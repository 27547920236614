import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import { apiCall } from '../../configs/apiMethodCall';

function AddApiVersionForm(props) {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [apiVersion, setApiVersion] = useState("");
    const [minVersion, setMinVersion] = useState("");
    const [forceUpdate, setForceUpdate] = useState("");
    const [apiEncKey, setApiEncKey] = useState("");
    const [apiBearerToken, setApiBearerToken] = useState("");
    const [deviceTypeData, setDeviceTypeData] = useState('null');
    const [forceMap, setForceMap] = useState([
        { value: 1, label: 'Yes' },
        { value: 0, label: 'No' },
    ]);
    const [deviceType, setDeviceType] = useState([
        { value: 2, label: 'iOS' },
        { value: 1, label: 'Android' },
    ]);

    const handleReset = () => {
        navigate('/master/language');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            if (isSubmitting) return;
            setIsSubmitting(true); 
            if(!deviceTypeData || !apiVersion || !minVersion || !forceUpdate || !apiEncKey || !apiBearerToken){
                toast.error('Missing required fields:');
                return
            }
            let payload = {
                device_type:deviceTypeData,
                api_version:apiVersion,
                api_min_version:minVersion,
                force_update:forceUpdate,
                api_enc_key:apiEncKey,
                api_bearer_token:apiBearerToken
            }
            console.log(payload,'---------44')
            // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/config/add_api_version`, payload, {
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // });
            const response = await apiCall('/config/add_api_version',payload,'POST')
    
            if (response.data.status) {
                console.log('Data saved successfully');
                toast.success(response.data.result, {
                });
            } else {
                console.error(`Error while saving data:${response.data.message}`);
                toast.error(response.data.result, {
                });
            }
        } catch (error) {
            toast.error(`Error while saving data:`);
   
        } finally{
            setIsSubmitting(false)
            navigate('/configuration/vc');
        }
    };

    return (
        <section className="panel add_section">
            <header className="panel-heading" >
                Add Api Version
            </header>
            <div className="panel-body">
                <form role="form" onSubmit={handleSubmit} encType="multipart/form-data" id="language_form">
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="api_version">API Version<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="text" className={`form-control ${apiVersion ? 'filled' : ''}`} id="api_version" name="api_version" placeholder="Enter Api Version" value={apiVersion} onChange={(e) => setApiVersion(e.target.value)} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="min_version">Min Version<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="number" className={`form-control ${minVersion ? 'filled' : ''}`} id="min_version" name="min_version" placeholder="Value should be greater than zero" value={minVersion} onChange={(e) => setMinVersion(e.target.value)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="api_token_enc">API ENC KEY<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="text" className={`form-control ${apiEncKey ? 'filled' : ''}`} id="api_token_enc" name="api_token_enc" placeholder="Enter Encryption Key" value={apiEncKey} onChange={(e) => setApiEncKey(e.target.value)} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="api_bearer">API Bearer Token<span style={{color: "#ff0000"}}></span></label>
                            <input type="text" className={`form-control ${apiBearerToken ? 'filled' : ''}`} id="api_bearer" name="api_bearer" placeholder="Enter Bearer Token." value={apiBearerToken} onChange={(e) => setApiBearerToken(e.target.value)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="api_device_type">Device Type<span style={{ color: "#ff0000", paddingRight: "10px" }}></span></label>
                                    <select id="api_device_type" className={`form-control ${deviceTypeData ? 'filled' : ''}`} value={deviceTypeData} onChange={(e) => setDeviceTypeData(e.target.value)}>
                                        <option value="">--Select a Device--</option>
                                        {deviceType.map((key) => (
                                            <option key={key.id} value={key.id}>
                                                {key.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="force_update">Force Update<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="force_update" className={`form-control ${forceUpdate ? 'filled' : ''}`} value={forceUpdate} onChange={(e)=>setForceUpdate(e.target.value)}>
                                        <option value="">--Select a Update Type--</option>
                                        {forceMap.map((key) => (
                                            <option key={key.id} value={key.id}>
                                                {key.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                    <div className="col-md-12 mt-3">
                        <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                    </div>
                </form>
                <ToastContainer />
            </div>
        </section>
    );
}

export default AddApiVersionForm;
