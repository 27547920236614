import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import {apiCall,apiCallForm} from '../../../src/configs/apiMethodCall'
import { useUser } from '../../../src/UserContext';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
function AddIconVisibilityForm(props) {
    const navigate = useNavigate();
    const { selectedLanguage } = useUser();
    const [articleType, setArticleType] = useState('');
    const [iconVisibility, setIconVisibility] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false)
    const contentTypeMap = {
        "0": "Text",
        "1": "Photo-Gallery",
        "2": "Video",
        "3": "Podcast",
        '4': "Webstory",
        '5': "Shorts",
        '6': "Live-Blog",
        "51":"Article Short",
        "52":"Video Short",
        "53":"Podcast Short"
      };
      const iconMap = {
        "1":"Show",
        "0":"Hide"
      }
    const handleReset = () => {
    };
    const handleValueChange = (value,setValue)=>{
        setValue(value)
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (
            !articleType ||
            !iconVisibility
        ) {
            toast.error("All fields are required.");
            return false;
        }
        if (isSubmitted){
            return
        }
        try {
            setIsSubmitted(true)
            const payload = {
                "id":false,
                "icon_visibility":iconVisibility,
                "article_type":articleType,
                "lang_id": selectedLanguage?.id
            }
            const response = await apiCall('/content/create_icon_visibility',payload,'POST')
            if (response?.data?.status) {
                console.log('Data saved successfully',response);
                toast.success("Data saved successfully", {
                });
            } else {
                console.error('Error while saving data:', "Error while saving data:");
                toast.error("Error while saving data:", {
                });
            }
        } catch (error) {
            console.log(error,'------77')
            toast.error("Error while saving data:", {})
        } finally{
            setIsSubmitted(false)
            navigate('/content_management/icon_visibility');
        }

    };
    return (
        <section className="panel add_section">
            <div className="panel-body">
                <form role="form" onSubmit={(event) => handleSubmit(event, 'widgets_ads')} id="widgets_form">
                    <div className="row mt-3">
                        <section className="panel">
                            <header className="panel-heading" style={{ fontWeight: "bold" }}>Add Icon Visibility</header>
                            <div className="row mt-2">
                            <div className="form-group col-md-6">
                                    <label htmlFor="plan_id">Icon Visibility<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="key_position" className={`form-control ${iconVisibility ? 'filled' : ''}`} value={iconVisibility} onChange={(e) => handleValueChange(e.target.value,setIconVisibility)}>
                                        <option value="">--Select icon visibility--</option>
                                        {Object.keys(iconMap).map((key) => (
                                            <option key={key} value={key}>
                                                {iconMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="plan_id">Article Type<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="key_position" className={`form-control ${articleType ? 'filled' : ''}`} value={articleType} onChange={(e) => handleValueChange(e.target.value,setArticleType)}>
                                        <option value="">--article type--</option>
                                        {Object.keys(contentTypeMap).map((key) => (
                                            <option key={key} value={key}>
                                                {contentTypeMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </section>
                        <div className="col-md-12 mt-3">
                            <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </section>
    );
}

export default AddIconVisibilityForm;
