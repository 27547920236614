import React from 'react';
import { HiOutlineLogout } from 'react-icons/hi';
import classNames from 'classnames';
import useLogout from '../hooks/useLogout';


const LogoutButton = ({ linkClass }) => {
  const logout = useLogout();

  return (
    <div className={classNames(linkClass, 'cursor-pointer text-red-500')} onClick={logout}>
      <span className="text-xl">
        {/* <HiOutlineLogout /> */}
      </span>
      Logout
    </div>
  );
};

export default LogoutButton;
