import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton,Pagination,Modal,InputLabel, FormControl
} from '@mui/material';
import { Link,useHistory } from 'react-router-dom'; 
import MyTableCell from '../../components/Tablecss';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const ViewAuthorsList = () => {
    const [contents, setContents] = useState([]);
    const [filters, setFilters] = useState({
        status: '',
        device_type :''
    });
    const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 0, label: 'Active' },
        { value: 1, label: 'In-Active' },
      ]);

      const [deviceOptions,setDeviceOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Android' },
        { value: 2, label: 'Ios' },
      ])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);
      const fetchData = async() => {
            try {
                const response = await apiGetCall(`/app/users`,{ page: currentPage, pageSize: 20, ...filters } )
                if (Array.isArray(response.data.data)) {
                    setContents(response.data.data);
                    setTotalPages(response.data.totalPages);
                } else {
                    console.error('Fetched data is not an array:', response.data);
                }
            } catch (error) {
                
            }
    };
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        console.log(name,value,'--------------82')
        setFilters({
            ...filters,
            [name]: value
        });
    };
 const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

 const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }
    const exportToCsv = e => {
        e.preventDefault()
        // Headers for each column
        let headers = ['id, name,email,mobile, device_type, app_version,status ,creation_time']
        // Convert users data to a csv
        let usersCsv = contents.reduce((acc, user) => {
          let { id, name,email,mobile, device_type, app_version,status ,creation_time } = user
          status = (status === 0 ? "Active" : "In-Active")
          device_type = (device_type === 1 ? "Android" : "Ios")
          creation_time = Date(creation_time).toLocaleString()
          acc.push([id, name,email,mobile, device_type, app_version,status ,creation_time].join(','))
          return acc
        }, [])
        downloadFile({
          data: [...headers, ...usersCsv].join('\n'),
          fileName: 'users.csv',
          fileType: 'text/csv',
        })
      }
    return (
        <>
          <div className="header-container">
                <header>
                    <h1>App Users List</h1>
                </header>
                <div className="add-author-button">
                <button type='button' onClick={exportToCsv}>
                    Export to CSV
                    </button>
                    {/* <Link to="/">Download CSV</Link> */}
                </div>
            </div>

        <div style={{ width: '100%', overflowX: 'auto' }}>
        <TableContainer component={Paper} style={{ width: '100%' }}>
    <Table>										
        <TableHead>
            <TableRow>
                <TableCell>Sr.No</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Profile Picture</TableCell>
                <TableCell>Device Type</TableCell>
                <TableCell>App Version</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Registered On</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                <Select
                        value={filters.device_type}
                        onChange={handleFilterChange}
                        displayEmpty
                        name="device_type"
                        size="small"
                        variant="outlined"
                    >
                        {deviceOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                <Select
                        value={filters.status}
                        onChange={handleFilterChange}
                        displayEmpty
                        name="status"
                        size="small"
                        variant="outlined"
                    >
                        {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell>
                </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {contents.map((content,index) => (
                <TableRow key={content.id}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{content.name}</TableCell>
                    <TableCell>{content.email}</TableCell>
                    <TableCell>{content.mobile}</TableCell>
                    <TableCell>{content.profile_picture && (
                    <img src={content.profile_picture} alt="Profile Picture" style={{ width: '50px', height: '50px' }} />
                )}</TableCell>
                    <TableCell>{content.device_type === 1 ? "Android" : "Ios"}</TableCell>
                    <TableCell>{content.app_version}</TableCell>
                    <MyTableCell content={content} />
                    <TableCell>{new Date(content.creation_time).toLocaleString()}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
</TableContainer>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
                    <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div>
            </div>
    </>
);
};
export default ViewAuthorsList;
