import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton, Pagination, Modal, InputLabel, FormControl
} from '@mui/material';
import { useUser } from '../../../src/UserContext';
import { Edit, Delete, Save, Add, Cancel } from '@mui/icons-material';
import { Link ,useNavigate} from 'react-router-dom';
import './Authors.css';
import { ToastContainer, toast } from 'react-toastify';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const ViewAuthorsList = () => {
    const { selectedLanguage } = useUser();
    const navigate = useNavigate();
    const [contents, setContents] = useState([]);
    const [filters, setFilters] = useState({
        content_status: '',
        lang_id:selectedLanguage?.id
    });
    const [icon ,setIcon] = useState('')
    const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
    ]);
    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const contentTypeMap = {
        "0": "Text",
        "1": "Photo-Gallery",
        "2": "Video",
        "3": "Podcast",
        '4': "Webstory",
        '5': "Shorts",
        '6': "Live-Blog"
    };

    const articleTypeMap = {
        '0': "Unknown",
        '1': "Article Short",
        '2': "Video Short",
        '3': "Podcast Short",
        '4': "JW Video Short"
    };
    const premiumContentTypeMap = {
        0: "Non Premium Content",
        1: "Premium Content"
    }
    const [editRowId, setEditRowId] = useState(null);
    const [editedContent, setEditedContent] = useState({
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    useEffect(() => {
        if (selectedLanguage?.id) {
            setFilters(prevFilters => ({
                ...prevFilters,
                lang_id: selectedLanguage.id
            }));
        }
    }, [selectedLanguage]);
    const fetchData = async () => {
        try {
            const response = await apiGetCall(`/author/authorlist`,{ page: currentPage, pageSize: 20, ...filters })
             if (Array.isArray(response.data.data)) {
                    setContents(response.data.data);
                    setTotalPages(response.data.totalPages);
                } else {
                    console.error('Fetched data is not an array:', response.data);
                }
        } catch (error) {
            
        }
    };
    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value, '--------------82')
        setFilters({
            ...filters,
            [name]: value
        });
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleEditClick = (id) => {
        setEditRowId(id);
        const content = contents.find(content => content.id === id);
        console.log('---------80', content)
        setEditedContent({ ...content});
        setImagePreview(content.author_logo)
        setModalOpen(true);
    };

    const handleCancelClick = () => {
        setEditRowId(null);
        setEditedContent({});
        setModalOpen(false);
    };

    const handleSaveClick = async (e) => {
        e.preventDefault()
        let payload = new FormData();
        Object.keys(editedContent).forEach(key => {
            payload.append(key, editedContent[key]);
        });
    
        if (icon) {
            try {
                payload.append('author_logo', icon);
            } catch (error) {
                console.log('Error appending icon:', error);
                toast.error('Error appending icon: ' + error.message);
                return;
            }
        }
    
        try {
            // const response = await fetch(`${process.env.REACT_APP_BASE_URL}/author/update_author`, {
            //     method: 'PUT',
            //     body: payload
            // });
            const response = await apiCallForm(`/author/update_author`,payload,'PUT')
            const data = await response.json();
    
            if (!response.ok) {
                toast.error('Failed to update author');
            }
            toast.success('Author updated successfully');
            setModalOpen(false)
            fetchData();
            setContents(contents);
            setEditRowId(null);
            setEditedContent({});
        } catch (error) {
            console.error('Error updating author:', error);
            toast.error('Error updating author: ' + (error.message || 'Unknown error occurred'));
        }
    };
    const [imagePreview, setImagePreview] = useState();

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setIcon(file)
        setImagePreview(URL.createObjectURL(file));
    };
    const handleDeleteClick = (id) => {
        // Delete data via API
        fetch(``, {
            method: 'DELETE'
        })
            .then(() => {
                const updatedContents = contents.filter(content => content.id !== id);
                setContents(updatedContents);
            })
            .catch(error => console.error('Error deleting data:', error));
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedContent({
            ...editedContent,
            [name]: value
        });
    };
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    const filteredContents = contents.filter(content => {
        const createdOnDate = new Date(content.createdOn);
        const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
        const toDate = filters.toDate ? new Date(filters.toDate) : null;

        return (
            (filters.category ? content.category === filters.category : true) &&
            (filters.contentType ? content.contentType === filters.contentType : true) &&
            (filters.status ? content.status === filters.status : true) &&
            (fromDate ? createdOnDate >= fromDate : true) &&
            (toDate ? createdOnDate <= toDate : true)
        );
    });

    return (
        <>
            <div className="header-container">
                <header>
                    <h1>Authors List</h1>
                </header>
                <div className="add-author-button">
                    <Link className="text-white" to="/admin/add_authors">Add Author</Link>
                </div>
            </div>

            <div style={{ width: '100%', overflowX: 'auto' }}>
                <TableContainer component={Paper} style={{ width: '100%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>CMS Id</TableCell>
                                <TableCell>Author Name</TableCell>
                                <TableCell>Author Image</TableCell>
                                <TableCell>Designation</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Author Status</TableCell>
                                <TableCell>Created On</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={filters.status}
                                        onChange={handleFilterChange}
                                        displayEmpty
                                        name="author_status"
                                        size="small"
                                        variant="outlined"
                                    >
                                        {statusOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredContents.map((content) => (
                                <TableRow key={content.id}>
                                    <TableCell>{content.id}</TableCell>
                                    <TableCell>{content.cms_id}</TableCell>
                                    <TableCell>{content.author_name}</TableCell>
                                    <TableCell>{content.author_logo && (
                                        <img src={content.author_logo} alt="Author Logo" style={{ width: '50px', height: '50px' }} />
                                    )}</TableCell>
                                    <TableCell>{content.designation}</TableCell>
                                    <TableCell>{content.description ? (
                                        content.description.includes('<') ? (
                                            <div dangerouslySetInnerHTML={{ __html: content.description }} />
                                        ) : (
                                            content.description
                                        )
                                    ) : null}</TableCell>
                                    <TableCell>{content.author_status === 1 ? "Enabled" : "Disabled"}</TableCell>
                                    <TableCell>{new Date(content.created_on).toLocaleString()}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteClick(content.id)} aria-label="delete">
                                            <Delete />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                    <Button
                        variant="outlined"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>
                {/* <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="edit-content-modal"
                    aria-describedby="modal to edit content"
                    className="centered-modal"
                >
                    <div className="modal-content">
                        <div className="modal-row">
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    id="author-name"
                                    label="Author Name"
                                    variant="outlined"
                                    value={editedContent.author_name}
                                    onChange={(e) => handleEditChange({ target: { name: "author_name", value: e.target.value } })}
                                    fullWidth
                                    required
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    id="email"
                                    label="Email"
                                    variant="outlined"
                                    value={editedContent.author_email}
                                    onChange={(e) => handleEditChange({ target: { name: "email", value: e.target.value } })}
                                    fullWidth
                                    required
                                />
                            </FormControl>
                        </div>
                        <div className="modal-row">
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    id="designation"
                                    label="Designation"
                                    variant="outlined"
                                    value={editedContent.designation}
                                    onChange={(e) => handleEditChange({ target: { name: "designation", value: e.target.value } })}
                                    fullWidth
                                    required
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <label htmlFor="author-image" style={{ marginBottom: '8px' }}>Author Image (Image Ratio 1:1)</label>
                                <input
                                    id="author-image"
                                    type="file"
                                    onChange={handleImageChange}
                                    style={{ marginBottom: '8px', display: 'block' }}
                                />
                                {imagePreview && (
                                    <img
                                        src={imagePreview}
                                        alt="Author"
                                        className="author-image-preview"
                                    />
                                )}
                            </FormControl>
                        </div>
                        <div className="modal-row">
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    id="twitter"
                                    label="Author's Twitter"
                                    variant="outlined"
                                    value={editedContent.twitter}
                                    onChange={(e) => handleEditChange({ target: { name: "twitter", value: e.target.value } })}
                                    fullWidth
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    id="facebook"
                                    label="Author's Facebook"
                                    variant="outlined"
                                    value={editedContent.facebook}
                                    onChange={(e) => handleEditChange({ target: { name: "facebook", value: e.target.value } })}
                                    fullWidth
                                />
                            </FormControl>
                        </div>
                        <div className="modal-row">
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    id="linkedin"
                                    label="Author's LinkedIn"
                                    variant="outlined"
                                    value={editedContent.linkedIn}
                                    onChange={(e) => handleEditChange({ target: { name: "linkedin", value: e.target.value } })}
                                    fullWidth
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    id="instagram"
                                    label="Author's Instagram"
                                    variant="outlined"
                                    value={editedContent.instagram}
                                    onChange={(e) => handleEditChange({ target: { name: "instagram", value: e.target.value } })}
                                    fullWidth
                                />
                            </FormControl>
                        </div>
                        <div className="modal-row">
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    id="youtube"
                                    label="Author's Youtube"
                                    variant="outlined"
                                    value={editedContent.youtube}
                                    onChange={(e) => handleEditChange({ target: { name: "youtube", value: e.target.value } })}
                                    fullWidth
                                />
                            </FormControl>
                        </div>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                id="description"
                                label="Description"
                                variant="outlined"
                                value={editedContent.description}
                                onChange={(e) => handleEditChange({ target: { name: "description", value: e.target.value } })}
                                fullWidth
                                multiline
                                rows={4}
                            />
                        </FormControl>

                        <div className="modal-actions">
                            <Button variant="contained" color="primary" onClick={handleSaveClick}>
                                Save
                            </Button>
                            <Button variant="contained" color="secondary" onClick={handleCancelClick} style={{ marginLeft: 10 }}>
                                Cancel
                            </Button>
                        </div>
                    </div>

                </Modal> */}
                <Modal
    open={modalOpen}
    onClose={handleCloseModal}
    aria-labelledby="edit-content-modal"
    aria-describedby="modal to edit content"
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
>
    <section className="panel edit_section col-6">
        <header className="panel-heading" >
            Edit Author
        </header>
        <div className="panel-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <form role="form" onSubmit={handleSaveClick} encType="multipart/form-data" id="edit_author_form">
                <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="author-name">Author Name<span style={{ color: "#ff0000" }}>*</span></label>
                        <input
                            type="text"
                            className={`form-control ${editedContent.author_name ? 'filled' : ''}`}
                            id="author-name"
                            name="author_name"
                            placeholder="Enter Author Name"
                            value={editedContent.author_name}
                            onChange={(e) => handleEditChange({ target: { name: "author_name", value: e.target.value } })}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="email">Email<span style={{ color: "#ff0000" }}>*</span></label>
                        <input
                            type="email"
                            className={`form-control ${editedContent.author_email ? 'filled' : ''}`}
                            id="email"
                            name="author_email"
                            placeholder="Enter Email"
                            value={editedContent.author_email}
                            onChange={(e) => handleEditChange({ target: { name: "author_email", value: e.target.value } })}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="form-group col-md-6">
                        <label htmlFor="designation">Designation<span style={{ color: "#ff0000" }}>*</span></label>
                        <input
                            type="text"
                            className={`form-control ${editedContent.designation ? 'filled' : ''}`}
                            id="designation"
                            name="designation"
                            placeholder="Enter Designation"
                            value={editedContent.designation}
                            onChange={(e) => handleEditChange({ target: { name: "designation", value: e.target.value } })}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="author-image">Author Image (Image Ratio 1:1)</label>
                        <input
                            id="author-image"
                            type="file"
                            className="form-control"
                            onChange={handleImageChange}
                        />
                        {imagePreview && (
                            <img
                                src={imagePreview}
                                alt="Author"
                                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                            />
                        )}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="form-group col-md-6">
                        <label htmlFor="twitter">Author's Twitter</label>
                        <input
                            type="text"
                            className={`form-control ${editedContent.twitter ? 'filled' : ''}`}
                            id="twitter"
                            name="twitter"
                            placeholder="Enter Twitter Handle"
                            value={editedContent.twitter}
                            onChange={(e) => handleEditChange({ target: { name: "twitter", value: e.target.value } })}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="facebook">Author's Facebook</label>
                        <input
                            type="text"
                            className={`form-control ${editedContent.facebook ? 'filled' : ''}`}
                            id="facebook"
                            name="facebook"
                            placeholder="Enter Facebook Profile"
                            value={editedContent.facebook}
                            onChange={(e) => handleEditChange({ target: { name: "facebook", value: e.target.value } })}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="form-group col-md-6">
                        <label htmlFor="linkedin">Author's LinkedIn</label>
                        <input
                            type="text"
                            className={`form-control ${editedContent.linkedin ? 'filled' : ''}`}
                            id="linkedin"
                            name="linkedin"
                            placeholder="Enter LinkedIn Profile"
                            value={editedContent.linkedin}
                            onChange={(e) => handleEditChange({ target: { name: "linkedin", value: e.target.value } })}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="instagram">Author's Instagram</label>
                        <input
                            type="text"
                            className={`form-control ${editedContent.instagram ? 'filled' : ''}`}
                            id="instagram"
                            name="instagram"
                            placeholder="Enter Instagram Handle"
                            value={editedContent.instagram}
                            onChange={(e) => handleEditChange({ target: { name: "instagram", value: e.target.value } })}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="form-group col-md-6">
                        <label htmlFor="youtube">Author's Youtube</label>
                        <input
                            type="text"
                            className={`form-control ${editedContent.youtube ? 'filled' : ''}`}
                            id="youtube"
                            name="youtube"
                            placeholder="Enter Youtube Channel"
                            value={editedContent.youtube}
                            onChange={(e) => handleEditChange({ target: { name: "youtube", value: e.target.value } })}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="form-group col-md-12">
                        <label htmlFor="description">Description</label>
                        <textarea
                            className={`form-control ${editedContent.description ? 'filled' : ''}`}
                            id="description"
                            name="description"
                            placeholder="Enter Description"
                            value={editedContent.description}
                            onChange={(e) => handleEditChange({ target: { name: "description", value: e.target.value } })}
                            rows={4}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>
                            Save
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={handleCancelClick} style={{ padding: "15px", lineHeight: "1.5" }}>
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
            <ToastContainer />
        </div>
    </section>
</Modal>

            </div>
            <ToastContainer />
        </>
    );
};
export default ViewAuthorsList;
