import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {apiCall,apiGetCall,apiCallForm} from '../../../src/configs/apiMethodCall'
import { useUser } from '../../../src/UserContext';
import { useNavigate,Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
const generatePassword = (length) => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+[]{}|;:,./<>?';
    let newPassword = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        newPassword += chars[randomIndex];
    }
    return newPassword;
};

function AddBackendUserForm(props) {
    const navigate = useNavigate();
    const { selectedLanguage,languages} = useUser();
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState("");
    const [role, setRole] = useState('');
    const [ langData,setLangData] = useState([])
    const [lang, setLang] = useState('');
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState(generatePassword(12));
    const [isSubmitted, setIsSubmitted] = useState(false)
    useEffect(() => {
        fetchData();
    }, [ ]);
    const fetchData = async() => {
        try {
            const response = await apiGetCall(`/config/master_management`,{})
            if (Array.isArray(response.data.data)) {
                let dataLang = []
                const data = (response.data.data.map(lang => ({
                    id: lang.id,
                    lang_name: lang.lang_name
                  })))
                  dataLang = [{
                    id:0,
                    lang_name:"All"
                },...data]
                setLangData(dataLang);
                } else {
                    console.error('Fetched data is not an array:', response.data);
                }
        } catch (error) {
            
        }
    };
    const roleMap = {
        "76": "TV9",
        "87": "API Live Watcher",
        "88": "Content Only",
        "89":"Manage Ads"
    };
    const handleReset = () => {
    };
    const handleValueChange = (value,setValue)=>{
        setValue(value)
    }
    const handleSubmit = async (event) => {
        try {
        event.preventDefault();
        let payload = {
            username:username, 
            registered_by:61, 
            email:email, 
            password:password, 
            mobile:mobile,
            perm_id:role, 
            app_id:74, 
            lang_id:lang
        }
        if (
            !username || !email || !password || !mobile || !role || !lang
        ) {
            toast.error("All fields are required.");
            return false;
        }
        if (isNaN(mobile)){
            toast.error("Mobile should be number.");
            return false;
        }
        if (isSubmitted){
            return
        }
            setIsSubmitted(true)
            const response = await apiCall('/admin/create_backenduser',payload,'POST')
            if (response?.data?.status) {
                console.log('Data saved successfully',response);
                toast.success("Data saved successfully", {
                });
            } else {
                console.error('Error while saving data:', "Error while saving data:");
                toast.error("Error while saving data:", {
                });
            }
        } catch (error) {
            console.log(error,'------77')
            toast.error("Error while saving data:", {})
        } finally{
            setIsSubmitted(false)
            navigate('/backend_management/backend_management');
        }

    };
    return (
        <section className="panel add_section">
            <div className="panel-body">
                <form role="form" onSubmit={(event) => handleSubmit(event, 'widgets_ads')} id="widgets_form">
                    <div className="row mt-3">
                        <section className="panel">
                            <header className="panel-heading" style={{ fontWeight: "bold" }}>Add Backend User</header>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor="email_back">Email<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <input type="email" className={`form-control ${email ? 'filled' : ''}`} id="email_back" name="order_idx" placeholder="Email" value={email} onChange={(e) => handleValueChange(e.target.value,setEmail)}/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="bakc_lang">Language<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="back_lang" className={`form-control ${lang ? 'filled' : ''}`} value={lang} onChange={(e) => handleValueChange(e.target.value,setLang)}>
                                        <option value="">--Languages--</option>
                                        {langData.map((key) => (
                                            <option key={key.id} value={key.id}>
                                                {key.lang_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-2">
                            <div className="form-group col-md-6">
                                    <label htmlFor="user_name">User Name<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className={`form-control ${username ? 'filled' : ''}`} id="user_name" name="user_name" placeholder="User Name" value={username} onChange={(e) => handleValueChange(e.target.value,setUserName)}/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="back_pass">Password<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <input type="password" className={`form-control ${email ? 'filled' : ''}`} id="order_idx" name="back_pass" placeholder="Password" value={password} onChange={(e) => handleValueChange(e.target.value,setPassword)}/>
                                </div>
                             </div>
                             <div className="row mt-2">
                            <div className="form-group col-md-6">
                                    <label htmlFor="mobile">Mobile<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <input type="mobile" className={`form-control ${mobile ? 'filled' : ''}`} id="mobile" name="order_idx" placeholder="Mobile" value={mobile} onChange={(e) => handleValueChange(e.target.value,setMobile)}/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="bakc_lang">Role Permissions<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="back_lang" className={`form-control ${role ? 'filled' : ''}`} value={role} onChange={(e) => handleValueChange(e.target.value,setRole)}>
                                        <option value="">--role--</option>
                                        {Object.keys(roleMap).map((key) => (
                                            <option key={key} value={key}>
                                                {roleMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                             </div>
                        </section>
                        <div className="col-md-12 mt-3">
                            <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </section>
    );
}

export default AddBackendUserForm;
