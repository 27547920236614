import React from 'react';
import { TableCell } from '@mui/material';

const MyTableCell = ({ content }) => (
  <TableCell style={{ color: content.status === 0 ? 'green' : 'red', fontWeight: 'bold'  }}>
    {content.status === 0 ? "Active" : "In-Active"}
  </TableCell>
);

export default MyTableCell;
