import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton,Pagination,Modal,InputLabel, FormControl
} from '@mui/material';
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Edit, Delete, Save,Add, Cancel } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import "react-datepicker/dist/react-datepicker.css";
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
// Function to format the timestamp to Date object
const convertTimestampToDate = (timestamp) => {
    if (!timestamp) return null;
    return new Date(timestamp * 1000);
  };
const ViewSplashList = () => {
    const [contents, setContents] = useState([]);
    const [formattedDate, setFormattedDate] = useState('');
    const [icon ,setIcon] = useState('')
    const [splashStartFrom, setSplashStartFrom] = useState(null);
    const [filters, setFilters] = useState({
        category: '',
        contentType: '',
        status: false,
        fromDate: '',
        toDate: ''
    });
    const [imagePreview, setImagePreview] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const [editRowId, setEditRowId] = useState(null);
    const [editedContent, setEditedContent] = useState({
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        if (editedContent.start_from) {
          const date = convertTimestampToDate(editedContent.start_from);
          if (!isNaN(date.getTime())) { // Check if date is valid
            setSplashStartFrom(date);
          }
        }
      }, [editedContent.start_from]);
    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);
      const fetchData = async () => {
        try {
            const response = await apiGetCall(`/master/splash_list?page=${currentPage}&pageSize=20`,filters)
            if (Array.isArray(response.data.data)) {
                    setContents(response.data.data);
                    setTotalPages(response.data.totalPages);
                } else {
                    console.error('Fetched data is not an array:', response.data);
                }
        } catch (error) {
            
        }
    };
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };
 const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    // const handleDateChange = (e,start_from) => {
    //     const value = e.target.value;
    //     console.log(value,'value')
    //     let dateValue = new Date(start_from).toISOString().slice(0, 16);
    
    //     if (value) {
    //       dateValue = new Date(value).toISOString().slice(0, 16);
    //     }
    
    //     handleEditChange({ target: { name: "start_from", value: dateValue } });
    //   };
    const handleDateChange = (date) => {
        setSplashStartFrom(date);
        if (date) {
          const unixTimestamp = Math.floor(date.getTime() / 1000);
          handleEditChange({ target: { name: "start_from", value: unixTimestamp } });
        } else {
          handleEditChange({ target: { name: "start_from", value: '' } });
        }
      };
      
      const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
    const handleEditClick = (id) => {
        setEditRowId(id);
        const content = contents.find(content => content.id === id);
        console.log('---------80',content)
        setEditedContent({ ...content });
        setModalOpen(true);
        setImagePreview(content.splash_url)
    };

    const handleCancelClick = () => {
        setEditRowId(null);
        setEditedContent({});
        setModalOpen(false);
    };

    const handleSaveClick = async(e) => {
        e.preventDefault()
        let formData = new FormData();
        Object.keys(editedContent).forEach(key => {
            try {
                const value = editedContent[key];
                formData.append(key, value);
            } catch (error) {
                console.log('Error appending key to formData:', error);
            }
        });
    
        if (icon) {
            try {
                formData.append('splash_url', icon);
            } catch (error) {
                console.log('Error appending icon:', error);
            }
        }
        console.log(formData,'------------------134')
        try {
            const response = await apiCallForm(`/master/update_splash`,formData,'PUT')
            if (response && response.data.status) {
                            setEditRowId(null);
                            setEditedContent({});
                            toast.success('Data updated successfully');
                            fetchData()
                            setModalOpen(false);
                            console.log('Modal should close now');
                        } else {
                            console.error('Error updating data:', data.message);
                            toast.error('Failed to update data');
                        }
        } catch (error) {
            toast.error('Failed to update data');
        }
    };
    const handleImageChange = (e, type) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
            setIcon(file)
        };
    
        if (file) {
          reader.readAsDataURL(file);
        }
      };
    const handleDeleteClick = (id) => {
        // Delete data via API
        fetch(`https://your-api-endpoint.com/contents/${id}`, {
            method: 'DELETE'
        })
        .then(() => {
            const updatedContents = contents.filter(content => content.id !== id);
            setContents(updatedContents);
        })
        .catch(error => console.error('Error deleting data:', error));
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedContent({
            ...editedContent,
            [name]: value
        });
    };
 const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
      ]);
    const filteredContents = contents.filter(content => {
        const createdOnDate = new Date(content.createdOn);
        const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
        const toDate = filters.toDate ? new Date(filters.toDate) : null;

        return (
            (filters.category ? content.category === filters.category : true) &&
            (filters.contentType ? content.contentType === filters.contentType : true) &&
            (filters.status ? content.status === filters.status : true) &&
            (fromDate ? createdOnDate >= fromDate : true) &&
            (toDate ? createdOnDate <= toDate : true)
        );
    });

    return (
        <>
          <div className="header-container">
    <header>
        <h1>Splash List</h1>
    </header>
    <div className="add-author-button">
        <Link className="text-white" to="/master/addsplash">Add Splash</Link>
    </div>
</div>

        <div style={{ width: '100%', overflowX: 'auto' }}>
        <TableContainer component={Paper} style={{ width: '100%' }}>
    <Table>				
        <TableHead>
            <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Platform</TableCell>
                <TableCell>Valid From</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                <Select
                        value={filters.status}
                        onChange={handleFilterChange}
                        displayEmpty
                        name="status"
                        size="small"
                        variant="outlined"
                    >
                     {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </TableCell>
                {/* <TableCell>
                </TableCell> */}
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {contents.map((content) => (
                <TableRow key={content.id}>
                    <TableCell>{content.id}</TableCell>
                    {/* <TableCell>{content.cms_id}</TableCell> */}
                    <TableCell>{content.title}</TableCell>
                    <TableCell>{content.splash_url && (
                    <img src={content.splash_url} alt="Splash GIF" style={{ width: '100px', height: '100px' }} />
                )}</TableCell>
                 <TableCell>{content.device_type}</TableCell>
                    <TableCell>{content.start_from ? formatDate(content.start_from) : 'No Data'}</TableCell>
                    <TableCell>{content.is_active === 1 ? "Enabled" : "Disabled"}</TableCell>
                    <TableCell>
                        <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                            <Edit />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(content.id)} aria-label="delete">
                            <Delete />
                        </IconButton>
                    </TableCell>

                </TableRow>
            ))}
        </TableBody>
    </Table>
</TableContainer>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
                    <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div>
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="edit-content-modal"
                aria-describedby="modal to edit content"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                {/* <div style={{ backgroundColor: 'white', boxShadow: 24, padding: 20, borderRadius: 8, width: '80%', maxWidth: '900px' }}>
                    <div style={{ display: 'flex', gap: '20px' }}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                        id="state-name"
                        label="Splash Title*"
                        variant="outlined"
                        value={editedContent.title}
                        onChange={(e) => handleEditChange({ target: { name: "title", value: e.target.value } })}
                        fullWidth
                        required
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                            <TextField
                            id="start-from"
                            label="Start From*"
                            type="datetime-local"
                            variant="outlined"
                            value={editedContent.start_from ? new Date(editedContent.start_from).toISOString().slice(0, 16) : ''}
                            onChange={(e) => handleDateChange(e,editedContent.start_from)}
              style={{ marginBottom: '8px' }}
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </div>
                    <div style={{ display: 'flex', gap: '20px' }}>
                    <FormControl fullWidth margin="normal">
                    <Select
                        id="device-type"
                        label="Splash For Device Type*"
                        variant="outlined"
                        value={editedContent.device_type}
                        onChange={(e) => handleEditChange({ target: { name: "device_type", value: e.target.value } })}
                        fullWidth
                        required
                    >
                     {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                    </div>
                    <div style={{ display: 'flex', gap: '20px' }}>
                    <FormControl fullWidth margin="normal">
                        <label htmlFor="state-image-dark" style={{ marginBottom: '8px' }}>Splash Image*(upload gif only) Ratio[9:16]</label>
                        <input
                        id="layout-image"
                        type="file"
                        onChange={(e) => handleImageChange(e, "")}
                        style={{ marginBottom: '8px' }}
                        />
                        {imagePreview && (
                        <img src={imagePreview} alt="Layout Image" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                        )}
                    </FormControl>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                    <Button variant="contained" color="primary" onClick={handleSaveClick}>
                        Save
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleCancelClick} style={{ marginLeft: 10 }}>
                        Cancel
                    </Button>
                    </div>
                </div> */}
                 <section className="panel edit_section col-6">
                        <header className="panel-heading" >
                            Edit Splash
                        </header>
                        <div className="panel-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            <form role="form" onSubmit={handleSaveClick} encType="multipart/form-data" id="edit_language_form">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="splash_title">Splash Title<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editedContent.title ? 'filled' : ''}`}
                                            id="splash_title"
                                            name="splash_title"
                                            placeholder="Splash Title"
                                            value={editedContent.title}
                                            onChange={(e) => handleEditChange({ target: { name: "title", value: e.target.value } })}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="splash_status">Splash Status</label>
                                        <select
                                            value={editedContent.is_active}
                                            onChange={(e) => handleEditChange({ target: { name: "is_active", value: e.target.value } })}
                                            className={`form-control ${editedContent.is_active ? 'filled' : ''}`}
                                            name="splash_status"
                                        >
                                           {statusOptions.filter(option => option.value !== false).map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="splash-image-light">Splash Gif</label>
                                        <input
                                            id="layout-image-light"
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => handleImageChange(e, "light")}
                                        />
                                        {imagePreview && (
                                        <img src={imagePreview} alt="Splash Image" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                                        )}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="layout_code">Starts From<span style={{ color: "#ff0000" }}>*</span></label>
                                        <DatePicker
                                            className={`form-control ${splashStartFrom ? 'filled' : ''}`}
                                            selected={splashStartFrom}
                                            onChange={handleDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            wrapperClassName="date-picker-wrapper" // Example custom wrapper class
                                        />
                                        </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>
                                                Save
                                            </button>
                                            <button type="button" className="btn btn-secondary" onClick={handleCancelClick} style={{ padding: "15px", lineHeight: "1.5" }}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <ToastContainer />
                        </div>
                    </section>
                </Modal>
            </div>
    </>
);
};
export default ViewSplashList;
