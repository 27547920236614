import React, { useState, useContext } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import API from './../Utils/API';
import { ToastContainer, toast } from 'react-toastify';
import Captcha from './../components/Captcha';
import { UserContext } from '../UserContext';
import { UpdateUserData } from "../UserStates/Actions";
import { initialState, rootReducer } from "../UserStates/Reducer";
import LoadingComponent from "../components/LoadingComponent/LoadingComponent";
import './Login.css';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [captchaValue, setCaptchaValue] = useState('');
    const [sendingData, setSendingData] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { state: userState, dispatch: userDispatch } = useContext(UserContext);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleUserLogin = async (e) => {
        e.preventDefault();
        setSendingData(true);

        try {
            const res = await API.post(`/userauth/login`, {
                email,
                password,
                captcha
            });

             if (captcha !== captchaValue) {
                toast.error('Captcha does not match!!');
                setSendingData(false);
                return;
            }

            console.log("Response data:", res.data.data.token,res.data.data.perm_id); // Check response structure
            localStorage.setItem('cmsToken', res.data.data.token);
            localStorage.setItem('perm_id',res.data.data.perm_id);
            // Assuming res.data contains the required user data
            await userDispatch(UpdateUserData({
                isLoggedIn: true,
                data: res.data, // Adjust this based on your API response structure
            }));

            // Show success message
            toast.success('Login successful');

            // Redirect to dashboard or another route
           navigate('/dashboard');

        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                error.response.data.errors.forEach(err => {
                    toast.error(err.msg);
                });
            } else {
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error('Failed to login. Please try again.');
                }
            }
        } finally {
            setSendingData(false);
        }
    };

    {console.log(userState)}
   
    // if (userState.isLoggedIn) {
    //   return <Navigate replace to="/dashboard" />;
    // }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="login-body">
                <div className="container">
                    <div className="row loginBox">
                        <div className="col-md-6">
                            <div className="logo-left">
                                <div className="logo-new-tb">
                                    <a href="#">
                                        <img src="https://d2g4n296dp0wrc.cloudfront.net/74/admin_v1/test_management/question_bank/74999490_TV9-NETWORK-LOGO-HIGH-RES_02-copy%201.png" alt="logo" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="logo-form-right">
                                <div className="login-wrap">
                                    <div className="Login-title">
                                        <h1 style={{ fontWeight: 700 }}>Login</h1>
                                    </div>

                                    <form className="mt-4 form-signin" onSubmit={handleUserLogin} autoComplete="off">
                                        <div className="row">
                                            <div className="col-lg-12 login-part-inner">
                                                <h4>Account Email ID</h4>
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="off"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    className="form-control"
                                                    placeholder="Email address"
                                                />
                                            </div>

                                            <div className="col-lg-12 login-part-inner">
                                                <h4>Password</h4>
                                                <div className="input-group my_input">
                                                    <input
                                                        id="password"
                                                        name="password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        autoComplete="off"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        className="form-control"
                                                        placeholder="Password"
                                                    />
                                                    <div className="input-group-addon" role="button" title="view password" onClick={togglePasswordVisibility}>
                                                        <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} id="icon" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 login-part-inner">
                                                <h4>Enter Captcha</h4>
                                                <div className="capture-field login_c_field">
                                                    <div>
                                                        <input
                                                            className='form-control mb-0'
                                                            type="text"
                                                            id='captcha'
                                                            name='captcha'
                                                            value={captcha}
                                                            onChange={(e) => setCaptcha(e.target.value)}
                                                            placeholder="Enter Captcha"
                                                        />
                                                    </div>
                                                    <Captcha setCaptchaValue={setCaptchaValue} />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 login-part-inner">
                                                <button
                                                    type="submit"
                                                    disabled={sendingData}
                                                    className="btn btn-lg btn-login btn-block"
                                                >
                                                    {sendingData ? 'Signing In...' : 'Sign In'}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <p className="footer">Copyright ©2024 tv9NETWORK</p>
                </footer>
            </div>
        </>
    );
}

export default LoginPage;