import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton,Pagination,Modal,InputLabel, FormControl,CircularProgress
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { Edit, Delete, Save, Cancel } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useUser } from '../../../src/UserContext';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const ViewContentOrder = () => {
    const { selectedLanguage } = useUser();
    console.log(selectedLanguage,'------------14')
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [contents, setContents] = useState([]);
    const [filters, setFilters] = useState({
        status: null,
        lang_id:selectedLanguage?.id
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await apiGetCall(`/content/content_order_icon?page=${currentPage}&pageSize=20`,filters );
            console.log(response,'---------28')
            if (Array.isArray(response.data.data)) {
                setContents(response.data.data);
                console.log(response.data.data,'---------28')
                setTotalPages(response.data.totalPages);
            } else {
                console.error('Fetched data is not an array:', response.data);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    };
    useEffect(() => {
        if (selectedLanguage?.id) {
            setFilters(prevFilters => ({
                ...prevFilters,
                lang_id: selectedLanguage.id
            }));
        }
    }, [selectedLanguage]);
    useEffect(() => {
        fetchData();
    }, [currentPage,filters]);

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const [imagePreview, setImagePreview] = useState('');
    const [orderIcon, setOrderIcon] = useState('');
    const [editRowId, setEditRowId] = useState(null);
    const [editedContent, setEditedContent] = useState({
    });
    const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
    ]);
    const keyPositionMap = {
        "1": "Top",
        "2": "Center",
        "3": "Bottom",
    };
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImagePreview(URL.createObjectURL(file));
        setOrderIcon(file)
    };

 const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleEditClick = (id) => {
        setEditRowId(id);
        const content = contents.find(content => content.id === id);
        setEditedContent({ ...content });
        setModalOpen(true);
        setImagePreview(content.order_icon_url)
    };

    const handleCancelClick = () => {
        setEditRowId(null);
        setEditedContent({});
        setModalOpen(false);
    };

    const handleSaveClick = async (e) => {
        e.preventDefault()
        let formData = new FormData();
        Object.keys(editedContent).forEach(key => {
            try {
                const value = editedContent[key];
                formData.append(key, value);
            } catch (error) {
                console.log('Error appending key to formData:', error);
            }
        });

        if (orderIcon) {
            try {
                formData.append('order_icon_url', orderIcon);
            } catch (error) {
                console.log('Error appending icon:', error);
            }
        }
        try {
            const response = await apiCallForm('/content/create_order_icon',formData,'POST')
                if (response?.data?.status) {
                    setEditRowId(null);
                    setEditedContent({});
                    toast.success('Data updated successfully');
                    fetchData()
                    setModalOpen(false);
                    console.log('Modal should close now');
                } else {
                    console.error('Error updating data:', data.message);
                    toast.error('Failed to update data');
                }
        } catch (error) {
            toast.error('Failed to update data');
        }
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedContent({
            ...editedContent,
            [name]: value
        });
    };
 const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };
    return (
        <div style={{ width: '100%', overflowX: 'auto' }}>
        <div className="header-container">
    <header>
        <h1>Order Icon List</h1>
            </header>
            <div className="add-author-button">
                <Link className="text-white" to="/content/create_order_icon">Add Order Icon</Link>
            </div>
        </div>
   <TableContainer component={Paper} style={{ width: '100%' }}>
   {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                        <CircularProgress />
                    </div>
                )
   :( <Table>
        <TableHead>
            <TableRow>
                <TableCell>Order Index</TableCell>
                <TableCell>Order Icon</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>Modified On</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                
                <TableCell>
                    <Select
                        value={filters.status}
                        onChange={handleFilterChange}
                        displayEmpty
                        name="status"
                        size="small"
                        variant="outlined"
                    >
                        {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell>
                </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {contents.map((content) => (
                <TableRow key={content.id}>
                    {/* <TableCell>{content.id}</TableCell> */}
                    <TableCell>{content.order_index}</TableCell>
                    <TableCell>{content.order_icon_url && (
                    <img src={content.order_icon_url} alt="Order Icon" style={{ width: '50px', height: '50px' }} />
                )}</TableCell>
                    <TableCell>{new Date(content.created_on*1000).toLocaleString()}</TableCell>
                    <TableCell>{new Date(content.modified_on*1000).toLocaleString()}</TableCell>
                    <TableCell>{content.order_status === 1 ? "Enabled" : "Disabled"}</TableCell>
                    <TableCell>
                        <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                            <Edit />
                        </IconButton>
                    </TableCell>

                </TableRow>
            ))}
        </TableBody>
    </Table>
    )}
</TableContainer>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
                    <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div>
            <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="edit-content-modal"
                    aria-describedby="modal to edit content"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <section className="panel edit_section col-6">
                        <header className="panel-heading" >
                            Edit Order Icon
                        </header>
                        <div className="panel-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            <form role="form" onSubmit={handleSaveClick} encType="multipart/form-data" id="edit_language_form">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="state_name">Order Index<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editedContent.order_index ? 'filled' : ''}`}
                                            id="order_index"
                                            name="order_index"
                                            placeholder="Order Index"
                                            value={editedContent.order_index}
                                            onChange={(e) => handleEditChange({ target: { name: "order_index", value: e.target.value } })}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="order_status">Order Icon Status</label>
                                        <select
                                            value={editedContent.order_status}
                                            onChange={(e) => handleEditChange({ target: { name: "order_status", value: e.target.value } })}
                                            className={`form-control ${editedContent.banner_status ? 'filled' : ''}`}
                                            name="order_status"
                                        >
                                            {statusOptions.filter(option => option.value !== false).map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="state-image-light">Order Icon (Image Ratio 1:1)</label>
                                        <input
                                            id="banner-image-light"
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => handleImageChange(e, "preview")}
                                            style={{ marginBottom: '8px' }}
                                        />
                                        {imagePreview &&
                                            <img
                                                src={imagePreview}
                                                alt="Banner Image"
                                                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                            />
                                        }
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="key_position">Icon Position</label>
                                        <select
                                            value={editedContent.icon_position}
                                            onChange={(e) => handleEditChange({ target: { name: "icon_position", value: e.target.value } })}
                                            className={`form-control ${editedContent.icon_position ? 'filled' : ''}`}
                                            name="icon_postion"
                                        >
                                            {Object.keys(keyPositionMap).map((key) => (
                                                <option key={key} value={key}>
                                                    {keyPositionMap[key]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>
                                                Save
                                            </button>
                                            <button type="button" className="btn btn-secondary" onClick={handleCancelClick} style={{ padding: "15px", lineHeight: "1.5" }}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <ToastContainer />
                        </div>
                    </section>

                </Modal>
    <ToastContainer/>
            </div>
);
};

export default ViewContentOrder;
