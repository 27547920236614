import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './UserContext';

const PrivateRoute = ({ element, allowedUserTypes, ...rest }) => {
  const { user } = useUser();
  const token = localStorage.getItem('token');
  console.log("token=="+token);
  console.log(user);
  // if (!token) {
  //   return <Navigate to="/login" />;
  // }
  // if (!user) {
  //   return <Navigate to="/" />;
  // }
  // if (!allowedUserTypes.includes(user.type)) {
  //   return <Navigate to="/unauthorized" />;
  // }
  return element;
};

export default PrivateRoute;