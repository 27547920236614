import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Select, MenuItem, Button, InputLabel
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useUser } from '../../UserContext';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {apiCall,apiCallForm,apiGetCall,apiPutCall} from '../../../src/configs/apiMethodCall'
const EditAppLabel = () => {
  const navigate = useNavigate()
  const { selectedLanguage } = useUser();
  const { id, screen } = useParams();
  const [appLabelScreen, setAppLabelScreen] = useState([
    { screen: 1, label: "Article Details" },
    { screen: 2, label: "Listing" },
    { screen: 3, label: "Live Channel" },
    { screen: 4, label: "Notification" },
    { screen: 5, label: "Bookmark" },
    { screen: 6, label: "Home" },
    { screen: 7, label: "Internet Off" },
    { screen: 8, label: "Toasts" },
    { screen: 9, label: "Language" },
    { screen: 10, label: "States" },
    { screen: 11, label: "Categories" },
    { screen: 12, label: "Shorts" },
    { screen: 13, label: "Player" },
    { screen: 14, label: "Left Menu" },
    { screen: 15, label: "Author" },
    { screen: 16, label: "Registration" },
    { screen: 17, label: "Subscription" },
    { screen: 18, label: "Setting" },
    { screen: 19, label: "Search" }
  ]);
  const [articleType, setArticleType] = useState([
    {id:0 ,label: "Article"},
    {id:1, label: "Photo-Gallery"},
    {id :2 ,label: "Video"},
    {id :3 , label: "Podcast"},
    {id :4, label: "Web-Story"},
    {id :5 , label: "Short"},
    {id :6 , label: "Liveblog"},
    {id :7 ,label: "Live"},
    {id :51 ,label: "Article Short"},
    {id :52 ,label: "Video Short"},
    {id :53 ,label: "Podcast Short"},
  ])
  const [appLabelFields, setAppLabelFields] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [filters, setFilters] = useState({
    lang_id: selectedLanguage?.id,
    screen: screen,
    article_type: 0,
    status: 1
  });
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };
  useEffect(() => {
    const fetchAppLabelFields = async () => {
        try {
            const response = await apiGetCall(`/master/app_label_list`, filters);
            setAppLabelFields(response.data.data);
        } catch (error) {
            console.error('Error fetching form data:', error);
        }
    };

    fetchAppLabelFields();
}, [id, screen, filters]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form submitted:', formValues);

    apiPutCall(`/master/edit_app_label_list`, formValues,filters)
      .then(response => {
        toast.success('Form updated successfully!');
      })
      .catch(error => {
        console.error('Error updating form:', error);
        toast.error('Failed to update form.');
      });
    setTimeout(() => {
      navigate(`/master/app_label`);

    }, 2000)
  };

const handleReset = () =>{
  navigate(`/master/app_label`);
}
  return (
    <>
      <section className="panel add_section">
        <div className="header-container">
          <header className="panel-heading">
            Edit App Label
          </header>
        </div>
        <div className="panel-body">
          <div className="relative" style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '20px' }}>
            <InputLabel id="screen-select-label"> Select Screen:</InputLabel>
            <Select
              value={filters.screen}
              onChange={handleFilterChange}
              displayEmpty
              name="screen"
              size="small"
              variant="outlined"
            >
              {appLabelScreen.map((option) => (
                <MenuItem key={option.screen} value={option.screen}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            </div>
            {filters.screen == 1 && (
              <div style={{ marginRight: '20px' }}>
                 <InputLabel id="article-select-label">Select Article</InputLabel>
              <Select
              value={filters.article_type}
              onChange={handleFilterChange}
              displayEmpty
              name="article_type"
              size="small"
              variant="outlined"
            >
              {articleType.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            </div>
            )
            }
          </div>
          <form role="form" onSubmit={handleSubmit}>
            <div className="row mt-3">
              {appLabelFields.length > 0 && (
                <div className="row mt-2">
                  {appLabelFields.map((field, index) => (
                    <div key={index} className="form-group col-md-6">
                      <label htmlFor={field.label_code}>{field.label}</label>
                      <input
                        type="text"
                        className={`form-control ${field.label ? 'filled' : ''}`}
                        id={field.label_code}
                        name={field.label_code}
                        placeholder={field.label}
                        onChange={handleInputChange}
                        value={formValues[field.label_code] || ''}
                      />
                    </div>
                  ))}
                </div>
              )}
              {/* <div className="col-md-12 mt-3">
                <Button type="submit" variant="contained" color="primary" style={{ padding: "15px", lineHeight: "1.5" }}>Save</Button>
              </div> */}
              <div className="col-md-12 mt-3">
              <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
              <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Cancel</button>
            </div>
            </div>
          </form>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};
export default EditAppLabel;
