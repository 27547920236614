import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const navigate = useNavigate();

  const logout = async () => {
    try {
      // Call your logout API

      await fetch(`${process.env.REACT_APP_BASE_URL}/auth/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('cmsToken')}`,
        },
      });
      localStorage.removeItem('token');
      localStorage.removeItem('userType');
      localStorage.removeItem('cmsToken');
      localStorage.removeItem('perm');
      navigate('/login');
    } catch (error) {
      console.error('Failed to logout:', error);
    }
  };

  return logout;
};

export default useLogout;
