import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import { apiCallForm } from '../../configs/apiMethodCall';

function LanguageForm(props) {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [langName, setLangName] = useState("");
    const [langCode, setLangCode] = useState("");
    const [apiToken, setApiToken] = useState("");
    const [termConditionUrl, setTermConditionUrl] = useState("");
    const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState("");
    const [langImageDark, setLangImageDark] = useState(null);
    const [langImageLight, setLangImageLight] = useState(null);
    const [previewImageDark, setPreviewImageDark] = useState(null);
    const [previewImageLight, setPreviewImageLight] = useState(null);


    const handleReset = () => {
        setLangName("");
        setLangCode("");
        setApiToken("");
        setTermConditionUrl("");
        setPrivacyPolicyUrl("");
        setLangImageDark(null);
        setLangImageLight(null);
        navigate('/master/language');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            if (isSubmitting) return;
            setIsSubmitting(true); 
            if(!langName || !langCode || !apiToken || !langImageDark || !langImageLight){
                toast.error('Missing required fields:');
                return
            }
            const formData = new FormData();
            formData.append('lang_name', langName);
            formData.append('lang_code', langCode);
            formData.append('api_token', apiToken);
            formData.append('term_condition_url', termConditionUrl);
            formData.append('privacy_policy_url', privacyPolicyUrl);
            formData.append('dark_lang_logo', langImageDark);
            formData.append('lang_logo', langImageLight);
            formData.append('cms_id', 0);
            console.log(langImageDark,'====formData')
    
            // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/master/create_lang`, formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     }
            // });
            const response = await apiCallForm('/master/create_lang',formData,'POST')
            if (response.data.status) {
                console.log('Data saved successfully');
                toast.success(response.data.message, {
                });
                // window.location.href = '/master/language';
            } else {
                console.error('Error while saving data:', response.data.message);
                toast.success(response.data.message, {
                });
            }
        } catch (error) {
            toast.error('Error while saving data:', error);
   
        } finally{
            setIsSubmitting(false)
            navigate('/master/language');
        }
    };
    
    const handleImageUpload = (event, setImage) => {
        const file = event.target.files[0];
        if (setImage === "dark"){
            setLangImageDark(file)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setPreviewImageDark(reader.result);
            }
        }
        else{
            setLangImageLight(file)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setPreviewImageLight(reader.result);
            }
        }
    };

    return (
        <section className="panel add_section">
            <header className="panel-heading">
                Add Language
            </header>
            <div className="panel-body">
                <form role="form" onSubmit={handleSubmit} encType="multipart/form-data" id="language_form">
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="lang_name">Language Name<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="text" className={`form-control ${langName ? 'filled' : ''}`} id="lang_name" name="lang_name" placeholder="Enter Language Name" value={langName} onChange={(e) => setLangName(e.target.value)} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="lang_code">Language CMS Domain<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="url" className={`form-control ${langCode ? 'filled' : ''}`} id="lang_code" name="lang_code" placeholder="Enter Language Name" value={langCode} onChange={(e) => setLangCode(e.target.value)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="api_token">CMS Api Token<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="text" className={`form-control ${apiToken ? 'filled' : ''}`} id="api_token" name="api_token" placeholder="Enter Api Token For this Language" value={apiToken} onChange={(e) => setApiToken(e.target.value)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="lang_image_dark">Language Image Dark<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="file" className={`form-control ${langImageDark ? 'filled' : ''}`}id="lang_image_dark" name="lang_image_dark" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, 'dark')} />
                            {previewImageDark && (
                        <img src={previewImageDark} alt="Lang Image" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                        )}
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="lang_image_light">Language Image Light<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="file" className={`form-control ${langImageLight ? 'filled' : ''}`} id="lang_image_light" name="lang_image_light" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, 'light')} />
                            {previewImageLight && (
                        <img src={previewImageLight} alt="Lang Image" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                        )}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="term_condition_url">Term & Condition Url<span style={{color: "#ff0000"}}></span></label>
                            <input type="url" className={`form-control ${termConditionUrl ? 'filled' : ''}`} id="term_condition_url" name="term_condition_url" placeholder="Enter url for term & condition." value={termConditionUrl} onChange={(e) => setTermConditionUrl(e.target.value)} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="privacy_policy_url">Privacy Policy URL<span style={{color: "#ff0000"}}></span></label>
                            <input type="url" className={`form-control ${privacyPolicyUrl ? 'filled' : ''}`}id="privacy_policy_url" name="privacy_policy_url" placeholder="Enter url for privacy policy" value={privacyPolicyUrl} onChange={(e) => setPrivacyPolicyUrl(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                    </div>
                </form>
                <ToastContainer />
            </div>
        </section>
    );
}

export default LanguageForm;
