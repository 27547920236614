import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton, Pagination, Modal, InputLabel, FormControl
} from '@mui/material';
import { useUser } from '../../UserContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Edit, Delete, Save, Add, Cancel } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const ViewWidgetsList = () => {
    const navigate = useNavigate();
    const { selectedLanguage } = useUser();
    const [contents, setContents] = useState([]);
    const [imagePreviewLight, setImagePreviewLight] = useState(null);
    const [icon, setIcon] = useState('');
    const [iconDark, setIconDark] = useState('');
    const [imagePreviewDark, setImagePreviewDark] = useState(null);
    const [filters, setFilters] = useState({
        status: false,
        lang_id: selectedLanguage?.id
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [webActivity, setWebActivity] = useState([])
    const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
    ]);
    const [widgetOptions, setWidgetOptions] = useState([{ "id": 1, "title": 'WebView' },
    { "id": 2, "title": 'External Link' },
    { "id": 3, "title": 'Activity' }]);
    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const [editRowId, setEditRowId] = useState(null);
    const [editedContent, setEditedContent] = useState({
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const fetchAcitvity = async () => {
        const response = await apiGetCall(`/master/widget_activity_list?lang_id=${selectedLanguage?.id}`,{})
        setWebActivity(response.data)
    }
    useEffect(() => {
        fetchAcitvity()
    }, [editedContent?.widget_action])
    useEffect(() => {
        if (selectedLanguage?.id) {
            setFilters(prevFilters => ({
                ...prevFilters,
                lang_id: selectedLanguage.id
            }));
        }
    }, [selectedLanguage]);

    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);
    const fetchData = async() => {
        try {
            const response = await apiGetCall(`/master/widget_list?page=${currentPage}&pageSize=20`,filters)
            if (Array.isArray(response.data.data)) {
                    setContents(response.data.data);
                    setTotalPages(response.data.totalPages);
                } else {
                    console.error('Fetched data is not an array:', response.data);
                }
        } catch (error) {
            
        }
    };
    const getWebActivityLabel = (id) => {
        const activity = webActivity.find((cat) => cat.id === id);
        return activity ? activity.category_title : '';
      };
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleEditClick = (id) => {
        setEditRowId(id);
        const content = contents.find(content => content.id === id);
        console.log('---------80', content)
        setEditedContent({ ...content });
        fetchAcitvity()
        setImagePreviewLight(content?.widget_icon)
        setImagePreviewDark(content?.widget_dark_icon)
        setModalOpen(true);
        console.log(editedContent, '-------------------103')
    };

    const handleCancelClick = () => {
        setEditRowId(null);
        setEditedContent({});
        setModalOpen(false);
        setIcon('')
        setIconDark('')
    };

    const handleSaveClick = async(e) => {
        e.preventDefault()
        let formData = new FormData();
        Object.keys(editedContent).forEach(key => {
            try {
                const value = editedContent[key];
                formData.append(key, value);
            } catch (error) {
                console.log('Error appending key to formData:', error);
            }
        });

        if (icon) {
            try {
                formData.append('widget_icon', icon);
            } catch (error) {
                console.log('Error appending icon:', error);
            }
        }
        if (iconDark) {
            formData.append('widget_dark_icon', iconDark)
        }
        console.log(formData, '------------------134')
        try {
            const response = await apiCallForm(`/master/update_widget`,formData,'PUT')
            if (response && response.data.status) {
                            setEditRowId(null);
                            setEditedContent({});
                            toast.success('Data updated successfully');
                            fetchData()
                            setModalOpen(false);
                            console.log('Modal should close now');
                        } else {
                            console.error('Error updating data:', data.message);
                            toast.error('Failed to update data');
                        }
        } catch (error) {
            toast.error('Failed to update data');
        }finally{
            navigate('/master/widgets');
        }
    };

    const handleImageChange = (e, type) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            if (type === "light") {
                setImagePreviewLight(reader.result);
                setIcon(file)
            }
            else if (type == 'dark') {
                setImagePreviewDark(reader.result);
                setIconDark(file)
            }
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };
    const handleDeleteClick = (id) => {
        // Delete data via API
        fetch(`https://your-api-endpoint.com/contents/${id}`, {
            method: 'DELETE'
        })
            .then(() => {
                const updatedContents = contents.filter(content => content.id !== id);
                setContents(updatedContents);
            })
            .catch(error => console.error('Error deleting data:', error));
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value, '---------------187')
        setEditedContent({
            ...editedContent,
            [name]: value
        });
    };
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };


    return (
        <>
            <div className="header-container">
                <header>
                    <h1>Widget List</h1>
                </header>
                <div className="add-author-button">
                    <Link className="text-white" to="/master/addwidget">Add Widget</Link>
                </div>
            </div>

            <div style={{ width: '100%', overflowX: 'auto' }}>
                <TableContainer component={Paper} style={{ width: '100%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>CMS Id</TableCell>
                                <TableCell>Widget Title</TableCell>
                                <TableCell>Icon Light</TableCell>
                                <TableCell>Icon Dark</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Created On</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={filters.status}
                                        onChange={handleFilterChange}
                                        displayEmpty
                                        name="status"
                                        size="small"
                                        variant="outlined"
                                    >
                                        {statusOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contents.map((content) => (
                                <TableRow key={content.id}>
                                    <TableCell>{content.id}</TableCell>
                                    <TableCell>{content.cms_id}</TableCell>
                                    <TableCell>{content.widget_title}</TableCell>
                                    <TableCell>{content.widget_icon && (
                                        <img src={content.widget_icon} alt="Layout Image" style={{ width: '50px', height: '50px' }} />
                                    )}</TableCell>
                                    <TableCell>{content.widget_dark_icon && (
                                        <img src={content.widget_dark_icon} alt="Layout Image" style={{ width: '50px', height: '50px' }} />
                                    )}</TableCell>
                                    <TableCell>{content.category_title}</TableCell>
                                    <TableCell>{content.widget_status === 1 ? "Enabled" : "Disabled"}</TableCell>
                                    <TableCell>{new Date(content.created * 1000).toLocaleString()}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteClick(content.id)} aria-label="delete">
                                            <Delete />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                    <Button
                        variant="outlined"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>
                <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="edit-content-modal"
                    aria-describedby="modal to edit content"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <section className="panel edit_section col-6">
                        <header className="panel-heading" >
                            Edit Widget
                        </header>
                        <div className="panel-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            <form role="form" onSubmit={handleSaveClick} encType="multipart/form-data" id="edit_language_form">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="widget_title">Widget Title<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editedContent.widget_title ? 'filled' : ''}`}
                                            id="widget_title"
                                            name="widget_title"
                                            placeholder="Layout Title"
                                            value={editedContent.widget_title}
                                            onChange={(e) => handleEditChange({ target: { name: "widget_title", value: e.target.value } })}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="widget_status">Widget Status</label>
                                        <select
                                            value={editedContent.widget_status}
                                            onChange={(e) => handleEditChange({ target: { name: "widget_status", value: e.target.value } })}
                                            className={`form-control ${editedContent.widget_status ? 'filled' : ''}`}
                                            name="widget_status"
                                        >
                                            {statusOptions.filter(option => option.value !== false).map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="widget-image-light">Widget Icon Light (Image Ratio 1:1)</label>
                                        <input
                                            id="widget-image-light"
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => handleImageChange(e, "light")}
                                            style={{ marginBottom: '8px', display: 'block' }}
                                        />
                                        {imagePreviewLight && (
                                            <img src={imagePreviewLight} alt="State Dark" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                                        )}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="widget-image-dark">Widget Icon Dark (Image Ratio 1:1)</label>
                                        <input
                                            id="widget-image-dark"
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => handleImageChange(e, "dark")}
                                            style={{ marginBottom: '8px', display: 'block' }}
                                        />
                                        {imagePreviewDark && (
                                            <img src={imagePreviewDark} alt="State Dark" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                                        )}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="widget_action">Widget Action</label>
                                        <select
                                            value={editedContent.widget_action}
                                            onChange={(e) => handleEditChange({ target: { name: "widget_action", value: e.target.value } })}
                                            className={`form-control ${editedContent.widget_action ? 'filled' : ''}`}
                                            name="widget_action"
                                        >
                                            {widgetOptions.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="widget_activity_url">Activity Action<span style={{ color: "#ff0000" }}>*</span></label>
                                        {editedContent.widget_action == '3' ? (
                                            <select
                                                id="activity_widget"
                                                className={`form-control ${editedContent.widget_action_url ? 'filled' : ''}`}
                                                value={editedContent.widget_action_url}
                                                onChange={(e) => handleEditChange({ target: { name: "widget_action_url", value: e.target.value } })}
                                            >
                                                {webActivity.map((cat) => (
                                                    <option key={cat.id} value={cat.id}>
                                                        {cat.category_title}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <input
                                                type="text"
                                                className={`form-control ${editedContent ? 'filled' : ''}`}
                                                id="widget_action_url"
                                                name="widget_action_url"
                                                placeholder="Widget Action Url"
                                                value={editedContent.widget_action_url}
                                                onChange={(e) => handleEditChange({ target: { name: "widget_action_url", value: e.target.value } })}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>
                                            Save
                                        </button>
                                        <button type="button" className="btn btn-secondary" onClick={handleCancelClick} style={{ padding: "15px", lineHeight: "1.5" }}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <ToastContainer />
                        </div>
                    </section>
                </Modal>

            </div>
        </>
    );
};
export default ViewWidgetsList;
