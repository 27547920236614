import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/shared/Layout';
import Login from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import NotFoundPage from './pages/404';
import UpdateAdsCode from './pages/ads/UpdateAdsCode'
import AddApiVersion from './pages/configurations/AddApiVersion'
import UnauthorizedPage from './pages/Unauthorized';
import Language from './pages/language/Language';
import AddLanguage from './pages/language/AddLanguage';
import AppLabelList from './pages/language/AppLabelList';
import UpdateLabelList from './pages/language/UpdateAppLabel';
import ViewState from './pages/state/ViewState';
import AddState from './pages/state/AddState';
import AddLayout from './pages/layouts/AddLayouts';
import ViewLayout from './pages/layouts/ViewLayouts';
import AddAds from './pages/ads/AddAdds';
import ViewAds from './pages/ads/ViewAdds';
import AddWidget from './pages/widgets/AddWidgets';
import ViewWidget from './pages/widgets/ViewWidgets';
import AddSplash from './pages/splash/AddSplash';
import ViewSplash from './pages/splash/ViewSplash';
import CacheManagementList from './pages/configurations/cacheManagementList';
import VersionControlList from './pages/configurations/versionList';
import ViewBackendUsersList from './pages/adminManagement/ViewBackendUsers';
import ViewContentList from './pages/contentManagement/ViewContentList';
import ViewGalleryList from './pages/contentManagement/ViewGalleryList';
import ViewWebStoryList from './pages/contentManagement/ViewWebStory';
import ViewTop9List from './pages/contentManagement/ViewTop9';
import ViewBackendUsersActivityLogs from './pages/adminManagement/ViewActivityLogs';
import RoleManagement from './pages/adminManagement/RoleManagement';
import ViewAuthorsList from './pages/authors/ViewAuthor';
import AuthorsForm from './pages/authors/AddAuthors';
import ViewSubscriptionPlanList from './pages/subScription/ViewSubScription';
import ViewContentAccessList from './pages/subScription/ViewContentAccess';
import ViewTransactionsList from './pages/subScription/ViewTransactionList';
import ViewAppUsers from './pages/appUsers/ViewAppUsers';
import ViewContentOrder from './pages/contentManagement/ViewContentOrder';
import ViewContentIconVisibility from './pages/contentManagement/ViewContentIconVisibility';
import ViewBannerList from './pages/subScription/ViewBanner';
import AddBanner from './pages/subScription/AddBanner';
import AddContentAccess from './pages/subScription/AddContentAccess';
import AddMenuSettings from './pages/configurations/AddMenu';
import AddSection from './pages/configurations/AddSection';
import ViewSaveSettingsForm from './pages/configurations/ViewAndSaveSettings';
import ViewAppSettingsList from './pages/configurations/ViewAppSettings';
import AddPlanForm from './pages/subScription/AddPlan';
import AddPlanTitleForm from './pages/subScription/AddPlanTitle'
import AddOrderIcon from './pages/contentManagement/AddOrderIcon'
import AddIconVisibility from './pages/contentManagement/AddIconVisibility'
import AddAppLabel from './pages/language/AddAppLabel'
import ViewManuSectionList from './pages/configurations/menuSectionList'
import { UserProvider } from './UserContext';
import UpdateSection from './pages/configurations/UpdateSection';
import AddBackendUserForm from './pages/adminManagement/AddBackendUser';
// Custom PrivateRoute component
// const PrivateRoute = ({ element, allowedUserTypes }) => {
//   const { state: userState } = useContext(UserContext);
//   const userTypes = ['76', 'editor'];

//   if (userState.isLoggedIn && allowedUserTypes.some(type => userTypes.includes(type))) {
//     return element;
//   } else {
//     // Redirect to login or unauthorized page as needed
//     return <Navigate to="/login" replace />;
//   }
// };
import PrivateRoute from '../src/PrivateRoute'

function App() {
  const props = {
    articleType: Object.freeze([1, 4]),
    gallery: Object.freeze([2])
  };

  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />

          {/* Protect routes with PrivateRoute */}
          {/* <Route
            path="/"
            element={<PrivateRoute allowedUserTypes={['76','86',"88","89"]} element={<Layout />} />}
          > */}

          <Route
            path="/"
            element={<PrivateRoute allowedUserTypes={['admin', 'editor']} element={<Layout />} />}
          >
            <Route path="/dashboard" element={<Dashboard />} />
            <Route exact path="/unauthorized" component={UnauthorizedPage} />
            
            <Route path="/master/language" element={<PrivateRoute allowedUserTypes={['76']} element={<Language />} />} />
            <Route path="/master/addlanguage" element={<PrivateRoute allowedUserTypes={['76']} element={<AddLanguage/>} />}/>
            <Route path="/master/app_label" element={<PrivateRoute allowedUserTypes={['76']} element={<AppLabelList />} />}/>
            <Route path="/add-app-label" element={<PrivateRoute allowedUserTypes={['76']} element={<AddAppLabel />} />}/>
            <Route path="/master/edit-ads-list/:id" element={<PrivateRoute allowedUserTypes={['76','89']} element={<UpdateAdsCode />} />}/>
            <Route path="/edit-app-label/:id/:screen" element={<PrivateRoute allowedUserTypes={['76']} element={<UpdateLabelList />} />}/>
            <Route path="/master/state" element={<PrivateRoute allowedUserTypes={['76']} element={<ViewState />} />} />
            <Route path="/master/addstate" element={<PrivateRoute allowedUserTypes={['76']} element={<AddState />} />}/>
            <Route path="/master/layouts" element={<PrivateRoute allowedUserTypes={['76']} element={<ViewLayout />} />} />
            <Route path="/master/addlayout" element={<PrivateRoute allowedUserTypes={['76']} element={<AddLayout />} />}/>
            <Route path="/master/ads" element={<PrivateRoute allowedUserTypes={['76','89']}element={<ViewAds />} />}/>
            <Route path="/master/addads" element={<PrivateRoute allowedUserTypes={['76','89']}element={<AddAds />} />}/>
            <Route path="/master/widgets" element={<PrivateRoute allowedUserTypes={['76']}element={<ViewWidget />} />}/>
            <Route path="/master/addwidget" element={<PrivateRoute allowedUserTypes={['76']} element={<AddWidget />} />}/>
            <Route path="/master/splash" element={<PrivateRoute allowedUserTypes={['76']} element={<ViewSplash />} />}/>
            <Route path="/master/addsplash" element={<PrivateRoute allowedUserTypes={['76']}element={<AddSplash />} />} />
            <Route path="/configuration/chache_management" element={<PrivateRoute allowedUserTypes={['76']} element={<CacheManagementList />} />} />
            <Route path="/configuration/vc" element={<PrivateRoute allowedUserTypes={['76']} element={<VersionControlList />} />} />
            <Route path="/backend_management/backend_management" element={<PrivateRoute allowedUserTypes={['76']} element={<ViewBackendUsersList />} />}/>
            <Route path="/content_management/content" element={<PrivateRoute allowedUserTypes={['76','88']} element={<ViewContentList {...props}/>} />}/>
            <Route path="/content_management/gallery"  element={<PrivateRoute allowedUserTypes={['76','88']}element={<ViewGalleryList />} />} />
            <Route path="/content_management/webstory" element={<PrivateRoute allowedUserTypes={['76','88']} element={<ViewWebStoryList />} />} />
            <Route path="/admin/add_authors" element={<PrivateRoute allowedUserTypes={['76']} element={<AuthorsForm />} />}/>
            <Route path="/admin/add_backend_user" element={<PrivateRoute allowedUserTypes={['76']} element={<AddBackendUserForm />} />}/>
            <Route path="/configuration/add_app_settings" element={<PrivateRoute allowedUserTypes={['76']} element={<AddMenuSettings />} />} />
            <Route path="/config/add_menu_section/:menu_id/:menu_side" element={<PrivateRoute allowedUserTypes={['76']} element={<AddSection/>} />} />
            <Route path="/config/view_menu_section/:menu_id/:menu_side" element={<PrivateRoute allowedUserTypes={['76']} element={<ViewManuSectionList/>} />} />
            <Route path="/configuration/add_api_version" element={<PrivateRoute allowedUserTypes={['76']} element={<AddApiVersion/>} />} />
            <Route path="/author/author_management" element={<PrivateRoute allowedUserTypes={['76']} element={<ViewAuthorsList />} />} />
            <Route path="/content_management/top9" element={<PrivateRoute allowedUserTypes={['76','88']} element={<ViewTop9List />} />}/>
            <Route path="/content/create_order_icon" element={<PrivateRoute allowedUserTypes={['76','88']} element={<AddOrderIcon />} />}/>
            <Route path="/content/create_icon_visibility" element={<PrivateRoute allowedUserTypes={['76']} element={<AddIconVisibility />} />}/>
            <Route path="/backend_management/activity_log" element={<PrivateRoute allowedUserTypes={['76']} element={<ViewBackendUsersActivityLogs />} />}/>
            <Route path="/subcription/plans" element={<PrivateRoute allowedUserTypes={['76']} element={<ViewSubscriptionPlanList />} />}/>
            <Route path="/backend_management/role_access" element={<PrivateRoute allowedUserTypes={['76']}element={<RoleManagement />} />}/>
            <Route path="/subcription/banner" element={<PrivateRoute allowedUserTypes={['76']}element={<ViewBannerList />} />}/>
            <Route path="/add_subcription/banner" element={<PrivateRoute allowedUserTypes={['76']}element={<AddBanner />} />}/>
            <Route path="/add_subcription/plan" element={<PrivateRoute allowedUserTypes={['76']}element={<AddPlanForm />} />}/>
            <Route path="/add_subcription/plan_title" element={<PrivateRoute allowedUserTypes={['76']}element={<AddPlanTitleForm />} />}/>
            <Route path="/subcription/cam" element={<PrivateRoute allowedUserTypes={['76']}element={<ViewContentAccessList />} />}/>
            <Route path="/add_subcription/cam" element={<PrivateRoute allowedUserTypes={['76']}element={<AddContentAccess/>} />}/>
            <Route path="/configuration/settings" element={<PrivateRoute allowedUserTypes={['76']} element={<ViewSaveSettingsForm />} />} />
            <Route path="/subcription/subtxn" element={<PrivateRoute allowedUserTypes={['76']} element={<ViewTransactionsList />} />} />
            <Route path="/appuser/application_user" element={<PrivateRoute allowedUserTypes={['76']} element={<ViewAppUsers />} />} />
            <Route path="/configuration/app_settings" element={<PrivateRoute allowedUserTypes={['76']} element={<ViewAppSettingsList />} />} />
            <Route path="/configuration/update_section/:menu_id/:section_id" element={<PrivateRoute allowedUserTypes={['76']} element={<UpdateSection />} />} />
            <Route path="/content_management/co_icon" element={<PrivateRoute allowedUserTypes={['76','88']} element={<ViewContentOrder />} />} />
            <Route path="/content_management/icon_visibility" element={<PrivateRoute allowedUserTypes={['76','88']} element={<ViewContentIconVisibility />} />} />
            </Route>

          <Route path="/404" element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;