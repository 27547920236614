import React from 'react';
import { NavLink, useLocation,Link } from 'react-router-dom';
import classNames from 'classnames';

const linkClass =
  'flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base';
  
function SidebarLink({ link, openSubMenu }) {
const { pathname } = useLocation();
const isMainMenuIcon = link.key === 'main'; // Add condition for main menu icon
const isSidebarMenuIcon = link.key === 'sidebar'; // Add condition for sidebar menu icon

return (
    <Link
    to={link.path}
    className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4` + classNames(
        pathname === link.path ? 'bg-neutral-700 text-white' : 'text-neutral-400',
        linkClass
    )}
    >
    {(isMainMenuIcon || isSidebarMenuIcon) && <span className="text-xl">{link.icon}</span>}

    {link.icon}

    {/* {typeof link.icon === 'string' ? (
        <span dangerouslySetInnerHTML={{ __html: link.icon }} />
    ) : null} */}
    {link.label}

    {link.subNav && (
        <svg className={`ml-auto w-4 h-4 fill-current text-neutral-400 transform transition-transform duration-300 ease-in-out ${openSubMenu === link.key ? 'rotate-180' : 'rotate-0'}`} viewBox="0 0 20 20">
        <path d="M4.411 6.911c.326-.326.854-.326 1.18 0L10 11.321l4.41-4.41a.836.836 0 011.18 1.18l-5 5a.836.836 0 01-1.18 0l-5-5a.836.836 0 010-1.18z"></path>
        </svg>
    )}
    </Link>
);
}

export default SidebarLink;