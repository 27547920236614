import React, { useState, useEffect } from 'react';
import {
    Pagination, Button, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'

const ViewBackendUsersActivityLogs = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [editData, setEditData] = useState({});
    const [editRow, setEditRow] = useState(null);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const cacheMap = {
        tv_001: "Version",
        tv_002: "Master",
        tv_003: "Home",
        tv_004: "Article List",
        tv_005: "Author",
        tv_006: "Live TV",
        tv_007: "Clear Data"
    };

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    const fetchData = async (page) => {
            try {
                const response = await apiGetCall(`/admin/backend_user_activity_logs?page=${page}&pageSize=20`,{})
                if (Array.isArray(response.data.data)) {
                    setData(response.data.data);
                        setTotalPages(response.data.totalPages);
                    } else {
                        console.error('Fetched data is not an array:', response.data);
                    }
            } catch (error) {
                
            }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleViewClick = (row) => {
        setSelectedRow(row);
        setViewModalOpen(true);
    };

    return (
        <>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>User</th>
                        <th>Activity</th>
                        <th>Area</th>
                        <th>Time</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index}>
                            <td>{row.id}</td>
                            <td>{row.username}</td>
                            <td>{row.comment}</td>
                            <td>{row.segment}</td>
                            <td>{row.activity_time}</td>
                            <td>
                                <IconButton onClick={() => handleViewClick(row)}>
                                    <VisibilityIcon />
                                </IconButton>
                            </td>
                            {/* <td>{row.json}</td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
                <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div>
            <Dialog open={viewModalOpen} onClose={() => setViewModalOpen(false)}>
                <DialogTitle>View Data</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <tbody>
                                {Object.keys(selectedRow).map(key => (
                                    <tr key={key}>
                                        <td><strong>{key}</strong></td>
                                        <td>{selectedRow[key]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setViewModalOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ViewBackendUsersActivityLogs;
