import React, { useState, useEffect, useRef, useContext } from 'react';
import classNames from 'classnames';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import API from '../../../Utils/API';
import { ToastContainer, toast } from 'react-toastify';
import { FcBullish } from 'react-icons/fc';
import { HiOutlineLogout } from 'react-icons/hi';
import { DASHBOARD_SIDEBAR_LINKS, DASHBOARD_SIDEBAR_BOTTOM_LINKS } from '../../../lib/constants';
import LogoutButton from '../../../pages/Logout';
import { UserContext } from '../../../UserContext'; 
import {apiCall,apiCallForm,apiGetCall} from '../../../../src/configs/apiMethodCall'
import { UpdateUserData } from "../../../UserStates/Actions";
import SidebarLink from '../../../components/shared/Sidebar/SidebarLink'

import Logo from '../../../images/logo/logo.png';
import * as IoIcons from 'react-icons/io';

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const { pathname } = location;
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const navigate = useNavigate();
  const { state: userState, dispatch: userDispatch } = useContext(UserContext);

  const toggleSubMenu = (linkKey) => {
    setOpenSubMenu((prevOpenSubMenu) => (prevOpenSubMenu === linkKey ? null : linkKey));
  };

  const triggerRef = useRef(null);
  const sidebarRef = useRef(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  
  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [sidebarOpen, setSidebarOpen]);

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.body.classList.add('sidebar-expanded');
    } else {
      document.body.classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  const handleClickOutside = (event) => {
    if (
      sidebarRef.current &&
      triggerRef.current &&
      !sidebarRef.current.contains(event.target) &&
      !triggerRef.current.contains(event.target)
    ) {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setSidebarOpen]);


  /*const handleLogout = async () => {
    try {
      await API.post('userauth/logout');
      await userDispatch(UpdateUserData({
        isLoggedIn: false,
        userData: null,
      }));
      navigate('/');
    } catch (e) {
      const errors = e.response ? e.response.data.message : undefined;
      toast.success(errors);
    }
  }*/


    const handleLogout = async () => {
      // const { user } = UserContext();

      try {

        // localStorage.removeItem('token');
        // localStorage.removeItem('isLoggedIn');
        // localStorage.removeItem('isLoggedInUsername');
        // localStorage.removeItem('userType');
        
        // // Optionally, reset user context or redirect the user
        // setUser(null);
        
        // console.log('Logout successful, localStorage cleared');

        const response = await apiCall('/userauth/logout',{},'POST');
        console.log(response,'---------43')
        if (response && response?.data?.status){
          localStorage.removeItem('isLoggedIn');
          localStorage.removeItem('userType');
          localStorage.removeItem('cmsToken');
          localStorage.removeItem('perm_id');
        }
        navigate('/login');
        navigate('/login');
      } catch (e) {
        const errors = e.response ? e.response.data.message : undefined;
        toast.success(errors);
      }
    }

  // function SidebarLink({ link, openSubMenu }) {
  //   const { pathname } = useLocation();
  //   const isMainMenuIcon = link.key === 'main'; // Add condition for main menu icon
  //   const isSidebarMenuIcon = link.key === 'sidebar'; // Add condition for sidebar menu icon

  // }
  
  return (
    <aside
      className={`absolute left-0 top-0 z-9999 flex h-screen w-72.5 flex-col overflow-y-hidden bg-blacknew duration-300 ease-linear dark:bg-boxdark lg:static lg:${
        sidebarOpen ? 'translate-x-0' : 'translate-x-0 -translate-x-full'
      }`}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
        <div className="flex items-center gap-2">
          <NavLink to="/">
            <img src={Logo} alt="Logo" width={40} />
          </NavLink>
          <span style={{ color: '#FFF', fontWeight:'600' }}>Dashboard</span>
        </div>

        <button
          ref={triggerRef}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden"
        >
          <svg className="fill-current" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z" fill=""></path></svg>
        </button>
      </div>
      {/* <!-- SIDEBAR HEADER --> */}


      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        {/* <!-- Sidebar Menu --> */}
        <nav className="">
          {/* <!-- Menu Group --> */}
          <div className="py-8 flex flex-1 flex-col gap-0.5">

            {DASHBOARD_SIDEBAR_LINKS.map((link) => (
              <React.Fragment key={link.key}>
                <div
                  className="cursor-pointer"
                  onClick={() => toggleSubMenu(link.key)}
                >
                  <SidebarLink link={link} openSubMenu={openSubMenu} />
                </div>

                {link.subNav && openSubMenu === link.key && (
                  <div className="pl-4">
                    {link.subNav.map((subLink) => (
                      <React.Fragment key={subLink.key}>
                        <SidebarLink link={subLink} />
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
          {/* <div className="flex flex-col gap-0.5 pt-2 border-t border-neutral-700">
            {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((link) => (
              <SidebarLink key={link.key} link={link} />
            ))}
          </div> */}
          <div className="flex flex-col gap-0.5 border-t border-neutral-700">
            <Link
              onClick={handleLogout}
              className="group relative flex items-center gap-2.5 rounded-sm py-2 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4"
              aria-expanded="false"
            >
              <i className="demo-pli-unlock me-3"></i> 
              <IoIcons.IoIosLogOut />
              <span className="nav-label ms-1">Logout</span>
            </Link>  


            
          </div>
        </nav>
        {/* <!-- Sidebar Menu --> */}
      </div>
    </aside>
  );
};

export default Sidebar;
