import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Button, IconButton, Pagination, Modal
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const ViewBackendUsersList = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [editRow, setEditRow] = useState(null);
    const [editData, setEditData] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    const fetchData = async (page) => {
            try {
                const response = await apiGetCall(`/admin/backend_users?page=${page}&pageSize=20`,{})
                if (Array.isArray(response.data.data)) {
                    setData(response.data.data);
                        setTotalPages(response.data.totalPages);
                    } else {
                        console.error('Fetched data is not an array:', response.data);
                    }
            } catch (error) {
                
            }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleCancelClick = () => {
        setEditRow(null);
        setEditData({});
        setModalOpen(false);
    };

    const handleEditClick = (row) => {
        setEditRow(row);
        setEditData({ ...row });
        setModalOpen(true);
    };

    const handleDeleteClick = (row) => {
        axios.delete(`${process.env.REACT_APP_BASE_URL}/config/delete_cache_item?id=${row.id}`)
            .then(response => {
                fetchData(currentPage);
            })
            .catch(error => console.error('Error deleting data:', error));
    };

    const handleEditChange = (field, value) => {
        setEditData({
            ...editData,
            [field]: value
        });
    };

    const handleSaveEdit = () => {
        apiCall(`/config/update_cache_item`, editData,"PUT")
            .then(response => {
                fetchData(currentPage);
                setEditRow(null);
                setModalOpen(false);
            })
            .catch(error => console.error('Error updating data:', error));
    };

    return (
        <>
            <div className="header-container">
                <header>
                    <h1>Backend Users</h1>
                        </header>
                        <div className="add-author-button">
                            <Link className="text-white" to="/admin/add_backend_user">Add Backend User</Link>
                        </div>
                    </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Language</TableCell>
                            <TableCell>User Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>User Role</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Created On</TableCell>
                            <TableCell>Updated On</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.lang_name}</TableCell>
                                <TableCell>{editRow === row ?
                                    <TextField
                                        value={editData.username}
                                        onChange={(e) => handleEditChange('username', e.target.value)}
                                    /> :
                                    <span>{row.username}</span>}
                                </TableCell>
                                <TableCell>{editRow === row ?
                                    <TextField
                                        value={editData.email}
                                        onChange={(e) => handleEditChange('email', e.target.value)}
                                    /> :
                                    <span>{row.email}</span>}
                                </TableCell>
                                <TableCell>{editRow === row ?
                                    <TextField
                                        value={editData.mobile}
                                        onChange={(e) => handleEditChange('mobile', e.target.value)}
                                    /> :
                                    <span>{row.mobile}</span>}
                                </TableCell>
                                <TableCell>{row.permission_group_name}</TableCell>
                                <TableCell>{row.user_state}</TableCell>
                                <TableCell>{row.created_on}</TableCell>
                                <TableCell>{row.updated_on}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEditClick(row)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteClick(row)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
            />
            
            <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="edit-content-modal"
                    aria-describedby="modal to edit content"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <section className="panel edit_section col-6">
                        <header className="panel-heading">
                            Edit User Details
                        </header>
                        <div className="panel-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            <form role="form" onSubmit={handleSaveEdit} encType="multipart/form-data" id="edit_backend_user_form">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="email_user">Email<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editData.email ? 'filled' : ''}`}
                                            id="email"
                                            name="email"
                                            placeholder="Email"
                                            value={editData.email}
                                            onChange={(e) => handleEditChange({ target: { name: "email", value: e.target.value } })}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="user_name">User Name<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editData.email ? 'filled' : ''}`}
                                            id="user_name"
                                            name="user_name"
                                            placeholder="User Name"
                                            value={editData.username}
                                            onChange={(e) => handleEditChange({ target: { name: "user_name", value: e.target.value } })}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>
                                                Save
                                            </button>
                                            <button type="button" className="btn btn-secondary" onClick={handleCancelClick} style={{ padding: "15px", lineHeight: "1.5" }}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                            </form>
                            <ToastContainer />
                        </div>
                    </section>

                </Modal>
        </>
    );
};

export default ViewBackendUsersList;
