import React, { useState, useEffect } from 'react';

const generateCaptcha = () => {
  let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let captcha = '';
  for (let i = 0; i < 6; i++) {
    captcha += chars[Math.floor(Math.random() * chars.length)];
  }
  return captcha;
};

const Captcha = ({ setCaptchaValue }) => {
  // const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captcha, setCaptcha] = useState(generateCaptcha());

  useEffect(() => {
    refreshCaptcha(); // Initialize captcha on component mount
  }, []);


  const refreshCaptcha = () => {
    const newCaptcha = generateCaptcha();
    setCaptcha(newCaptcha);
    setCaptchaValue(newCaptcha);
  };

  return (
    <>
      <div style={{ backgroundColor: '#FCC23A', padding: '5px 10px 5px 10px', fontSize: '1.2em' }}>{captcha}</div>
      <div>
        <button onClick={refreshCaptcha} type="button" className="btnRefresh">
        </button>
      </div>
    </>
  );
}; 
export default Captcha;
