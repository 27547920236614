import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton,Pagination,Modal,InputLabel, FormControl
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { useUser } from '../../UserContext';
import 'react-toastify/dist/ReactToastify.css';
import { Edit, Delete, Save,Add, Cancel } from '@mui/icons-material';
import { Link,useNavigate } from 'react-router-dom'; 
import * as IoIcons from "react-icons/io";
import {apiCall,apiCallForm,apiGetCall,apiPutCall} from '../../../src/configs/apiMethodCall'
const AppLabelList = () => {
    const navigate = useNavigate()
  const { selectedLanguage } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [langId, setLangId] = useState('');
  const [appLabelScreen, setAppLabelScreen] = useState([
   { screen:1, label: "Article Details"},
    {screen:2,label: "Listing"},
    {screen:3,label: "Live Channel"},
    {screen :4,label: "Notification"},
   {screen: 5,label: "Bookmark"},
   {screen: 6,label: "Home"},
   { screen:7,label: "Internet Off"},
   { screen:8,label: "Toasts"},
    {screen:9,label: "Language"},
    {screen:10,label: "States"},
    {screen:11,label: "Categories"},
    {screen :12,label: "Shorts"},
    {screen:13,label: "Player"},
    {screen :14,label: "Left Menu"},
    {screen:15,label: "Author"},
    {screen:16,label: "Registration"},
    {screen:17,label: "Subscription"},
    {screen:18,label: "Setting"},
   { screen:19,label: "Search"}
  ]);
  const [screen, setScreen] = useState('');
  const [appLabelFields, setAppLabelFields] = useState([]);
  const [filters, setFilters] = useState({
    lang_id: selectedLanguage?.id,
    screen: 1,
    status: ''
});
  useEffect(() => {
    if (selectedLanguage?.id) {
      setLangId(selectedLanguage.id);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    const fetchAppLabelFields = async () => {
        try {
            const response = await apiGetCall(`/master/app_label_list`, filters);
            setAppLabelFields(response?.data?.data);  // Set the response data
        } catch (error) {
            console.error('Error fetching additional fields:', error);
        }
    };

    if (langId) {
        fetchAppLabelFields();  // Call the async function when langId exists
    }
}, [langId, screen, filters]);  

  // const handleAppLabelChange = (event) => {
  //   setScreen(event.target.value);
  // };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
        ...filters,
        [name]: value
    });
};
const handleNextPage = () => {
    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
};

const handleStatusChangeClick = (id, status) => {
    console.log("id==", id, "status==", status); // For debugging
    apiCall(`/master/update_app_label/${id}/${status}`,{},
        'PUT',)
    .then(response => {
        if (!response.ok) {
            return response.json().then(err => {alert(err.message || 'Something went wrong');});
        }
        return response.json();
    })
    .then(data => {
        if (data.status) {
            toast.success(data.message);

              if (langId) {
                try {
                    const response = apiGetCall(`/master/app_label_list`,filters)
                    setAppLabelFields(response?.data?.data);
                } catch (error) {
                    console.error('Error fetching additional fields:', error);
                }
              }

        } else {
            console.error('Error updating data:', data.message);
            toast.error('Failed to update data');
        }
    })
    .catch(error => {
        console.error('Error updating data:', error);
        toast.error('Failed to update data');
    });
  }


  const handleEditClick = (id,screen) => {
    console.log(`/edit-app-label/${id}/${screen}`,'------------69')
    navigate(`/edit-app-label/${id}/${screen}`);
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
};
  return (
    <>
      <div className="header-container">
            <header>
                <h1>App Label List</h1>
            </header>
            <div className="add-author-button">
                <Link className="text-white" to="/add-app-label">Add App Label</Link>
            </div>
        </div>

    <div style={{ width: '100%', overflowX: 'auto' }}>
    <TableContainer component={Paper} style={{ width: '100%' }}>
        <Table>				
            <TableHead>
                <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Screen
                            <Select
                            value={filters.screen}
                            onChange={handleFilterChange}
                            displayEmpty
                            name="screen"
                            size="small"
                            variant="outlined"
                        >
                        {appLabelScreen.map((option) => (
                                <MenuItem key={option.screen} value={option.screen}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </TableCell>
                    <TableCell>Article Type</TableCell>
                    <TableCell>Label</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Created On</TableCell>
                    <TableCell>Action</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {appLabelFields.map((content) => (
                    <TableRow key={content.id}>
                        <TableCell>{content.id}</TableCell>
                        {/* <TableCell>{content.cms_id}</TableCell> */}
                        <TableCell>{content.screen}</TableCell>
                        <TableCell>{content.article_type}</TableCell>
                        <TableCell>{content.label}</TableCell>
                        {/* <TableCell>{content.designation}</TableCell> */}
                        <TableCell style={{ color: content.status === 1 ? 'green' : 'red' }} >{content.status === 1 ? "Enabled" : "Disabled"}</TableCell>
                        <TableCell>{new Date(content.created_on * 1000).toLocaleString()}</TableCell>
                        <TableCell>
                            <IconButton onClick={() => handleEditClick(content.id,content.screen)} aria-label="edit">
                                <Edit />
                            </IconButton>
                            <IconButton onClick={() => handleStatusChangeClick(content.id, content.status ? 0 : 1)} aria-label="toggle-status">
                                <IoIcons.IoIosRemoveCircleOutline style={{ color: content.status === 1 ? 'green' : 'red' }} />
                            </IconButton>
                        </TableCell>

                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>

    <ToastContainer />

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
            />
                <Button
                variant="outlined"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
            >
                Next
            </Button>
        </div>
        </div>
</>
);
};

export default AppLabelList;
