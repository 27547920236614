import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton, Pagination, Modal, InputLabel, FormControl
} from '@mui/material';
import { Edit, Delete, Save, Add, Cancel } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const ViewSubscriptionBannerList = () => {
    const [bannerTitle, setBannerTitle] = useState('')
    const [contents, setContents] = useState([]);
    const [filters, setFilters] = useState({
        status: '',
    });
    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const handleEditStatusChange = (e) => {
        // editedContent?.banner_status = e.target.value
    }

    const [editRowId, setEditRowId] = useState(null);
    const [editedContent, setEditedContent] = useState({
    });
    const [imagePreview, setImagePreview] = useState('');
    const [bannerImage, setBannerImage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const fetchData = async () => {
        try {
            const response = await apiGetCall(`/plan/banner_list?page=${currentPage}&pageSize=20`,filters)
            if (Array.isArray(response.data.data)) {
                    setContents(response.data.data);
                    setTotalPages(response.data.totalPages);
                } else {
                    console.error('Fetched data is not an array:', response.data);
                }
        } catch (error) {
            
        }
    };
    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleEditClick = (id) => {
        setEditRowId(id);
        const content = contents.find(content => content.id === id);
        console.log('---------80', content)
        setEditedContent({ ...content });
        setModalOpen(true);
        setImagePreview(content.banner_image)
    };

    const handleCancelClick = () => {
        setEditRowId(null);
        setEditedContent({});
        setModalOpen(false);
    };

    const handleSaveClick = async (e) => {
        e.preventDefault()
        let formData = new FormData();
        Object.keys(editedContent).forEach(key => {
            try {
                const value = editedContent[key];
                formData.append(key, value);
            } catch (error) {
                console.log('Error appending key to formData:', error);
            }
        });

        if (bannerImage) {
            try {
                formData.append('banner_image', bannerImage);
            } catch (error) {
                console.log('Error appending icon:', error);
            }
        }
        console.log(formData, '---------------100')
        try {
            const response = await apiCallForm(`/plan/update_banner`,formData,'PUT')
            if (response && response.data.status) {
                            setEditRowId(null);
                            setEditedContent({});
                            toast.success('Data updated successfully');
                            fetchData()
                            setModalOpen(false);
                            console.log('Modal should close now');
                        } else {
                            console.error('Error updating data:', data.message);
                            toast.error('Failed to update data');
                        }
        } catch (error) {
            toast.error('Failed to update data');
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        // handleEditChange({ target: { name: "banner_image", value: file } });
        setImagePreview(URL.createObjectURL(file));
        setBannerImage(file)
    };
    const handleDeleteClick = (id) => {
        // Delete data via API
        fetch(`https://your-api-endpoint.com/contents/${id}`, {
            method: 'DELETE'
        })
            .then(() => {
                const updatedContents = contents.filter(content => content.id !== id);
                setContents(updatedContents);
            })
            .catch(error => console.error('Error deleting data:', error));
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedContent({
            ...editedContent,
            [name]: value
        });
    };
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
    ]);
    return (
        <>
            <div className="header-container">
                <header>
                    <h1>Banner List</h1>
                </header>
                <div className="add-author-button">
                    <Link className="text-white" to="/add_subcription/banner">Add Banner</Link>
                </div>
            </div>

            <div style={{ width: '100%', overflowX: 'auto' }}>
                <TableContainer component={Paper} style={{ width: '100%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Banner Title</TableCell>
                                <TableCell>Banner Image</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Created On</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={filters.status}
                                        onChange={handleFilterChange}
                                        displayEmpty
                                        name="status"
                                        size="small"
                                        variant="outlined"
                                    >
                                        {statusOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contents.map((content) => (
                                <TableRow key={content.id}>
                                    <TableCell>{content.id}</TableCell>
                                    <TableCell>{content.banner_title}</TableCell>
                                    <TableCell>{content.banner_image && (
                                        <img src={content.banner_image} alt="Banner Image" style={{  height:'100px' }} />
                                    )}</TableCell>
                                    {/* <TableCell>
                                    {content.banner_image && content.image_sizes && (
                                        (() => {
                                        const [width, height] = content.image_sizes.split('X').map(Number);
                                        return (
                                            <img
                                            src={content.banner_image}
                                            alt="Banner Image"
                                            style={{ width: `${width}px`, height: `${height}px` }}
                                            />
                                        );
                                        })()
                                    )}
                                    </TableCell> */}
                                    <TableCell>{content.banner_status === 1 ? "Enabled" : "Disabled"}</TableCell>
                                    <TableCell>{new Date(content.created_on).toLocaleString()}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteClick(content.id)} aria-label="delete">
                                            <Delete />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                    <Button
                        variant="outlined"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>
                {/* <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="edit-content-modal"
                    aria-describedby="modal to edit content"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh',
                        paddingLeft: '250px',
                    }}
                >
                    <div className="modal-content">
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    id="banner_title"
                                    label="Banner Title*"
                                    variant="outlined"
                                    value={editedContent.banner_title}
                                    onChange={(e) => handleEditChange({ target: { name: "banner_title", value: e.target.value } })}
                                    fullWidth
                                    required
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <Select
                                    value={editedContent.banner_status}
                                    onChange={handleEditStatusChange}
                                    displayEmpty
                                    name="status"
                                    size="small"
                                    variant="outlined"
                                >
                                    {statusOptions.map((option) => {
                                         if (option.value === false) {
                                            return null;
                                        }
                                        return (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <FormControl fullWidth margin="normal">
                                <label htmlFor="state-image-light" style={{ marginBottom: '8px' }}>Icon Light</label>
                                <input
                                    id="state-image-banner"
                                    type="file"
                                    onChange={(e) => handleImageChange(e, "preview")}
                                    style={{ marginBottom: '8px' }}
                                />
                                {imagePreview &&
                                    <img
                                        src={imagePreview}
                                        alt="Banner Language"
                                        style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                    />
                                }
                            </FormControl>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 20,
                        }}>
                            <Button variant="contained" color="primary" onClick={handleSaveClick}>
                                Save
                            </Button>
                            <Button variant="contained" color="secondary" onClick={handleCancelClick} style={{ marginLeft: 10 }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal> */}
                <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="edit-content-modal"
                    aria-describedby="modal to edit content"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <section className="panel edit_section col-6">
                        <header className="panel-heading" >
                            Edit Banner
                        </header>
                        <div className="panel-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            <form role="form" onSubmit={handleSaveClick} encType="multipart/form-data" id="edit_language_form">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="state_name">Banner Title<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editedContent.banner_title ? 'filled' : ''}`}
                                            id="banner_title"
                                            name="banner_title"
                                            placeholder="Banner Title"
                                            value={editedContent.banner_title}
                                            onChange={(e) => handleEditChange({ target: { name: "banner_title", value: e.target.value } })}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="lang_status">Banner Status</label>
                                        <select
                                            value={editedContent.banner_status}
                                            onChange={(e) => handleEditChange({ target: { name: "banner_status", value: e.target.value } })}
                                            className={`form-control ${editedContent.banner_status ? 'filled' : ''}`}
                                            name="banner_status"
                                        >
                                            {statusOptions.filter(option => option.value !== false).map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="state-image-light">State Image Light (Image Ratio 1:1)</label>
                                        <input
                                            id="banner-image-light"
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => handleImageChange(e, "preview")}
                                            style={{ marginBottom: '8px' }}
                                        />
                                        {imagePreview &&
                                            <img
                                                src={imagePreview}
                                                alt="Banner Image"
                                                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                            />
                                        }
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>
                                                Save
                                            </button>
                                            <button type="button" className="btn btn-secondary" onClick={handleCancelClick} style={{ padding: "15px", lineHeight: "1.5" }}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <ToastContainer />
                        </div>
                    </section>

                </Modal>
            </div>
        </>
    );
};
export default ViewSubscriptionBannerList;
