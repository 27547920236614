import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useUser } from '../../../src/UserContext';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Ads.css'
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
function AdsForm(props) {
    const navigate = useNavigate();
    const { selectedLanguage } = useUser();
    const [stickyAdsCode, setStickyAdsCode] = useState("");
    const [isSubmit,setIsSubmit] = useState(false)
    const [galleryAdsCode, setGalleryAdsCode] = useState("");
    const [galleryAdsSize, setGalleryAdSize] = useState("");
    const [galleryAdsOrder, setGalleryAdsOrder] = useState("");
    const [videoAdsCode, setVideoAdsCode] = useState("");
    const [videoAdsSize, setVideoAdsSize] = useState("");
    const [videoAdsOrder, setVideoAdsOrder] = useState("");
    const [detailsAdsCode, setDetailsAdsCode] = useState("");
    const [detailsAdsSize, setDetailsAdSize] = useState("");
    const [detailsAdsOrder, setDetailsAdsOrder] = useState("");
    const [listingAdsCode, setListingAdsCode] = useState("");
    const [listingAdsSize, setListingAdsSize] = useState("");
    const [interstitialAdsCode ,setinterstitialAdsCode] = useState("");
    const [interstitialAdsCount ,setinterstitialAdsCount] = useState("")
    const [liveAdsCode, setLiveAdsCode] = useState('')
    const [liveAdsPosition, setLiveAdsPosition] = useState('')
    const [liveAdsDuration, setLiveVideoDuration] = useState('')
    const [activeTab, setActiveTab] = useState('Article');
    const [errors, setErrors] = useState({});

    const adsSizeMap = [
                    {id:1, value: "BANNER(320, 50)"},
                    {id :2, value:"FULL_BANNER(468, 60)"},
                    {id :3, value: "LARGE_BANNER(320, 100)"},
                    {id :4, value : "MEDIUM_RECTANGLE(300, 250)"}
    ]
    const adsSizeLiveMap = [
        {id:1, value: "PRE"},
        {id :2, value:"MID"},
        {id :3, value: "END"}
]
    const handleReset = () => {
        setStickyAdsCode("");
        setGalleryAdsCode("");
        setGalleryAdSize("");
        setGalleryAdsOrder("");
        setVideoAdsCode("");
        setVideoAdsSize("");
        setVideoAdsOrder("");
        setDetailsAdsCode("");
        setDetailsAdSize("");
        setDetailsAdsOrder("");
        setListingAdsCode("");
        setListingAdsSize("");
        setLiveAdsCode("");
        setLiveAdsPosition("");
        setErrors("");
    };
    const handleTabClick = (e, tabName) => {
        e.preventDefault();
        setActiveTab(tabName);
    };
    const validateForm = (formType) => {
        let formErrors = {};
            
            if (formType === 'sticky_ads') {
                console.log(stickyAdsCode,'-------------27')
                if (!stickyAdsCode) {
                    formErrors.stickyAdsCode = 'Title is required';
                }
            } else if (formType === 'interstitial_ads') {
                if (!interstitialAdsCode || !interstitialAdsCount) {
                    formErrors.interstitialAdsCode = 'Missing required field';
                }
            } else if (formType === 'listing_ads') {
                if (!listingAdsCode || !listingAdsSize) {
                    formErrors.listingAdsCode = 'Missing required field';
                }
            }else if (formType === 'article_ads'){
                if (!detailsAdsCode || !detailsAdsSize || !detailsAdsOrder) {
                    formErrors.detailsAdsCode = 'Missing required field';
                }
                // else if ( (typeof detailsAdsOrder !== 'number') || (typeof detailsAdsSize !== 'number')){
                //     console.log(detailsAdsSize,'--------------55',typeof detailsAdsOrder)
                //     formErrors.detailsAdsSize = 'Ads Position and Ads Size must be a number';
                // }
            } else if (formType === 'gallery_ads_code'){
                if (!galleryAdsCode || !galleryAdsSize || !galleryAdsOrder) {
                    formErrors.detailsAdsCode = 'Missing required field';
                }

            } else if (formType === 'video_ads'){
                if (!videoAdsCode || !videoAdsSize || !videoAdsOrder) {
                    formErrors.detailsAdsCode = 'Missing required field';
                }
            } else if (formType === 'live_ads'){
                if (!liveAdsCode || !liveAdsPosition || (!liveAdsDuration && liveAdsPosition ==2)) {
                    formErrors.detailsAdsCode = 'Missing required field';
                }
            }
        return formErrors;
    };
    const handleSubmit = async (event,formType) => {
        event.preventDefault();
        if (selectedLanguage == null) {
            toast.error("Please select a language first");
            return
        }
        const formErrors = validateForm(formType);

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            toast.error(Object.values(formErrors)[0]);
            return;
        }
        try {
            let payload
            // payload["lang_id"] = selectedLanguage?.id
            if (formType === 'sticky_ads') {
               payload = {
                sticky_ads_code: stickyAdsCode,
                };
            } else if (formType === 'interstitial_ads') {
                payload = {
                    interstitial_ads_code: interstitialAdsCode,
                    interstitial_ads_count:interstitialAdsCount
                    };
            } else if (formType === 'listing_ads') {
                payload = {
                    listing_ads_code:listingAdsCode,
                    listing_ads_size:listingAdsSize
                }
            } else if (formType === 'article_ads'){
                payload = {
                    detail_ads_code:detailsAdsCode,
                    detail_ads_size:detailsAdsSize,
                    detail_ads_order:detailsAdsOrder
                }
            } else if (formType === 'gallery_ads'){
                payload = {
                    gallery_ads_code:galleryAdsCode,
                    gallery_ads_size:galleryAdsSize,
                    gallery_ads_order:galleryAdsOrder
                }

            } else if (formType === 'video_ads'){
                payload = {
                    video_ads:videoAdsCode,
                    video_ads_duration:videoAdsSize,
                    video_ads_position:videoAdsOrder
                }
            } else if (formType === 'live_ads'){
                payload = {
                    live_ads_code :liveAdsCode,
                    live_ads_duration: liveAdsDuration,
                    live_ads_position:liveAdsPosition
                }
            }
            console.log(formType,'---------------999',payload)
            payload["lang_id"] = selectedLanguage?.id
            if (isSubmit){
                return
            }
            setIsSubmit(true)
            // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/master/create_ads`, payload, {
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // });
            const response = await apiCall('/master/create_ads',payload,'POST')

            if (response.data.status) {
                console.log('Data saved successfully');
                toast.success("Data saved successfully", {
                });            
            } else {
                console.error('Error while saving data:', "Error while saving data:");
                toast.error(response.data.message, {
                });
            }
        } catch (error) {
            console.error('Error while saving data:', error);
            toast.error(error, {
            });
        }finally{
            setIsSubmit(false)
            navigate('/master/ads'); 
        }
    };

    return (
        <section className="panel add_section">
            <div className="panel-body">
                <form role="form" onSubmit={(event) => handleSubmit(event, 'sticky_ads')} id="sticky_ads_form">
                    <div>
                        <section className="panel">
                            <header className="panel-heading" style={{ fontWeight: "bold" }}>Sticky Ads</header>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor="sticky_ads">Sticky Ads<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className={`form-control ${stickyAdsCode ? 'filled' : ''}`} id="sticky_name" name="sticky_name" placeholder="Enter Sticky Ads Code" value={stickyAdsCode} onChange={(e) => setStickyAdsCode(e.target.value)} />
                                </div>
                            </div>
                        </section>
                        <div className="col-md-12 mt-3">
                            <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                        </div>
                    </div>
                </form>

                <form role="form" onSubmit={(event) => handleSubmit(event, 'interstitial_ads')} id="interstitial_ads_form">
                    <div className="row mt-3">
                        <section className="panel">
                            <header className="panel-heading" style={{ fontWeight: "bold" }}>Interstitial Ads</header>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor="interstitial_ads">Interstitial Ads<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className={`form-control ${interstitialAdsCode ? 'filled' : ''}`}id="interstitial_name" name="interstitial_name" placeholder="Interstitial Ads" value={interstitialAdsCode} onChange={(e) => setinterstitialAdsCode(e.target.value)} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="layout_code">Count After Show Interstitial<span style={{color: "#ff0000"}}>*</span></label>
                                    <input type="number" className={`form-control ${interstitialAdsCount ? 'filled' : ''}`} id="nterstitial_name" name="interstitial_code_count" placeholder="Interstitial Ads Code" value={interstitialAdsCount} onChange={(e) => setinterstitialAdsCount(e.target.value)} />
                                </div>
                            </div>
                        </section>
                        <div className="col-md-12 mt-3">
                            <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                        </div>
                    </div>
                </form>

                <form role="form" onSubmit={(event) => handleSubmit(event, 'listing_ads')} id="listing_ads_form">
                    <div className="row mt-3">
                        <section className="panel">
                            <header className="panel-heading" style={{ fontWeight: "bold" }}>Listing Ads</header>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor="listing_ads_code">Ads Code<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className={`form-control ${listingAdsCode ? 'filled' : ''}`} id="listing_ads_code" name="listing_ads_code" placeholder="Listing Ads Code" value={listingAdsCode} onChange={(e) => setListingAdsCode(e.target.value)} />
                                </div>
                                <div className="form-group col-md-6">
                                        <label htmlFor="layout_code">Listing Ads Size<span style={{color: "#ff0000"}}>*</span></label>
                                        <select
                                            id="menu_action_element"
                                            className={`form-control ${listingAdsSize ? 'filled' : ''}`}
                                            value={listingAdsSize}
                                            onChange={(e) => setListingAdsSize(e.target.value)}
                                        >
                                            <option value="">--Select an Ads Size--</option>
                                            {adsSizeMap.map((cat) => (
                                                <option key={cat.id} value={cat.id}>
                                                    {cat.value}
                                                </option>
                                            ))}
                                        </select>
                                </div>
                            </div>
                        </section>
                        <div className="col-md-12 mt-3">
                            <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                        </div>
                    </div>
                </form>

                <div className="row mt-3">
                    <header className="panel-heading">Article Content Ads</header>
                    <div className="tabs">
                        <button className={`tab-button ${activeTab === 'Article' ? 'active' : ''}`} onClick={(e) => handleTabClick(e, 'Article')}>Article</button>
                        <button className={`tab-button ${activeTab === 'PhotoGallery' ? 'active' : ''}`} onClick={(e) => handleTabClick(e, 'PhotoGallery')}>Photo Gallery</button>
                        <button className={`tab-button ${activeTab === 'Video' ? 'active' : ''}`} onClick={(e) => handleTabClick(e, 'Video')}>Video</button>
                        <button className={`tab-button ${activeTab === 'Live' ? 'active' : ''}`} onClick={(e) => handleTabClick(e, 'Live')}>Live</button>
                    </div>

                    <div id="Article" className={`tab-content ${activeTab === 'Article' ? 'active' : ''}`}>
                        <form role="form" onSubmit={(event) => handleSubmit(event, 'article_ads')} id="article_ads_form">
                            <label htmlFor="ads-code">Ads Code*</label>
                            <input type="text" id="ads-code" name="ads-code" className={`form-control ${detailsAdsCode ? 'filled' : ''}`}placeholder="Enter ads code"  value={detailsAdsCode} onChange={(e) => setDetailsAdsCode(e.target.value)} />
                            <label htmlFor="ads-position">Ads Position*</label>
                            <input type="text" id="ads-position" name="ads-position" className={`form-control ${detailsAdsOrder ? 'filled' : ''}`}placeholder="Enter ads position"  value={detailsAdsOrder} onChange={(e) => setDetailsAdsOrder(e.target.value)} />
                            <label htmlFor="ads-size">Ads Size*</label>
                            <select
                                    id="menu_action_element"
                                    className={`form-control ${detailsAdsSize ? 'filled' : ''}`}
                                    value={detailsAdsSize}
                                    onChange={(e) => setDetailsAdSize(e.target.value)}
                                >
                                    <option value="">--Select an Ads Size--</option>
                                    {adsSizeMap.map((cat) => (
                                        <option key={cat.id} value={cat.id}>
                                            {cat.value}
                                        </option>
                                    ))}
                                </select>
                            <div className="col-md-12 mt-3">
                                <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                                <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                            </div>
                        </form>
                    </div>

                    <div id="PhotoGallery" className={`tab-content ${activeTab === 'PhotoGallery' ? 'active' : ''}`}>
                    <form role="form" onSubmit={(event) => handleSubmit(event, 'gallery_ads')} id="article_ads_form">
                            <label htmlFor="ads-code">Ads Code*</label>
                            <input type="text" id="ads-code" name="ads-code" className={`form-control ${galleryAdsCode ? 'filled' : ''}`}placeholder="Enter ads code"  value={galleryAdsCode} onChange={(e) => setGalleryAdsCode(e.target.value)} />
                            <label htmlFor="ads-position">Ads Position*</label>
                            <input type="text" id="ads-position" name="ads-position" className={`form-control ${galleryAdsOrder ? 'filled' : ''}`}placeholder="Enter ads position"  value={galleryAdsOrder} onChange={(e) => setGalleryAdsOrder(e.target.value)} />
                            <label htmlFor="ads-size">Ads Size*</label>
                            <select
                                            id="menu_action_element"
                                            className={`form-control ${galleryAdsSize ? 'filled' : ''}`}
                                            value={galleryAdsSize}
                                            onChange={(e) => setGalleryAdSize(e.target.value)}
                                        >
                                            <option value="">--Select an Ads Size--</option>
                                            {adsSizeMap.map((cat) => (
                                                <option key={cat.id} value={cat.id}>
                                                    {cat.value}
                                                </option>
                                            ))}
                                        </select>
                            <div className="col-md-12 mt-3">
                                <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                                <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                            </div>
                        </form>
                    </div>

                    <div id="Video" className={`tab-content ${activeTab === 'Video' ? 'active' : ''}`}>
                    <form role="form" onSubmit={(event) => handleSubmit(event, 'video_ads')} id="article_ads_form">
                            <label htmlFor="ads-code">Ads Code*</label>
                            <input type="text" id="ads-code" name="ads-code" 
                            className={`form-control ${videoAdsCode ? 'filled' : ''}`} placeholder="Enter ads code"  value={videoAdsCode} onChange={(e) => setVideoAdsCode(e.target.value)}/>
                            <label htmlFor="ads-position">Ads Position*</label>
                            <input type="text" id="ads-position" name="ads-position" 
                            className={`form-control ${videoAdsOrder ? 'filled' : ''}`}placeholder="Enter ads position"  value={videoAdsOrder} onChange={(e) => setVideoAdsOrder(e.target.value)} />
                            <label htmlFor="ads-size">Ads Size*</label>
                            <select
                                            id="menu_action_element"
                                            className={`form-control ${videoAdsSize ? 'filled' : ''}`}
                                            value={videoAdsSize}
                                            onChange={(e) => setVideoAdsSize(e.target.value)}
                                        >
                                            <option value="">--Select an Ads Size--</option>
                                            {adsSizeMap.map((cat) => (
                                                <option key={cat.id} value={cat.id}>
                                                    {cat.value}
                                                </option>
                                            ))}
                                        </select>
                            <div className="col-md-12 mt-3">
                                <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                                <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                            </div>
                        </form>
                    </div>
                    <div id="Live" className={`tab-content ${activeTab === 'Live' ? 'active' : ''}`}>
                    <form role="form" onSubmit={(event) => handleSubmit(event, 'live_ads')} id="live_ads_form">
                            <label htmlFor="live-ads-code">Ads Code*</label>
                            <input type="text" id="live-ads-code" name="live-ads-code" 
                            className={`form-control ${liveAdsCode ? 'filled' : ''}`}placeholder="Enter ads code"  value={liveAdsCode} onChange={(e) => setLiveAdsCode(e.target.value)}/>
                            <label htmlFor="live-ads-position">Duration for play Ads*</label>
                            <input type="text" id="ads-position" name="live-position" 
                            className={`form-control ${liveAdsDuration ? 'filled' : ''}`} placeholder="Enter duration"  value={liveAdsDuration} onChange={(e) => setLiveVideoDuration(e.target.value)} />
                            <label htmlFor="ads-size">Ads Position*</label>
                            <select
                                            id="menu_action_element"
                                            className={`form-control ${liveAdsPosition ? 'filled' : ''}`}
                                            value={liveAdsPosition}
                                            onChange={(e) => setLiveAdsPosition(e.target.value)}
                                        >
                                            <option value="">--Select an Ads Size--</option>
                                            {adsSizeLiveMap.map((cat) => (
                                                <option key={cat.id} value={cat.id}>
                                                    {cat.value}
                                                </option>
                                            ))}
                                        </select>
                            <div className="col-md-12 mt-3">
                                <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                                <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        <ToastContainer />
        </section>
    );
}

export default AdsForm;
