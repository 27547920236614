import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton, Pagination, Modal, InputLabel, FormControl
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from '../../UserContext';
import { Edit, Delete, Save, Add, Cancel } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import * as IoIcons from "react-icons/io";
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const ViewStateList = () => {
    const { selectedLanguage } = useUser();
    const [contents, setContents] = useState([]);
    const [imagePreviewLight, setImagePreviewLight] = useState(null);
    const [imagePreviewDark, setImagePreviewDark] = useState(null);
    const [icon, setIcon] = useState('');
    const [darkIcon, setDarkIcon] = useState('');
    const [filters, setFilters] = useState({
        category: '',
        lang_id: selectedLanguage?.id,
        contentType: '',
        status: false,
        fromDate: '',
        toDate: ''
    });
    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const contentTypeMap = {
        "0": "Text",
        "1": "Photo-Gallery",
        "2": "Video",
        "3": "Podcast",
        '4': "Webstory",
        '5': "Shorts",
        '6': "Live-Blog"
    };

    const articleTypeMap = {
        '0': "Unknown",
        '1': "Article Short",
        '2': "Video Short",
        '3': "Podcast Short",
        '4': "JW Video Short"
    };
    const premiumContentTypeMap = {
        0: "Non Premium Content",
        1: "Premium Content"
    }
    const [editRowId, setEditRowId] = useState(null);
    const [editedContent, setEditedContent] = useState({
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    useEffect(() => {
        if (selectedLanguage?.id) {
            setFilters(prevFilters => ({
                ...prevFilters,
                lang_id: selectedLanguage.id
            }));
        }
    }, [selectedLanguage]);

    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);
    const fetchData = async () => {
        try {
            const response = await apiGetCall(`/master/state_list?page=${currentPage}&pageSize=20`,filters)
            if (Array.isArray(response.data.data)) {
                    setContents(response.data.data);
                    setTotalPages(response.data.totalPages);
                } else {
                    console.error('Fetched data is not an array:', response.data);
                }
        } catch (error) {
            
        }
    };
    const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
    ]);
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleEditClick = (id) => {
        setEditRowId(id);
        const content = contents.find(content => content.id === id);
        console.log('---------80', content)
        setEditedContent({ ...content });
        setModalOpen(true);
        setImagePreviewLight(content.state_logo)
        setImagePreviewDark(content.dark_state_logo)
        console.log(editedContent, '-------------------103')
    };

    const handleCancelClick = () => {
        setEditRowId(null);
        setEditedContent({});
        setModalOpen(false);
    };

    const handleSaveClick = async(e) => {
        e.preventDefault()
        let formData = new FormData();
        Object.keys(editedContent).forEach(key => {
            try {
                const value = editedContent[key];
                formData.append(key, value);
            } catch (error) {
                console.log('Error appending key to formData:', error);
            }
        });

        if (icon) {
            try {
                formData.append('state_logo', icon);
                console.log('Icon appended:', icon);
            } catch (error) {
                console.log('Error appending icon:', error);
            }
        }
        if (darkIcon) {
            try {
                formData.append('dark_state_logo', darkIcon);
                console.log('Dark icon appended:', darkIcon);
            } catch (error) {
                console.log('Error appending dark icon:', error);
            }
        }
        console.log(formData, '------------------134')
        try {
            const response = await apiCallForm(`/master/update_state`,formData,'PUT')
            if (response && response.data.status) {
                            setEditRowId(null);
                            setEditedContent({});
                            toast.success('Data updated successfully');
                            fetchData()
                            setModalOpen(false);
                            console.log('Modal should close now');
                        } else {
                            console.error('Error updating data:', data.message);
                            toast.error('Failed to update data');
                        }
        } catch (error) {
            toast.error('Failed to update data');
        }
    };
    const handleImageChange = (e, type) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            if (type === "light") {
                setImagePreviewLight(reader.result);
                setIcon(file)
            } else if (type === "dark") {
                setImagePreviewDark(reader.result);
                setDarkIcon(file)
            }
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleStatusChangeClick = (id, status) => {
        apiCallForm(`/master/update_app_label/${id}/${status}`,{},
             'PUT')
        .then(response => {
            if (!response.ok) {
                return response.json().then(err => {alert(err.message || 'Something went wrong');});
            }
            return response.json();
        })
        .then(data => {
            if (data.status) {
                toast.success(data.message);
    
                  if (langId) {
                    axios.get(`${process.env.REACT_APP_BASE_URL}/master/app_label_list`, { params: filters })
                      .then(response => {
                        setAppLabelFields(response?.data?.data);
                      })
                      .catch(error => {
                        console.error('Error fetching additional fields:', error);
                      });
                  }
    
            } else {
                console.error('Error updating data:', data.message);
                toast.error('Failed to update data');
            }
        })
        .catch(error => {
            console.error('Error updating data:', error);
            toast.error('Failed to update data');
        });
    };
    

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedContent({
            ...editedContent,
            [name]: value
        });
    };
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
            <div className="header-container">
                <header>
                    <h1>State List</h1>
                </header>
                <div className="add-author-button">
                    <Link className="text-white" to="/master/addstate">Add State</Link>
                </div>
            </div>

            <div style={{ width: '100%', overflowX: 'auto' }}>
                <TableContainer component={Paper} style={{ width: '100%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>State</TableCell>
                                <TableCell>Icon Light</TableCell>
                                <TableCell>Icon Dark</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Created On</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={filters.status}
                                        onChange={handleFilterChange}
                                        displayEmpty
                                        name="status"
                                        size="small"
                                        variant="outlined"
                                    >
                                        {statusOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contents.map((content) => (
                                <TableRow key={content.id}>
                                    <TableCell>{content.id}</TableCell>
                                    {/* <TableCell>{content.cms_id}</TableCell> */}
                                    <TableCell>{content.state_name}</TableCell>
                                    <TableCell>{content.state_logo && (
                                        <img src={content.state_logo} alt="State Logo" style={{ width: '50px', height: '50px' }} />
                                    )}</TableCell>
                                    <TableCell>{content.dark_state_logo && (
                                        <img src={content.dark_state_logo} alt="State Logo" style={{ width: '50px', height: '50px' }} />
                                    )}</TableCell>
                                    {/* <TableCell>{content.designation}</TableCell> */}
                                    <TableCell>{content.state_status === 1 ? "Enabled" : "Disabled"}</TableCell>
                                    <TableCell>{new Date(content.created * 1000).toLocaleString()}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                                            <Edit />
                                        </IconButton>

                                        <IconButton onClick={() => handleStatusChangeClick(content.id, content.state_status ? 0 : 1)} aria-label="toggle-status">
                                            <IoIcons.IoIosRemoveCircleOutline style={{ color: content.state_status === 1 ? 'green' : 'red' }} />
                                        </IconButton>

                                        {/* <IconButton onClick={() => handleDeleteClick(content.id)} aria-label="delete">
                                            <Delete />
                                        </IconButton> */}
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '50%' }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                    <Button
                        variant="outlined"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>
                <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="edit-content-modal"
                    aria-describedby="modal to edit content"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <section className="panel edit_section col-6">
                        <header className="panel-heading">
                            Edit State
                        </header>
                        <div className="panel-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            <form role="form" onSubmit={handleSaveClick} encType="multipart/form-data" id="edit_language_form">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="state_name">State Name<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editedContent.state_name ? 'filled' : ''}`}
                                            id="state_name"
                                            name="state_name"
                                            placeholder="State Name"
                                            value={editedContent.state_name}
                                            onChange={(e) => handleEditChange({ target: { name: "state_name", value: e.target.value } })}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="lang_status">State Status</label>
                                        <select
                                            value={editedContent.state_status}
                                            onChange={(e) => handleEditChange({ target: { name: "state_status", value: e.target.value } })}
                                            className={`form-control ${editedContent.state_status ? 'filled' : ''}`}
                                            name="state_status"
                                        >
                                           {statusOptions.filter(option => option.value !== false).map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="state-image-light">State Image Light (Image Ratio 1:1)</label>
                                        <input
                                            id="state-image-light"
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => handleImageChange(e, "light")}
                                        />
                                        {imagePreviewLight && (
                                            <img src={imagePreviewLight} alt="Language Light" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                                        )}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="state-image-dark">State Image Dark (Image Ratio 1:1)</label>
                                        <input
                                            id="state-image-dark"
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => handleImageChange(e, "dark")}
                                        />
                                        {imagePreviewDark && (
                                            <img src={imagePreviewDark} alt="Language Dark" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                                        )}
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>
                                                Save
                                            </button>
                                            <button type="button" className="btn btn-secondary" onClick={handleCancelClick} style={{ padding: "15px", lineHeight: "1.5" }}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <ToastContainer />
                        </div>
                    </section>

                </Modal>
            </div>
        </>
    );
};
export default ViewStateList;
