import React, { createContext, useContext, useState, useReducer, useEffect } from 'react';
import { initialState, rootReducer } from "./UserStates/Reducer";
import {apiCall,apiCallForm,apiGetCall} from '../src/configs/apiMethodCall'
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const [user, setUser] = useState(null);
  const [cmsToken,setCmsToken] = useState('')
  const [perm,setPerm] = useState('')
  const [languages, setLanguages] = useState([{
      "id": 1,
      "lang_name": "हिन्दी"
  }]);
  const [selectedLanguage, setSelectedLanguage] = useState({
      "id": 1,
      "lang_name": "हिन्दी"
  });

  // useEffect(() => {
  //   localStorage.setItem('isLoggedIn', state.isLoggedIn);
  // }, [state.isLoggedIn]);


  useEffect(() => {
    if (state.userData && state.userData.data) {
      localStorage.setItem('isLoggedIn', state.isLoggedIn);
      localStorage.setItem('isLoggedInUsername', state.userData.data.username);
  
      const fetchUserLoggedInData = async () => {
        try {
          const intUserId = state.userData.data.id;
          console.log(state.userData.data.id);
  
          // API call passing id as a query parameter
          const response = await apiGetCall(`/userauth/userdetails?id=${intUserId}`);
          const userData = response.data.data;
  
          setUser({
            id: userData.id,
            lang_id: userData.lang_id,
            type: userData.username,
          });

        } catch (error) {
          console.error('Error fetching languages:', error);
        }
      };
  
      fetchUserLoggedInData();
    }
  }, [state.isLoggedIn, state.userData]);
  


  useEffect(() => {
    const username = localStorage.getItem('username');
    const cmstoken = localStorage.getItem('cmsToken');
    const perm = localStorage.getItem('perm_id');
    //console.log(cmstoken,'------2222222222-----',perm)
    if (username || cmsToken || perm) {
      setUser({ type: username });
      setCmsToken(cmstoken)
      setPerm(perm)
    } else {
      setUser(null);
      localStorage.removeItem('cmsToken');
      localStorage.removeItem('perm');
    }
  }, []);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await apiGetCall(`/config/master_management`,{});
        setLanguages(response.data.data.map(lang => ({
          id: lang.id,
          lang_name: lang.lang_name
        })));
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };

    fetchLanguages();
  }, []);

  return (
    <UserContext.Provider value={{ state, dispatch, user, setUser, languages, selectedLanguage, setSelectedLanguage,cmsToken,perm }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);