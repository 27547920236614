import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
import Select from 'react-select'
function AddContentAccessForm(props) {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [accessTitle, setAccessTitle] = useState("");
    const [contentQuality,setContentQuality] = useState("")
    const [errors, setErrors] = useState({});
    const [contentType, setContentType] = useState([]);
    const [contentResolution, setContentResolution] = useState('');
    const [premiumContent, setPremiumContent] = useState('');

    const handleReset = (e) => {
        e.preventDefault()
        navigate('/subcription/cam');
    };
    const validateForm = (formType) => {
        let formErrors = {};
        return formErrors;
    };
    // const contentTypeMap = {
    //     "0": "Article",
    //     "2": "Video",
    //     "3": "Podcast"
    //   };
      const contentTypeMap = [
        { id: 0, label: "Article" },
        { id: 1, label: "Photo-Gallery" },
        { id: 2, label: "Video" },
        { id: 3, label: "Podcast" },
        { id: 4, label: "Web-Story" },
        { id: 5, label: "Short" },
        { id: 6, label: "Liveblog" },
        { id: 7, label: "Live" },
        { id: 51, label: "Article Short" },
        { id: 52, label: "Video Short" },
        { id: 53, label: "Podcast Short" }
      ];
      const contentResolutionMap = {
        "140": "140p",
        "240": "240p",
        "360": "360p",
        "480":"480p",
        "720":"720p"
      };
      const premiumContentTypeMap = {
        0:"Non Premium Content",
        1:"Premium Content"
  }
  const handleContentTypeChange = (selectedOptions) => {
    setContentType(selectedOptions ? selectedOptions.map(option => option.value) : []);
    console.log(contentType,'---------------43')
};

const options = contentTypeMap.map(type => ({ value: type.id, label: type.label }));

    const handleContentResolutionChange = (event) => {
        setContentResolution(event.target.value)
    };
    const handlePremiumContentChange = (event) =>{
        setPremiumContent(event.target.value)
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (
            !accessTitle ||
            !contentType ||
            !contentQuality ||
            !contentResolution ||
            !premiumContent
        ) {
            toast.error("All fields are required.");
            return false;
        }
        if (isSubmitting) return;
        setIsSubmitting(true); 
        try {
            let payload = {
                access_title:accessTitle,
                content_type:contentType,
                content_quality:contentQuality,
                content_resolution:contentResolution,
                premium_content:premiumContent,
                content_access_status:1

            }

            // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/plan/create_content_access`, payload, {
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // });
            const response = await apiCall('/plan/create_content_access',payload,'POST')
            if (response.data.status) {
                console.log('Data saved successfully');
                toast.success("Data saved successfully", {
                });
            } else {
                console.error('Error while saving data:', "Error while saving data:");
                toast.error("Error while saving data:", {
                });
            }
        } catch (error) {
            toast.error("Error while saving data:", {})
        } finally{
            setIsSubmitting(false)
                navigate('/subcription/cam')
        }
     
    };
    return (
        <section className="panel add_section">
            <div className="panel-body">
                <form role="form" onSubmit={(event) => handleSubmit(event, 'widgets_ads')} id="widgets_form">
                    <div className="row mt-3">
                        <section className="panel">
                            <header className="panel-heading" style={{ fontWeight: "bold" }}>Add Content Access</header>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor="acess_title"> Access Title<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className={`form-control ${accessTitle ? 'filled' : ''}`} id="acess_title" name="widget_title" placeholder="Access Title" value={accessTitle} onChange={(e) => setAccessTitle(e.target.value)} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="content_type"> Content type<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <Select
                                    id="content_type"
                                    className={`form-control ${contentType ? 'filled' : ''}`}
                                    value={options.filter(option => contentType.includes(option.value))}
                                    onChange={handleContentTypeChange}
                                    options={options}
                                    isMulti
                                />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                        <label htmlFor="content_quality"> Content Quality<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                        <input type="text" className={`form-control ${contentQuality ? 'filled' : ''}`} id="content_quality" name="content_quality" value={contentQuality} onChange={(e) => setContentQuality(e.target.value)} />
                                    </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="content_resolution*"> Content Resolution<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <select id="Content Resolution*" className={`form-control ${contentResolution ? 'filled' : ''}`} value={contentResolution} onChange={handleContentResolutionChange}>
                                    {Object.keys(contentResolutionMap).map((key) => (
                                    <option key={key} value={key}>
                                        {contentResolutionMap[key]}
                                    </option>
                                ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                            <div className="form-group col-md-6">
                                    <label htmlFor="premium_content">Premium Content<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <select id="premium_content" className={`form-control ${premiumContent ? 'filled' : ''}`} value={premiumContent} onChange={handlePremiumContentChange}>
                                    {Object.keys(premiumContentTypeMap).map((key) => (
                                    <option key={key} value={key}>
                                        {premiumContentTypeMap[key]}
                                    </option>
                                ))}
                                </select>
                                </div>
                            </div>
                        </section>
                        <div className="col-md-12 mt-3">
                            <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </section>
    );
}

export default AddContentAccessForm;
