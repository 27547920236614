import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton,Pagination,Modal,InputLabel, FormControl
} from '@mui/material';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
import { Edit, Delete, Save, Cancel } from '@mui/icons-material';

const ViewTop9List = () => {
    const [contents, setContents] = useState([]);
    const [filters, setFilters] = useState({
        category: '',
        contentType: '',
        status: '',
        fromDate: '',
        toDate: ''
    });
    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const contentTypeMap = {
        "0": "Text",
        "1": "Photo-Gallery",
        "2": "Video",
        "3": "Podcast",
        '4': "Webstory",
        '5': "Shorts",
        '6': "Live-Blog"
      };
      
      const articleTypeMap = {
        '0':"Unknown",
        '1': "Article Short",
        '2': "Video Short",
        '3': "Podcast Short",
        '4': "JW Video Short"
      };
      const premiumContentTypeMap = {
            0:"Non Premium Content",
            1:"Premium Content"
      }
    const [editRowId, setEditRowId] = useState(null);
    const [editedContent, setEditedContent] = useState({
        "id": 1,
        "lang_id": 2,
        "cms_id": 10975,
        "article_title": "Joe Biden may extend May 1 deadline for troop withdrawal from Afghanistan",
        "article_type_id": articleTypeMap["0"],
        "content_type_id": contentTypeMap["1"],
        "premium_content": 0,
        "article_status": 1,
        "created_on": "2021-04-08T12:05:58.000Z",
        "updated_on": "2021-04-08T12:05:58.000Z",
        "category": "India",
        "cat_id": 170,
        "sub_cat_id": 0,
        "category_title": "India",
        "sub_title": null
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);
      const fetchData = async () => {
        try {
            const response = await apiGetCall(`/content/contentlist?page=${currentPage}&pageSize=20&top_9=1`,filters)
            if (Array.isArray(response.data.data)) {
                setContents(response.data.data);
                setTotalPages(response.data.totalPages);
            } else {
                console.error('Fetched data is not an array:', response.data);
            }
        } catch (error) {
            
        }
    };
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };
 const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleEditClick = (id) => {
        setEditRowId(id);
        const content = contents.find(content => content.id === id);
        console.log('---------80',content)
        // content.content_type_id = contentTypeMap[content.content_type_id]
        // content.article_type_id = articleTypeMap[content.article_type_id]
        setEditedContent({ ...content });
        setModalOpen(true);
        console.log(editedContent,'-------------------103')
    };

    const handleCancelClick = () => {
        setEditRowId(null);
        setEditedContent({});
        setModalOpen(false);
    };

    const handleSaveClick = () => {
        // Update data via API
        fetch(`https://your-api-endpoint.com/contents/${editedContent.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(editedContent)
        })
        .then(response => response.json())
        .then(data => {
            const updatedContents = contents.map(content =>
                content.id === editedContent.id ? editedContent : content
            );
            setContents(updatedContents);
            setEditRowId(null);
            setEditedContent({});
        })
        .catch(error => console.error('Error updating data:', error));
    };

    const handleDeleteClick = (id) => {
        // Delete data via API
        fetch(`https://your-api-endpoint.com/contents/${id}`, {
            method: 'DELETE'
        })
        .then(() => {
            const updatedContents = contents.filter(content => content.id !== id);
            setContents(updatedContents);
        })
        .catch(error => console.error('Error deleting data:', error));
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedContent({
            ...editedContent,
            [name]: value
        });
    };
    const [premiumContentOptions, setpremiumContentOptions] = useState([
        { value: false, label: 'All' },
        { value: 0, label: 'Non Premium Content' },
        { value: 1, label: 'Premium Content' }
      ]);
      const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
      ]);
 const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    const filteredContents = contents.filter(content => {
        const createdOnDate = new Date(content.createdOn);
        const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
        const toDate = filters.toDate ? new Date(filters.toDate) : null;

        return (
            (filters.category ? content.category === filters.category : true) &&
            (filters.contentType ? content.contentType === filters.contentType : true) &&
            (filters.status ? content.status === filters.status : true) &&
            (fromDate ? createdOnDate >= fromDate : true) &&
            (toDate ? createdOnDate <= toDate : true)
        );
    });

    return (
        <div style={{ width: '100%', overflowX: 'auto' }}>
  <TableContainer component={Paper} style={{ width: '100%' }}>
    <Table>
        <TableHead>
            <TableRow>
                {/* <TableCell>Id</TableCell> */}
                <TableCell>CMS Id</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Gallery Title</TableCell>
                <TableCell>Premium Content</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
            <TableRow>
                {/* <TableCell>
                </TableCell> */}
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                    <Select
                        value={filters.premiumContent}
                        onChange={handleFilterChange}
                        displayEmpty
                        name="premiumContent"
                        size="small"
                        variant="outlined"
                    >
                        {premiumContentOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell>
                    <Select
                        value={filters.status}
                        onChange={handleFilterChange}
                        displayEmpty
                        name="status"
                        size="small"
                        variant="outlined"
                    >
                        {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell>
                    <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        name="fromDate"
                        value={filters.fromDate}
                        onChange={handleFilterChange}
                    />
                    <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        name="toDate"
                        value={filters.toDate}
                        onChange={handleFilterChange}
                    />
                </TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {contents.map((content) => (
                <TableRow key={content.id}>
                    {/* <TableCell>{content.id}</TableCell> */}
                    <TableCell>{content.cms_id}</TableCell>
                    <TableCell>{content.category}</TableCell>
                    <TableCell>{content.article_title}</TableCell>
                    <TableCell>{premiumContentTypeMap[content.premium_content]}</TableCell>
                    <TableCell>{content.article_status === 1 ? "Enabled" : "Disabled"}</TableCell>
                    <TableCell>{new Date(content.created_on).toLocaleString()}</TableCell>
                    <TableCell>
                        <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                            <Edit />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(content.id)} aria-label="delete">
                            <Delete />
                        </IconButton>
                    </TableCell>

                </TableRow>
            ))}
        </TableBody>
    </Table>
</TableContainer>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
                    <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div>
            <Modal
    open={modalOpen}
    onClose={handleCloseModal}
    aria-labelledby="edit-content-modal"
    aria-describedby="modal to edit content"
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
>
    <div style={{ backgroundColor: 'white', boxShadow: 24, padding: 20, borderRadius: 8 }}>
        <FormControl fullWidth margin="normal">
            {/* <InputLabel htmlFor="content-title">Content Title</InputLabel> */}
            <TextField
                id="content-title"
                label="Content Title"
                variant="outlined"
                value={editedContent.article_title}
                onChange={(e) => handleEditChange({ target: { name: "content_title", value: e.target.value } })}
                fullWidth
            />
        </FormControl>
        <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="premium-content">Premium Content</InputLabel>
            <Select
                id="premium-content"
                value={editedContent.premium_content}
                onChange={(e) => handleEditChange({ target: { name: "premium_content", value: e.target.value } })}
                displayEmpty
                fullWidth
                variant="outlined"
            >
                <MenuItem value="" disabled>
                    Select Premium Content
                </MenuItem>
                {Object.entries(premiumContentTypeMap).map(([key, value]) => (
                    <MenuItem key={key} value={key}>{value}</MenuItem>
                ))}
            </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="status">Status</InputLabel>
            <Select
                id="status"
                value={editedContent.article_status === 1 ? "Enabled" : "Disabled"}
                onChange={(e) => handleEditChange({ target: { name: "status", value: e.target.value } })}
                displayEmpty
                fullWidth
                variant="outlined"
            >
                <MenuItem value="" disabled>
                    Select Status
                </MenuItem>
                <MenuItem value="Enabled">Enabled</MenuItem>
                <MenuItem value="Disabled">Disabled</MenuItem>
            </Select>
        </FormControl>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
            <Button variant="contained" color="primary" onClick={handleSaveClick}>
                Save
            </Button>
            <Button variant="contained" color="secondary" onClick={handleCancelClick} style={{ marginLeft: 10 }}>
                Cancel
            </Button>
        </div>
    </div>
</Modal>

            </div>
);
};

export default ViewTop9List;
