import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../src/UserContext';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
import { ToastContainer, toast } from 'react-toastify';
function AuthorsForm(props) {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [authorName, setAuthorName] = useState("");
    const [email, setEmail] = useState("");
    const { selectedLanguage } = useUser();
    const [designation, setDesignation] = useState("");
    const [authorTwitter, setAuthorTwitter] = useState("");
    const [authorLinkedin, setAuthorLinkedin] = useState("");
    const [authorYoutube, setAuthorYoutube] = useState("");
    const [authorInstagram, setAuthorInstagram] = useState("");
    const [authorFacebook, setAuthorFacebook] = useState("");
    const [description, setDescription] = useState("")
    const [authorLogo, setAuthorLogo] = useState(null);
    const [imagePreview, setImagePreview] = useState('');

    const handleReset = () => {
        setAuthorName("");
        setEmail("");
        setDesignation("");
        setAuthorTwitter("");
        setAuthorLinkedin("");
        setAuthorYoutube("");
        setAuthorInstagram("");
        setAuthorLogo(null)
        window.location.href = '/author/author_management';
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (!selectedLanguage) {
                toast.error("Please select a language first");
                return;
            }
            if (isSubmitting) return;
            setIsSubmitting(true);
            const formData = new FormData();
            formData.append('author_name', authorName);
            formData.append('author_logo', authorLogo);
            formData.append('author_email', email);
            formData.append('lang_id', selectedLanguage?.id);
            formData.append('designation', designation);
            formData.append('description', description);
            formData.append('linkedIn', authorLinkedin);
            formData.append('twitter', authorTwitter);
            formData.append('instagram', authorInstagram);
            formData.append('youtube', authorYoutube);
            formData.append('facebook', authorFacebook);
            
            try {
                // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/author/create_author`, formData, {
                //     headers: {
                //         'Content-Type': 'multipart/form-data'
                //     }
                // });
                const response = await apiCallForm('/author/create_author',formData,'POST')
                if (response.data.status) {
                    console.log('Data saved successfully');
                    toast.success("Data saved successfully");
                    navigate('/author/author_management');
                } else {
                    console.error('Error while saving data:', response.data.message || "Unknown error");
                    toast.error(`Error while saving data:', ${response.data.message}`);
                    // navigate('/author/author_management');
                }
            } catch (error) {
                if (error.response) {
                    console.error('Error response:--76', error.response);
                    toast.error(error.response?.data?.error);
                } else if (error.request) {
                    console.error('Error request:', error.request);
                    toast.error("No response from server. Please check your network connection.");
                } else {
                    toast.error("Please select a language first");
                }
                // navigate('/author/author_management');
            }
        } catch (error) {
            console.error('Unexpected error:', error);
            toast.error("An unexpected error occurred. Please try again later.");
            navigate('/author/author_management');
        }finally{
            setIsSubmitting(false);
        }
    };
    const handleImageUpload = (event, setImage) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImagePreview(reader.result);
        };
        setAuthorLogo(file)
    
    };

    return (
        <section className="panel add_section">
            <header className="panel-heading" >
                Add Author
            </header>
            <div className="panel-body">
                <form role="form" onSubmit={handleSubmit} encType="multipart/form-data" id="language_form">
                 
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="author_name">Author Name<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="text" className={`form-control ${authorName ? 'filled' : ''}`} id="author_name" name="author_name" placeholder="Enter Name" value={authorName} onChange={(e) => setAuthorName(e.target.value)} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="author_email">Email<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="email" className={`form-control ${email ? 'filled' : ''}`} id="email" name="email_author" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="author_designation">Designation<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="text" className={`form-control ${designation ? 'filled' : ''}`} id="author_designation" name="author_designation" placeholder="Enter Designation" value={designation} onChange={(e) => setDesignation(e.target.value)} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="author_logo">Author Logo<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="file" className={`form-control ${authorLogo ? 'filled' : ''}`} id="author_logo" name="author_logo" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, setAuthorLogo)} />
                            {imagePreview && (
                        <img src={imagePreview} alt="Layout Image" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                        )}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="author_twitter">Author's twitter<span style={{color: "#ff0000"}}></span></label>
                            <input type="url" className={`form-control ${authorTwitter ? 'filled' : ''}`} id="author_twitter" name="author_twitter" placeholder="Enter Twitter account Url" value={authorTwitter} onChange={(e) => setAuthorTwitter(e.target.value)} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="author_linkedIn">Author's linkedIn<span style={{color: "#ff0000"}}></span></label>
                            <input type="url" className={`form-control ${authorLinkedin ? 'filled' : ''}`} id="author_linkedIn" name="author_linkedIn" placeholder="Enter linkedIn url" value={authorLinkedin} onChange={(e) => setAuthorLinkedin(e.target.value)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                    <div className="form-group col-md-4">
                            <label htmlFor="author_youtube">Author's youtube<span style={{color: "#ff0000"}}></span></label>
                            <input type="url" className={`form-control ${authorYoutube ? 'filled' : ''}`} id="author_youtube" name="author_youtube" placeholder="Enter youtube account url" value={authorYoutube} onChange={(e) => setAuthorYoutube(e.target.value)} />
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="author_insta">Author's instagram<span style={{color: "#ff0000"}}></span></label>
                            <input type="url" className={`form-control ${authorInstagram ? 'filled' : ''}`} id="author_insta" name="author_insta" placeholder="Enter instagram account url" value={authorInstagram} onChange={(e) => setAuthorInstagram(e.target.value)} />
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="author_insta">Author's facebook<span style={{color: "#ff0000"}}></span></label>
                            <input type="url" className={`form-control ${authorFacebook ? 'filled' : ''}`} id="author_facebook" name="author_facebook" placeholder="Enter instagram account url" value={authorFacebook} onChange={(e) => setAuthorFacebook(e.target.value)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                    <div className="rich-text-editor-container">
                        <h2>Description</h2>
                        <CKEditor
                            editor={ClassicEditor}
                            data={description}
                            onChange={(event, editor) => {
                            const data = editor.getData();
                            setDescription(data);
                            }}
                        />
                        <div className="editor-preview">
                            <h3>Preview:</h3>
                            <div dangerouslySetInnerHTML={{ __html: description }} />
                        </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </section>
    );
}

export default AuthorsForm;
