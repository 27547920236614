import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton, Pagination, Modal, InputLabel, FormControl
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Edit, Delete, Save, Add, Cancel } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'

const ViewSubscriptionPlanList = () => {
    const navigate = useNavigate();
    const [contents, setContents] = useState([]);
    const [filters, setFilters] = useState({
        status: '',
    });
    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const planTypeMap = {
        "0": "Weekly",
        "1": "Monthly",
        "2": "Quarterly",
        "3": "Half Yearly",
        '4': "Annual",
        '5': "Life Time",
    };
    const durationMap = {
        "7": "7 Days",
        "30": "30 Days",
        "90": "90 Days",
        '365': "365 Days",
        "0": "Perpetuity",
    };

    const geoLocationMap = {
        "1": "India",
        "2": "Outside India",
    }
    const contentAccessMap = {
        '1': "Article",
        '2': "Article + Video"
    };
    const AdsTypeMap = {
        '1': "All",
        '2': "Only static type ads",
        '3': 'None'
    };
    const gstMap = {
        '1': 'Include',
        '2': 'Exclude'
    }
    const upgradeMap = {
        '1': 'Yes',
        '2': 'No'
    }
    const premiumContentTypeMap = {
        0: "Non Premium Content",
        1: "Premium Content"
    }
    const [editRowId, setEditRowId] = useState(null);
    const [editedContent, setEditedContent] = useState({
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);
    const fetchData = async() => {
        try {
            const response = await apiGetCall(`/plan/plan_list?page=${currentPage}&pageSize=20`,filters)
            if (Array.isArray(response.data.data)) {
                    setContents(response.data.data);
                    setTotalPages(response.data.totalPages);
                } else {
                    console.error('Fetched data is not an array:', response.data);
                }
        } catch (error) {
            
        }
    };
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleEditClick = (id) => {
        setEditRowId(id);
        const content = contents.find(content => content.id === id);
        console.log('---------80', content)
        setEditedContent({ ...content });
        setModalOpen(true);
        console.log(editedContent, '-------------------103')
    };

    const handleCancelClick = () => {
        setEditRowId(null);
        setEditedContent({});
        setModalOpen(false);
    };

    const handleSaveClick = async (e) => {
        e.preventDefault();
        try {
            const response = await apiCall(`/plan/update_plan`, JSON.stringify(editedContent),'PUT')
            if (response && response.data.status) {
                            setEditRowId(null);
                            setEditedContent({});
                            toast.success('Data updated successfully');
                            fetchData()
                            setModalOpen(false);
                            console.log('Modal should close now');
                        } else {
                            console.error('Error updating data:', data.message);
                            toast.error('Failed to update data');
                        }
        } catch (error) {
            toast.error('Failed to update data');
        }finally{
            navigate('/subcription/plans');
        }
    };

    const handleDeleteClick = (id) => {
        // Delete data via API
        fetch(`https://your-api-endpoint.com/contents/${id}`, {
            method: 'DELETE'
        })
            .then(() => {
                const updatedContents = contents.filter(content => content.id !== id);
                setContents(updatedContents);
            })
            .catch(error => console.error('Error deleting data:', error));
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedContent({
            ...editedContent,
            [name]: value
        });
    };
    const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
    ]);
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
            <div className="header-container">
                <header>
                    <h1>Plan List</h1>
                </header>
                <div className="add-author-button">
                    <Link className="text-white" to="/add_subcription/plan">Add Plan</Link>
                    <Link className="text-white" to="/add_subcription/plan_title">Add Plan Title</Link>
                </div>
            </div>

            <div style={{ width: '100%', overflowX: 'auto' }}>
                <TableContainer component={Paper} style={{ width: '100%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Id</TableCell> */}
                                <TableCell>Plan Type</TableCell>
                                <TableCell>Duration</TableCell>
                                <TableCell>Strike-Off Price</TableCell>
                                <TableCell>Actual Price</TableCell>
                                <TableCell>Number of Devices</TableCell>
                                <TableCell>Content Type</TableCell>
                                <TableCell>Geo Location</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Created On</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                            <TableRow>
                                {/* <TableCell>
                </TableCell> */}
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={filters.status}
                                        onChange={handleFilterChange}
                                        displayEmpty
                                        name="status"
                                        size="small"
                                        variant="outlined"
                                    >
                                        {statusOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contents.length > 0 ? (
                                contents.map((content) => (
                                    <TableRow key={content.id}>
                                        {/* <TableCell>{content.id}</TableCell> */}
                                        <TableCell>{planTypeMap[content.plan_type]}</TableCell>
                                        <TableCell>{content.duration !== 0 ? content.duration + " Days" : "0 Days"}</TableCell>
                                        <TableCell>{content.strike_off_amount}</TableCell>
                                        <TableCell>{content.plan_amount}</TableCell>
                                        <TableCell>{content.no_of_devices}</TableCell>
                                        <TableCell>{content.content_type === 1 ? "Article" : "Article + Video"}</TableCell>
                                        <TableCell>{content.geo_location === 1 ? "India" : "Outside India"}</TableCell>
                                        <TableCell>{content.plan_status === 1 ? "Enabled" : "Disabled"}</TableCell>
                                        <TableCell>{new Date(content.created_on * 1000).toLocaleString()}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => handleDeleteClick(content.id)} aria-label="delete">
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={10} align="center">No data available</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                    <Button
                        variant="outlined"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>
                <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="edit-content-modal"
                    aria-describedby="modal to edit content"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <section className="panel edit_section col-6">
                        <header className="panel-heading" >
                            Edit Plan
                        </header>
                        <div className="panel-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            <form role="form" onSubmit={handleSaveClick} encType="multipart/form-data" id="edit_language_form">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="plan_type">Plan Type*</label>
                                        <select
                                            value={editedContent.plan_type}
                                            onChange={(e) => handleEditChange({ target: { name: "plan_type", value: e.target.value } })}
                                            className={`form-control ${editedContent.plan_type ? 'filled' : ''}`}
                                            name="plan_type"
                                        >
                                            {Object.keys(planTypeMap).map((key) => (
                                                // <MenuItem key={key} value={key}>
                                                //     {planTypeMap[key]}
                                                // </MenuItem>
                                                <option key={key} value={key}>
                                                    {planTypeMap[key]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="geo_location">Geo Location*</label>
                                        <select
                                            value={editedContent.geo_location}
                                            onChange={(e) => handleEditChange({ target: { name: "geo_location", value: e.target.value } })}
                                            className={`form-control ${editedContent.geo_location ? 'filled' : ''}`}
                                            name="geo_location"
                                        >
                                            {Object.keys(geoLocationMap).map((key) => (
                                                <option key={key} value={key}>
                                                    {geoLocationMap[key]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="strike_off_amount">Strike-Off Price*<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editedContent.strike_off_amount ? 'filled' : ''}`}
                                            id="strike_off_amount"
                                            name="strike_off_amount"
                                            placeholder="Strike-Off Price"
                                            value={editedContent.strike_off_amount}
                                            onChange={(e) => handleEditChange({ target: { name: "strike_off_amount", value: e.target.value } })}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="actual_price">Actual Price<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editedContent.plan_amount ? 'filled' : ''}`}
                                            id="actual_price"
                                            name="actual_price"
                                            placeholder="Actual Price"
                                            value={editedContent.plan_amount}
                                            onChange={(e) => handleEditChange({ target: { name: "plan_amount", value: e.target.value } })}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="no_of_devices">Number of Devices<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editedContent.no_of_devices ? 'filled' : ''}`}
                                            id="no_of_devices"
                                            name="no_of_devices"
                                            placeholder="Number of Devices"
                                            value={editedContent.no_of_devices}
                                            onChange={(e) => handleEditChange({ target: { name: "no_of_devices", value: e.target.value } })}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="gst_percentage">GST %<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editedContent.gst_percentage ? 'filled' : ''}`}
                                            id="gst_percentage"
                                            name="gst_percentage"
                                            placeholder="GST Percentage"
                                            value={editedContent.gst_percentage}
                                            onChange={(e) => handleEditChange({ target: { name: "gst_percentage", value: e.target.value } })}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="upgrade">Upgrade*</label>
                                        <select
                                            value={editedContent.upgrade}
                                            onChange={(e) => handleEditChange({ target: { name: "upgrade", value: e.target.value } })}
                                            className={`form-control ${editedContent.upgrade ? 'filled' : ''}`}
                                            name="upgrade"
                                        >
                                            {Object.keys(upgradeMap).map((key) => (
                                                // <MenuItem key={key} value={key}>
                                                //     {upgradeMap[key]}
                                                // </MenuItem>
                                                <option key={key} value={key}>
                                                    {upgradeMap[key]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="content_type">Content Access*</label>
                                        <select
                                            value={editedContent.content_type}
                                            onChange={(e) => handleEditChange({ target: { name: "content_type", value: e.target.value } })}
                                            className={`form-control ${editedContent.content_type ? 'filled' : ''}`}
                                            name="content_type"
                                        >
                                            {Object.keys(contentAccessMap).map((key) => (
                                                // <MenuItem key={key} value={key}>
                                                //     {contentAccessMap[key]}
                                                // </MenuItem>
                                                <option key={key} value={key}>
                                                    {contentAccessMap[key]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="gst_include">Is GST included*</label>
                                        <select
                                            value={editedContent.gst_include}
                                            onChange={(e) => handleEditChange({ target: { name: "gst_include", value: e.target.value } })}
                                            className={`form-control ${editedContent.gst_include ? 'filled' : ''}`}
                                            name="gst_include"
                                        >
                                            {Object.keys(gstMap).map((key) => (
                                                // <MenuItem key={key} value={key}>
                                                //     {gstMap[key]}
                                                // </MenuItem>
                                                <option key={key} value={key}>
                                                    {gstMap[key]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="ad_types_disabled">Ad Types Disabled*</label>
                                        <select
                                            value={editedContent.ad_types_disabled}
                                            onChange={(e) => handleEditChange({ target: { name: "ad_types_disabled", value: e.target.value } })}
                                            className={`form-control ${editedContent.ad_types_disabled ? 'filled' : ''}`}
                                            name="ad_types_disabled"
                                        >
                                            {Object.keys(AdsTypeMap).map((key) => (
                                                // <MenuItem key={key} value={key}>
                                                //     {AdsTypeMap[key]}
                                                // </MenuItem>
                                                <option key={key} value={key}>
                                                    {AdsTypeMap[key]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="android_product_key">Android Product Key<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editedContent.android_product_key ? 'filled' : ''}`}
                                            id="android_product_key"
                                            name="android_product_key"
                                            placeholder="Android Product Key"
                                            value={editedContent.android_product_key}
                                            onChange={(e) => handleEditChange({ target: { name: "android_product_key", value: e.target.value } })}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="ios_product_key">IOS Product Key<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editedContent.ios_product_key ? 'filled' : ''}`}
                                            id="ios_product_key"
                                            name="ios_product_key"
                                            placeholder="IOS Product Key"
                                            value={editedContent.ios_product_key}
                                            onChange={(e) => handleEditChange({ target: { name: "ios_product_key", value: e.target.value } })}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="duration">Duration*</label>
                                        <select
                                            value={editedContent.duration}
                                            onChange={(e) => handleEditChange({ target: { name: "duration", value: e.target.value } })}
                                            className={`form-control ${editedContent.duration ? 'filled' : ''}`}
                                            name="duration"
                                        >
                                            {Object.keys(durationMap).map((key) => (
                                                // <MenuItem key={key} value={key}>
                                                //     {durationMap[key]}
                                                // </MenuItem>
                                                <option key={key} value={key}>
                                                    {durationMap[key]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="planstatus">Plan Status</label>
                                        <select
                                            value={editedContent.plan_status}
                                            onChange={(e) => handleEditChange({ target: { name: "plan_status", value: e.target.value } })}
                                            className={`form-control ${editedContent.plan_status ? 'filled' : ''}`}
                                            name="plan_status"
                                        >
                                            {statusOptions.filter(option => option.value !== false).map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>
                                            Save
                                        </button>
                                        <button type="button" className="btn btn-secondary" onClick={handleCancelClick} style={{ padding: "15px", lineHeight: "1.5" }}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <ToastContainer />
                        </div>
                    </section>
                </Modal>

            </div>
        </>
    );
};
export default ViewSubscriptionPlanList;
