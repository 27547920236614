import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import {apiCall,apiCallForm} from '../../../src/configs/apiMethodCall'
import { useUser } from '../../../src/UserContext';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
function AddOrderIconForm(props) {
    const navigate = useNavigate();
    const { selectedLanguage } = useUser();
    const [orderIdx, setOrderIdx] = useState('');
    const [keyPosition, setKeyPosition] = useState("");
    const [orderIcon, setOrderIcon] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false)
    const keyPositionMap = {
        "1": "Top",
        "2": "Center",
        "3": "Bottom",
    };
    const handleImageUpload = (event, setImage) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImage(file);
            setImagePreview(reader.result);
        };
    };
    const handleReset = () => {
        setOrderIcon(null)
        setOrderIdx('')
        setKeyPosition('')
    };
    const handleValueChange = (value)=>{
        setKeyPosition(value)
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (
            !orderIdx ||
            !keyPosition || !orderIcon
        ) {
            toast.error("All fields are required.");
            return false;
        }
        if (isNaN(orderIdx)){
            toast.error("Order Index should be number.");
            return false;
        }
        if (isSubmitted){
            return
        }
        try {
            setIsSubmitted(true)
            const formData = new FormData();
            formData.append('id', false);
            formData.append('order_index', orderIdx);
            formData.append('order_icon_url', orderIcon);
            formData.append('lang_id', selectedLanguage?.id)
            formData.append('icon_position',keyPosition)
            formData.append('order_status',1)
            // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/content/create_order_icon`, formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     }
            // });
            const response = await apiCallForm('/content/create_order_icon',formData,'POST')
            if (response?.data?.status) {
                console.log('Data saved successfully',response);
                toast.success("Data saved successfully", {
                });
            } else {
                console.error('Error while saving data:', "Error while saving data:");
                toast.error("Error while saving data:", {
                });
            }
        } catch (error) {
            console.log(error,'------77')
            toast.error("Error while saving data:", {})
        } finally{
            setIsSubmitted(false)
            navigate('/content_management/co_icon');
        }

    };
    return (
        <section className="panel add_section">
            <div className="panel-body">
                <form role="form" onSubmit={(event) => handleSubmit(event, 'widgets_ads')} id="widgets_form">
                    <div className="row mt-3">
                        <section className="panel">
                            <header className="panel-heading" style={{ fontWeight: "bold" }}>Add Order Icon</header>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor="order_idx">Order Index<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className={`form-control ${orderIdx ? 'filled' : ''}`} id="order_idx" name="order_idx" placeholder="Order Index" value={orderIdx} onChange={(e) => setOrderIdx(e.target.value)} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="plan_id">Key Position<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="key_position" className={`form-control ${keyPosition ? 'filled' : ''}`} value={keyPosition} onChange={(e) => handleValueChange(e.target.value)}>
                                        <option value="">--Key Position--</option>
                                        {Object.keys(keyPositionMap).map((key) => (
                                            <option key={key} value={key}>
                                                {keyPositionMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-2">
                            <div className="form-group col-md-6">
                                    <label htmlFor="widget_image_dark">Order Icon<span style={{color: "#ff0000"}}>*</span></label>
                                    <input type="file" className={`form-control ${orderIcon ? 'filled' : ''}`} id="order_icon" name="order_icon" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, setOrderIcon)} />
                                    {imagePreview && (
                        <img src={imagePreview} alt="Order Icon" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                        )}
                                </div>
                    </div>
                        </section>
                        <div className="col-md-12 mt-3">
                            <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </section>
    );
}

export default AddOrderIconForm;
