import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useUser } from '../../../src/UserContext';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
function WidgetForm(props) {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { selectedLanguage } = useUser();
    const [widgetTitle, setWidgetTitle] = useState("");
    const [widgetActionUrl,setWidgetActionUrl] = useState("")
    const [widgetIconDark, setWidgetIconDark] = useState(null);
    const [widgetIconLight, setWidgetIconLight] = useState(null);
    const [previewImageDark, setWidgetImageDark] = useState(null);
    const [previewImageLight, setWidgetImageLight] = useState(null);
    const [widgetAction, setAction] = useState();
    const [errors, setErrors] = useState({});
    const [category, setCategory] = useState([]);
    const [webActivity,setWebActivity] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedSubCategory, setSelectedSubCategory] = useState(0);
    // const [selectedLangId, setSelectedLangId] = useState('');
    const [widgetOptions, setWidgetOptions] = useState([]);
    useEffect(()=>{
            const fetchAcitvity = async () =>{
                const response = await apiGetCall(`/master/widget_activity_list?lang_id=${selectedLanguage?.id}`,{})
                // console.log('--------------27',response.data)
                setWebActivity(response.data)
            }
            fetchAcitvity()
    },[widgetAction])
    useEffect(() => {
        const fetchWidgets = async () => {
            try {
                const response = await apiGetCall(`/master/category_list`,{});
                setCategory(response.data.data);
                setWidgetOptions([{ "id": 1, "title": 'WebView' },
                { "id": 2, "title": 'External Link' },
                { "id": 3, "title": 'Activity' }])
            } catch (error) {
                console.error('Error fetching languages:', error);
            }
        };

        fetchWidgets();
    }, []);

    const handleImageUpload = (event, setImage,type) => {
        const file = event.target.files[0];
        setImage(file);
        if (type == 'dark'){
            const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setWidgetImageDark(reader.result);
        }
        }
        else{
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setWidgetImageLight(reader.result);
            }
        }

    };
    const handleReset = (e) => {
        e.preventDefault()
        navigate('/master/widgets');
    };
    const validateForm = (formType) => {
        let formErrors = {};
        return formErrors;
    };
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        let row = category.find(row => row.id == event.target.value);
        // setSelectedLangId(row.lang_id)
    };
    const handleSubCategoryChange = (event) => {
        setSelectedSubCategory(event.target.value);
    };
    const handleWidgetActionChange = (event) =>{
        setAction(event.target.value)
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            console.log(!widgetTitle , !selectedLanguage , !widgetActionUrl , !widgetAction,!widgetIconDark, !widgetIconLight)
            if (!widgetTitle || !selectedLanguage || !widgetActionUrl || !widgetAction || !widgetIconDark || !widgetIconLight){
                toast.error('Missing required fields')
                return 
            }
            if (isSubmitting) return;
            setIsSubmitting(true); 
            const formData = new FormData();
            formData.append('widget_title', widgetTitle);
            formData.append('cms_id', 0);
            formData.append('lang_id',selectedLanguage?.id);
            formData.append('cat_id',selectedCategory);
            formData.append('widget_action_url',widgetActionUrl);
            formData.append('sub_cat_id',selectedCategory);
            formData.append('widget_dark_icon', widgetIconDark);
            formData.append('widget_icon', widgetIconLight);
            formData.append('widget_action', widgetAction);

            // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/master/create_widget`, formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     }
            // });
            const response = await apiCallForm('/master/create_widget',formData,'POST')
            if (response.data.status) {
                console.log('Data saved successfully');
                toast.success("Data saved successfully", {
                });
            } else {
                console.error('Error while saving data:', "Error while saving data:");
                toast.error("Error while saving data:", {
                });
            }
        } catch (error) {
            toast.error("Error while saving data:", {})
        }finally{
            setIsSubmitting(false); 
            navigate('/master/widgets')
        }

    };
    return (
        <section className="panel add_section">
            <div className="panel-body">
                <form role="form" onSubmit={(event) => handleSubmit(event, 'widgets_ads')} id="widgets_form">
                    <div className="row mt-3">
                        <section className="panel">
                            <header className="panel-heading" style={{ fontWeight: "bold" }}>Add Widget</header>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor="widgets_title"> Widget Title<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className={`form-control ${widgetTitle ? 'filled' : ''}`} id="widget_title" name="widget_title" placeholder="Widget Title" value={widgetTitle} onChange={(e) => setWidgetTitle(e.target.value)} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="widget_image_dark">Widget Icon Dark<span style={{color: "#ff0000"}}>*</span></label>
                                    <input type="file" className={`form-control ${widgetIconDark ? 'filled' : ''}`} id="widget_image_dark" name="lang_image_dark" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, setWidgetIconDark,'dark')} />
                                    {previewImageDark && (
                        <img src={previewImageDark} alt="Widget Image" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                        )}
                                </div>
                            </div>
                            <div className="row mt-3">
                            <div className="form-group col-md-6">
                                    <label htmlFor="widgets_category"> Widget Category<span style={{color: "#ff0000",paddingRight: "10px" }}></span></label>
                                    <select id="widget_category" className={`form-control ${selectedCategory ? 'filled' : ''}`} value={selectedCategory} onChange={handleCategoryChange}>
                                    <option value="">--Select a Category--</option>
                                    {category.map((cat) => (
                                        <option key={cat.id} value={cat.id}>
                                            {cat.category_title}
                                        </option>
                                    ))}
                                </select>
                                </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="widget_image_light">Widget Icon Light<span style={{color: "#ff0000"}}>*</span></label>
                                        <input type="file" className={`form-control ${widgetIconLight ? 'filled' : ''}`} id="widget_image_light" name="lang_image_dark" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, setWidgetIconLight,'light')} />
                                        {previewImageLight && (
                        <img src={previewImageLight} alt="Lang Image" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                        )}
                                    </div>
                            </div>
                            <div className="row mt-3">
                            <div className="form-group col-md-6">
                                    <label htmlFor="widgets_sub_category"> Widget Sub Category<span style={{color: "#ff0000",paddingRight: "10px" }}></span></label>
                                    <select id="widget_sub_category" className={`form-control ${selectedSubCategory ? 'filled' : ''}`} value={selectedSubCategory} onChange={handleSubCategoryChange}>
                                    <option value="">--Select a Sub Category--</option>
                                    {category.map((cat) => (
                                        <option key={cat.id} value={cat.id}>
                                            {cat.category_title}
                                        </option>
                                    ))}
                                </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="widgets_action"> Widget Action<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <select id="widget_action" className={`form-control ${widgetAction ? 'filled' : ''}`} value={widgetAction} onChange={handleWidgetActionChange}>
                                    <option value="">--Select a Action--</option>
                                    {widgetOptions.map((cat) => (
                                        <option key={cat.id} value={cat.id}>
                                            {cat.title}
                                        </option>
                                    ))}
                                </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="widget_activity_url"> Activity action<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    {widgetAction == '3' ? (
                                        <select
                                        id="activity_widget"
                                        className={`form-control ${widgetActionUrl ? 'filled' : ''}`}
                                        value={widgetActionUrl}
                                        onChange={(e) => setWidgetActionUrl(e.target.value)}
                                        >
                                        {webActivity.map((cat) => (
                                            <option key={cat.id} value={cat.id}>
                                                {cat.category_title}
                                            </option>
                                        ))}
                                        </select>
                                ) : (
                                    <input
                                    type="text"
                                    className={`form-control ${widgetActionUrl ? 'filled' : ''}`}
                                    id="widget_action_url"
                                    name="widget_action_url"
                                    placeholder="Widget Action Url"
                                    value={widgetActionUrl}
                                    onChange={(e) => setWidgetActionUrl(e.target.value)}
                                    />
                                )}
                                </div>
                            </div>
                        </section>
                        <div className="col-md-12 mt-3">
                            <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </section>
    );
}

export default WidgetForm;
