import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useUser } from '../../../src/UserContext';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
function AddMenuSettings(props) {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { selectedLanguage } = useUser();
    const [menuTitle, setMenuTitle] = useState("");
    const [iconDark, setIconDark] = useState(null);
    const [iconLight, setIconLight] = useState(null);
    const [selectedLight, setSelectedLight] = useState(null);
    const [selectedDark, setSelectedDark] = useState(null);
    const [menuActionElementState, setMenuActionElementState] = useState([]);
    const [menuLocationData, setMenuLocationData] = useState('');
    const [menuTypeData, setMenuTypeData] = useState('');
    const [errors, setErrors] = useState({});
    const [sendToMenu, setSendToMenu] = useState([]);
    const [menuType, setMenuType] = useState([]);
    const [menuActionElement, setMenuActionElement] = useState('');
    const [sendToMenuData, setSendToMenuData] = useState(0)
    const [deviceType, setDeviceType] = useState('')
    const fetchMenuActionElement = async (menuType) => {
        try {
            if (selectedLanguage == null) {
                toast.error("Please select a language first");
                return
            }
            const response = await apiGetCall(`/config/get_menu_action_element?lang_id=${selectedLanguage.id}&menu_type=${menuType}`,{})
            if (response.data.result) {
                setMenuActionElementState(response.data.result);
            }
            else {
                setMenuActionElementState([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect( () => {
        const fetchSendToMenu = async () => {
            try {
                if (selectedLanguage == null) {
                    toast.error("Please select a language first");
                    return
                }
                const response = await apiGetCall(`/config/send_to_menu_list?lang_id=${selectedLanguage.id}`,{})
                setSendToMenu(response.data.sendToMenu);
                setMenuType(response.data.menuType)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (menuTypeData) {
           fetchMenuActionElement(menuTypeData)
        }

        fetchSendToMenu();
    }, [selectedLanguage, menuTypeData]);
    const menuFilterOptions = [
        { id: 1, value: "0", label: "Bottom Bar" },
        { id: 3, value: "1", label: "Left Menu" },
        { id: 4, value: "2", label: "Top Menu" },
        { id: 5, value: "3", label: "Left Top Menu" },
        { id: 2, value: "4", label: "Shorts Menu" }
    ];
    const deviceTypeOptions = [
        { id: 1, value: "1", label: "Android" },
        { id: 3, value: "2", label: "Ios" },
    ];
    const handleImageUpload = (event, setImage) => {
        if (selectedLanguage == null) {
            toast.error("Please select a language first");
            return
        }
        const file = event.target.files[0];
        setImage(file);
    };
    const handleReset = () => {
        navigate('/configuration/app_settings');
    };
    const validateForm = (formType) => {
        let formErrors = {};
        return formErrors;
    };
    const handleMenuLocationChange = (event) => {
        if (selectedLanguage == null) {
            toast.error("Please select a language first");
            return
        }
        setMenuLocationData(event.target.value)

    }
    const handleMenuActionElementChange = (event) => {
        if (selectedLanguage == null) {
            toast.error("Please select a language first");
            return
        }
        setMenuActionElement(event.target.value)
    }
    const handleMenuTypeChange = (event) => {
        if (selectedLanguage == null) {
            toast.error("Please select a language first");
            return
        }
        setMenuTypeData(event.target.value)
    };
    const handleDeviceTypeChange = (event) => {
        setDeviceType(event.target.value)
    }
    const handleSendMenuChange = (event) => {
        if (selectedLanguage == null) {
            toast.error("Please select a language first");
            return
        }
        setSendToMenuData(event.target.value)
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (isSubmitting) return;
            setIsSubmitting(true); 
            if (!menuTitle || !menuActionElement || !sendToMenuData || !menuLocationData || !menuTypeData || (menuTypeData === 6 && !deviceType) || !iconDark || !iconLight) {
                console.error('Missing required fields');
                toast.error("Missing required fields");
                return;
            }
            const formData = new FormData();
            formData.append('title', menuTitle);
            formData.append('menu_action_element', menuActionElement);
            formData.append('lang_id', selectedLanguage.id);
            formData.append('send_to_menu', sendToMenuData);
            formData.append('menu_side', menuLocationData);
            formData.append('data_limit',0);
            formData.append('type', menuTypeData);
            formData.append('device_type', deviceType);
            formData.append('menu_dark_icon', iconDark);
            formData.append('menu_light_icon', iconLight);
            formData.append('select_dark_icon', selectedDark);
            formData.append('select_light_icon', selectedLight);
            // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/config/add_menu`, formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     }
            // });
            const response = await apiCallForm('/config/add_menu',formData,'POST')
            if (response.data.status) {
                console.log('Data saved successfully');
                toast.success("Data saved successfully", {
                });
            } else {
                console.error('Error while saving data:', "Error while saving data:");
                toast.error("Error while saving data:", {
                });
                
            }
        } catch (error) {
            toast.error("Error while saving data:", {})

        } finally{
            setIsSubmitting(false); 
            navigate('/configuration/app_settings');
        }
    };
    return (
        <section className="panel add_section">
            <div className="panel-body">
                <form role="form" onSubmit={(event) => handleSubmit(event, 'widgets_ads')} id="widgets_form">
                    <div className="row mt-3">
                        <section className="panel">
                            <header className="panel-heading" style={{ fontWeight: "bold" }}>Add Menu</header>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu_title"> Menu Title<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className="form-control" id="menu_title" name="menu_title" placeholder="Menu Title" value={menuTitle} onChange={(e) => setMenuTitle(e.target.value)} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="widgets_category">Menu Location<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="widget_category" className="form-control" value={menuLocationData} onChange={handleMenuLocationChange}>
                                        <option value="">--Select a Menu Location--</option>
                                        {menuFilterOptions.map((cat) => (
                                            <option key={cat.id} value={cat.value}>
                                                {cat.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="widget_image_dark">For Dark Mode:<span style={{ color: "#ff0000" }}>*</span></label>
                                    <input type="file" className="form-control" id="widget_image_dark" name="lang_image_dark" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, setIconDark)} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="widget_image_light">For Light Mode:<span style={{ color: "#ff0000" }}>*</span></label>
                                    <input type="file" className="form-control" id="widget_image_light" name="lang_image_dark" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, setIconLight)} />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="selected_image_dark">Selected Dark Mode:<span style={{ color: "#ff0000" }}>*</span></label>
                                    <input type="file" className="form-control" id="selected_image_dark" name="lang_image_dark" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, setSelectedDark)} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="selected_image_light">Selected Light Mode:<span style={{ color: "#ff0000" }}>*</span></label>
                                    <input type="file" className="form-control" id="selected_image_light" name="lang_image_dark" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, setSelectedLight)} />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="widgets_sub_category"> Menu Type<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="widget_sub_category" className="form-control" value={menuTypeData} onChange={handleMenuTypeChange}>
                                        <option value="">--Select a Menu Type--</option>
                                        {Object.keys(menuType).map((key) => (
                                            <option key={key} value={key}>
                                                {menuType[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="widgets_action"> Send To Menu<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="widget_action" className="form-control" value={sendToMenuData} onChange={handleSendMenuChange}>
                                        <option value="">--Select a Send to menu--</option>
                                        {sendToMenu.map((cat) => (
                                            <option key={cat.id} value={cat.id}>
                                                {cat.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu_action_element">
                                        Menu Action Element<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span>
                                    </label>
                                    {menuActionElementState.length > 0 ? (
                                        <select
                                            id="menu_action_element"
                                            className="form-control"
                                            value={menuActionElement}
                                            onChange={handleMenuActionElementChange}
                                        >
                                            <option value="">--Select an Action Element--</option>
                                            {menuActionElementState.map((cat) => (
                                                <option key={cat.id} value={cat.id}>
                                                    {cat.title}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <input
                                            type="text"
                                            id="menu_action_element"
                                            className="form-control"
                                            value={menuActionElement}
                                            onChange={handleMenuActionElementChange}
                                        />
                                    )}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="widgets_action">Data Limit<span style={{ color: "#ff0000", paddingRight: "10px" }}></span></label>
                                    <input type="text" className="form-control" id="menu_title" name="menu_title" placeholder="" />
                                </div>
                            </div>
                            <div className="row mt-3">
                                {menuTypeData === '6' && (
                                    <div className="form-group col-md-6">
                                        <label htmlFor="deep_action_element">
                                            Device Type<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span>
                                        </label>
                                        <select id="device_type" className="form-control" value={deviceType} onChange={handleDeviceTypeChange}>
                                            <option value=""></option>
                                            {deviceTypeOptions.map((cat) => (
                                                <option key={cat.id} value={cat.value}>
                                                    {cat.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </div>
                        </section>
                        <div className="col-md-12 mt-3">
                            <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </section>
    );
}
export default AddMenuSettings;
