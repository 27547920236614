import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton, Pagination, Modal, InputLabel, FormControl
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useUser } from '../../UserContext';
import { Edit,Add } from '@mui/icons-material';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const CacheManagementList = () => {
    const [data, setData] = useState([]);
    const { selectedLanguage } = useUser();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const cacheMap = {
        tv_001: "Version",
        tv_002: "Master",
        tv_003: "Home",
        tv_004: "Article List",
        tv_005: "Author",
        tv_006: "Live TV",
        tv_007: "Clear Data"
    };
    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    const fetchData = async(page) => {
            try {
                const response = await apiGetCall(`/config/cache_management_list?page=${page}&pageSize=20`)
                if (Array.isArray(response.data.data)) {
                    setData(response.data.data);
                    setTotalPages(response.data.totalPages || 1);
                } else {
                    console.error('Fetched data is not an array:');
                }
            } catch (error) {
                
            }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleEditClick = async (event,version) => {
        event.preventDefault();
        const payload = {
            lang_id:selectedLanguage?.id,
            version : version
        };

        try {
            console.log('----------60--data', payload)
            const response = await apiCall(`/config/update_api_version`, payload,'PUT');
            console.log(response.data);
            if (response.status === 200) {
                toast.success('cache updated successfully.')
                fetchData(currentPage)
            }
        } catch (error) {
            toast.error("An error occurred while updating the section.");
            console.error('Error updating cache:', error);
        }
    };
    return (
        <>
            <TableContainer component={Paper} style={{ width: '100%' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>APP</TableCell>
                            <TableCell>Language</TableCell>
                            <TableCell>Version</TableCell>
                            <TableCell>Master</TableCell>
                            <TableCell>Home</TableCell>
                            <TableCell>Article List</TableCell>
                            <TableCell>Author</TableCell>
                            <TableCell>Live TV</TableCell>
                            <TableCell>Clear Data</TableCell>
                            {/* <TableCell>Action</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length > 0 ? (
                            data.map((content) => (
                                <TableRow key={content.id}>
                                    <TableCell>{content.app_version + (content.platform == "2"?"(iOS)" : "(Android)")}</TableCell>
                                    <TableCell>{content.language_name}</TableCell>
                                    <TableCell>{content.tv_001}
                                    <IconButton aria-label="add" onClick={(e) =>  handleEditClick(e,'VERSION_API')}>
                                    <Add />
                                    </IconButton>
                                    </TableCell>
                                    <TableCell>{content.tv_002}
                                    <IconButton aria-label="add" onClick={(e) => handleEditClick(e,'MASTER_API')}>
                                    <Add />
                                    </IconButton>
                                    </TableCell>
                                    <TableCell>{content.tv_003}
                                    <IconButton aria-label="add" onClick={(e) => handleEditClick(e,'HOME_API')}>
                                    <Add />
                                    </IconButton>
                                    </TableCell>
                                    <TableCell>{content.tv_004}
                                    <IconButton aria-label="add" onClick={(e) => handleEditClick(e,'ARTICLE_LIST_API')}>
                                    <Add />
                                    </IconButton>
                                    </TableCell>
                                    <TableCell>{content.tv_005}
                                    <IconButton aria-label="add" onClick={(e) => handleEditClick(e,'AUTHOR_API')}>
                                    <Add />
                                    </IconButton>
                                    </TableCell>
                                    <TableCell>{content.tv_006}
                                    <IconButton aria-label="add" onClick={(e) => handleEditClick(e,'LIVE_TV_API')}>
                                    <Add />
                                    </IconButton>
                                    </TableCell>
                                    <TableCell>{content.tv_007}
                                    <IconButton aria-label="add" onClick={(e) => handleEditClick(e,'CLEAR_DATA_API')}>
                                    <Add />
                                    </IconButton>
                                    </TableCell>
                                    {/* <TableCell>
                                        <IconButton onClick={() => handleEditClick(content)} aria-label="edit">
                                            <Edit />
                                        </IconButton>
                                    </TableCell> */}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={7} align="center">No data available</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
                <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div>
            <ToastContainer/>
        </>
    );
};

export default CacheManagementList;
