import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useUser } from '../../../src/UserContext';
import { useNavigate } from 'react-router-dom';
import {apiCall,apiCallForm,apiGetCall} from '../../configs/apiMethodCall'
import { ToastContainer, toast } from 'react-toastify';
function AddPlanTitleForm(props) {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { selectedLanguage } = useUser();
    const [plans, setPlans] = useState([]);
    const [planId, setPlanId] = useState("");
    const [planName, setPlanName] = useState("");
    const [title, setPlanTitle] = useState('')
    const [filters, setFilters] = useState({
        status: '1',
    });
    const planTypeMap = {
        "0": "Weekly",
        "1": "Monthly",
        "2": "Quarterly",
        "3": "Half Yearly",
        '4': "Annual",
        '5': "Life Time",
    };
    useEffect(() => {
        fetchData();
    }, [selectedLanguage]);
    const fetchData = async() => {
            try {
                const response = await apiGetCall(`/plan/plan_list`,filters)
                if (Array.isArray(response.data.data)) {
                    setPlans(response.data.data);
                }
            } catch (error) {
                
            }
    };
    const handleReset = () => {
        
    };
    const handleValueChange = (value)=>{
        const plan = plans.filter((row)=>row.id == value)
        setPlanId(value)
        setPlanName(planTypeMap[plan?.[0]["plan_type"]])
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (
            !planId ||
            !title
        ) {
            toast.error("All fields are required.");
            return false;
        }
        if (isSubmitting) return;
        setIsSubmitting(true); 
        try {
            let payload = {
                plan_id :planId,
                plan_title :title,
                plan_name:planName,
                lang_id:selectedLanguage?.id,
                title_status:1

            }
            // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/plan/add_plan_title`, payload, {
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // });
            const response = await apiCall('/plan/add_plan_title',payload,'POST')
            if (response.data.status) {
                console.log('Data saved successfully');
                toast.success("Data saved successfully", {
                });
            } else {
                console.error('Error while saving data:', "Error while saving data:");
                toast.error("Error while saving data:", {
                });
            }
        } catch (error) {
            toast.error("Error while saving data:", {})
        } finally{
            setIsSubmitting(false)
            navigate('/subcription/plans');
        }
    };
    return (
        <section className="panel add_section">
            <div className="panel-body">
                <form role="form" onSubmit={(event) => handleSubmit(event, 'widgets_ads')} id="widgets_form">
                    <div className="row mt-3">
                        <section className="panel">
                            <header className="panel-heading" style={{ fontWeight: "bold" }}>Add Plan</header>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor="plan_id">Plan<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="plan_type" className={`form-control ${planId ? 'filled' : ''}`} value={planId} onChange={(e) => handleValueChange(e.target.value)}>
                                        <option value="">--Select a Plan--</option>
                                        {plans.map((key) => (
                                            <option key={key.id} value={key.id}>
                                                {planTypeMap[key.plan_type]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="plan_title">Plan Title<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className={`form-control ${title ? 'filled' : ''}`} id="plan_title" name="plan_title" placeholder="Plan Title" value={title} onChange={(e) => setPlanTitle(e.target.value)} />
                                </div>
                            </div>
                        </section>
                        <div className="col-md-12 mt-3">
                            <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </section>
    );
}

export default AddPlanTitleForm;
