import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../src/UserContext';
import { ToastContainer, toast } from 'react-toastify';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
function StateForm(props) {
    const navigate = useNavigate();
    const { selectedLanguage } = useUser();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [stateName, setStateName] = useState("");
    const [stateImageDark, setStateImageDark] = useState(null);
    const [stateImageLight, setStateImageLight] = useState(null);
    const [previewImageDark, setPreviewImageDark] = useState(null);
    const [previewImageLight, setPreviewImageLight] = useState(null);

    const handleReset = () => {
        setStateName("");
        setStateImageDark(null);
        setStateImageLight(null);
        navigate('/master/state');
    };

    useEffect(() => {
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (!selectedLanguage || !stateName || !stateImageDark || !stateImageLight){
                toast.error('Missing required fields')
                return
            }
            if (isSubmitting) return;
            setIsSubmitting(true); 
            const formData = new FormData();
            formData.append('state_name', stateName);
            formData.append('lang_id', selectedLanguage?.id);
            formData.append('cms_id', 0);
            formData.append('dark_state_logo', stateImageDark);
            formData.append('state_logo', stateImageLight);

            // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/master/create_state`, formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     }
            // });
            const response = await apiCallForm('/master/create_state',formData,'POST')
            if (response.data.status) {
                console.log('Data saved successfully');
                toast.success("Data saved successfully", {
                });
            } else {
                console.error('Error while saving data:', "Error while saving data:");
                toast.success("Error while saving data:", {
                });
            }
        } catch (error) {
            toast.error("Error while saving data:", {})
        }finally{
            setIsSubmitting(false); 
            navigate('/master/state');
        }
    };

    const handleImageUpload = (event, setImage,type) => {
        const file = event.target.files[0];
        setImage(file)
        if (type == 'dark'){
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setPreviewImageDark(reader.result);
            };
        }
        else{
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setPreviewImageLight(reader.result);
            };
        }

    };

    return (
        <section className="panel add_section">
            <header className="panel-heading" >
                Add State
            </header>
            <div className="panel-body">
              <form role="form" onSubmit={handleSubmit} encType="multipart/form-data" id="state_form" className="form-container">

            <div className="row mt-3">
                <div className="form-group col-md-6">
                    <label htmlFor="state_name">State Name<span className="required-indicator">*</span></label>
                    <input type="text" className={`form-control ${stateName ? 'filled' : ''}`} id="state_name" name="state_name" placeholder="Enter State Name" value={stateName} onChange={(e) => setStateName(e.target.value)} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="form-group col-md-6">
                    <label htmlFor="state_image_dark">State Image Dark<span className="required-indicator">*</span></label>
                    <input type="file" className={`form-control ${stateImageDark ? 'filled' : ''}`} id="state_image_dark" name="state_image_dark" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, setStateImageDark,'dark')} />
                    {previewImageLight && (
                        <img src={previewImageLight} alt="State Image" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                        )}
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="state_image_light">State Image Light<span className="required-indicator">*</span></label>
                    <input type="file" className={`form-control ${stateImageLight ? 'filled' : ''}`} id="state_image_light" name="state_image_light" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, setStateImageLight,'light')} />
                    {previewImageLight && (
                        <img src={previewImageLight} alt="State Image" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                        )}
                </div>
            </div>
            {/* <div className="row mt-3">
                <div className="form-group col-md-6">
                    <label htmlFor="language">Select Language<span className="required-indicator">*</span></label>
                    <select id="language" className={`form-control ${selectedLanguage ? 'filled' : ''}`} value={selectedLanguage} onChange={handleLanguageChange}>
                        <option value="">--Select a Language--</option>
                        {languages.map((language) => (
                            <option key={language.id} value={language.id}>
                                {language.lang_name}
                            </option>
                        ))}
                    </select>
                </div>
            </div> */}
            <div className="row mt-3">
                <div className="form-group col-md-6">
                    <button type="submit" className="btn btn-primary">Submit</button>
                    <button type="button" className="btn btn-secondary" onClick={handleReset} >Clear</button>
                </div>
            </div>
        </form>
            </div>
            <ToastContainer />
        </section>
    );
}

export default StateForm;
