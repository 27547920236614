import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton, Pagination, Modal, InputLabel, FormControl, CircularProgress
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from '../../UserContext';
import { Edit, Delete, Save, Cancel } from '@mui/icons-material';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const ContentList = (articleType) => {
    const { selectedLanguage } = useUser();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [contents, setContents] = useState([]);
    const [filters, setFilters] = useState({
        premiumContent: '',
        contentType: '',
        status: '',
        fromDate: '',
        toDate: false,
        lang_id:selectedLanguage?.id
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (selectedLanguage?.id) {
            setFilters(prevFilters => ({
                ...prevFilters,
                lang_id: selectedLanguage.id
            }));
        }
    }, [selectedLanguage]);
    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const contentTypeMap = {
        "0": "Text",
        "1": "Photo-Gallery",
        "2": "Video",
        "3": "Podcast",
        '4': "Webstory",
        '5': "Shorts",
        '6': "Live-Blog"
    };
    const [contentOptions, setContentOptions] = useState([
        { value: false, label: 'All' },
        { value: 0, label: 'Text' },
        { value: 1, label: 'Photo-Gallery' },
        { value: 2, label: 'Video' },
        { value: 3, label: 'Podcast' },
        { value: 4, label: 'Webstory' },
        { value: 5, label: 'Shorts' },
        { value: 6, label: 'Live-Blog' },
    ]);
    const [premiumContentOptions, setpremiumContentOptions] = useState([
        { value: false, label: 'All' },
        { value: 0, label: 'Non Premium Content' },
        { value: 1, label: 'Premium Content' }
    ]);
    const articleTypeMap = {
        '0': "Unknown",
        '1': "Article Short",
        '2': "Video Short",
        '3': "Podcast Short",
        '4': "JW Video Short"
    };
    const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
    ]);
    const premiumContentTypeMap = {
        0: "Non Premium Content",
        1: "Premium Content"
    }
    const [editRowId, setEditRowId] = useState(null);
    const [editedContent, setEditedContent] = useState({
        "id": 1,
        "lang_id": 2,
        "cms_id": 10975,
        "article_title": "Joe Biden may extend May 1 deadline for troop withdrawal from Afghanistan",
        "article_type_id": articleTypeMap["0"],
        "content_type_id": contentTypeMap["1"],
        "premium_content": 0,
        "article_status": 1,
        "created_on": "2021-04-08T12:05:58.000Z",
        "updated_on": "2021-04-08T12:05:58.000Z",
        "category": "India",
        "cat_id": 170,
        "sub_cat_id": 0,
        "category_title": "India",
        "sub_title": null
    });


    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await apiGetCall(`/content/contentlist?page=${currentPage}&pageSize=20&articleType=${Object.freeze(articleType.articleType)}`, filters);
            if (Array.isArray(response.data.data)) {
                setContents(response.data.data);
                setTotalPages(response.data.totalPages);
            } else {
                console.error('Fetched data is not an array:', response.data);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    };
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value, '--------------94')
        setFilters({
            ...filters,
            [name]: value
        });
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleEditClick = (id) => {
        setEditRowId(id);
        const content = contents.find(content => content.id === id);
        console.log('---------80', content)
        // content.content_type_id = contentTypeMap[content.content_type_id]
        // content.article_type_id = articleTypeMap[content.article_type_id]
        setEditedContent({ ...content });
        setModalOpen(true);
    };

    const handleCancelClick = () => {
        setEditRowId(null);
        setEditedContent({});
        setModalOpen(false);
    };

    const handleSaveClick = () => {
        // Update data via API
        fetch(`https://your-api-endpoint.com/contents/${editedContent.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(editedContent)
        })
            .then(response => response.json())
            .then(data => {
                const updatedContents = contents.map(content =>
                    content.id === editedContent.id ? editedContent : content
                );
                setContents(updatedContents);
                setEditRowId(null);
                setEditedContent({});
            })
            .catch(error => console.error('Error updating data:', error));
    };

    const handleDeleteClick = (id) => {
        // Delete data via API
        fetch(`https://your-api-endpoint.com/contents/${id}`, {
            method: 'DELETE'
        })
            .then(() => {
                const updatedContents = contents.filter(content => content.id !== id);
                setContents(updatedContents);
            })
            .catch(error => console.error('Error deleting data:', error));
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedContent({
            ...editedContent,
            [name]: value
        });
    };
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    // const filteredContents = contents.filter(content => {
    //     const createdOnDate = new Date(content.createdOn);
    //     const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
    //     const toDate = filters.toDate ? new Date(filters.toDate) : null;

    //     return (
    //         (filters.category ? content.category === filters.category : true) &&
    //         (filters.contentType ? content.contentType === filters.contentType : true) &&
    //         (filters.status ? content.status === filters.status : true) &&
    //         (fromDate ? createdOnDate >= fromDate : true) &&
    //         (toDate ? createdOnDate <= toDate : true)
    //     );
    // });

    return (
        <div style={{ width: '100%', overflowX: 'auto' }}>
            <TableContainer component={Paper} style={{ width: '100%' }}>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                        <CircularProgress />
                    </div>
                )
                    : (<Table>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Id</TableCell> */}
                                <TableCell>CMS Id</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell>Sub Category</TableCell>
                                <TableCell>Content Title</TableCell>
                                <TableCell>Content Type</TableCell>
                                <TableCell>Article Type</TableCell>
                                <TableCell>Premium Content</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Created On</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                            <TableRow>
                                {/* <TableCell>
                </TableCell> */}
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={filters.contentType}
                                        onChange={handleFilterChange}
                                        displayEmpty
                                        name="contentType"
                                        size="small"
                                        variant="outlined"
                                    >
                                        {contentOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={filters.premiumContent}
                                        onChange={handleFilterChange}
                                        displayEmpty
                                        name="premiumContent"
                                        size="small"
                                        variant="outlined"
                                    >
                                        {premiumContentOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={filters.status}
                                        onChange={handleFilterChange}
                                        displayEmpty
                                        name="status"
                                        size="small"
                                        variant="outlined"
                                    >
                                        {statusOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="date"
                                        variant="outlined"
                                        size="small"
                                        name="fromDate"
                                        value={filters.fromDate}
                                        onChange={handleFilterChange}
                                    />
                                    <TextField
                                        type="date"
                                        variant="outlined"
                                        size="small"
                                        name="toDate"
                                        value={filters.toDate}
                                        onChange={handleFilterChange}
                                    />
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contents.map((content) => (
                                <TableRow key={content.id}>
                                    {/* <TableCell>{content.id}</TableCell> */}
                                    <TableCell>{content.cms_id}</TableCell>
                                    <TableCell>{content.category}</TableCell>
                                    <TableCell>{content.sub_title}</TableCell>
                                    <TableCell>{content.article_title}</TableCell>
                                    <TableCell>{contentTypeMap[content.content_type_id]}</TableCell>
                                    <TableCell>{articleTypeMap[content.article_type_id]}</TableCell>
                                    <TableCell>{premiumContentTypeMap[content.premium_content]}</TableCell>
                                    <TableCell>{content.article_status === 1 ? "Enabled" : "Disabled"}</TableCell>
                                    <TableCell>{new Date(content.created_on).toLocaleString()}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteClick(content.id)} aria-label="delete">
                                            <Delete />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    )}
            </TableContainer>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
                <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div>
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="edit-content-modal"
                aria-describedby="modal to edit content"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <section className="panel edit_section col-6">
                    <header className="panel-heading" >
                        Edit Content
                    </header>
                    <div className="panel-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                        <form role="form" onSubmit={handleSaveClick} encType="multipart/form-data" id="edit_content_form">
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="content-title">Content Title<span style={{ color: "#ff0000" }}>*</span></label>
                                    <input
                                        type="text"
                                        className={`form-control ${editedContent.article_title ? 'filled' : ''}`}
                                        id="content-title"
                                        name="content_title"
                                        placeholder="Content Title"
                                        value={editedContent.article_title}
                                        onChange={(e) => handleEditChange({ target: { name: "content_title", value: e.target.value } })}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="content-type">Content Type<span style={{ color: "#ff0000" }}>*</span></label>
                                    <select
                                        id="content-type"
                                        className={`form-control ${editedContent.content_type_id ? 'filled' : ''}`}
                                        value={editedContent.content_type_id}
                                        onChange={(e) => handleEditChange({ target: { name: "content_type", value: contentTypeMap[e.target.value] } })}
                                    >
                                        <option value="" disabled>
                                            Select Content Type
                                        </option>
                                        {Object.entries(contentTypeMap).map(([key, value]) => (
                                            <option key={key} value={key}>{value}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="article-type">Article Type<span style={{ color: "#ff0000" }}>*</span></label>
                                    <select
                                        id="article-type"
                                        className={`form-control ${editedContent.article_type_id ? 'filled' : ''}`}
                                        value={editedContent.article_type_id}
                                        onChange={(e) => handleEditChange({ target: { name: "article_type", value: articleTypeMap[e.target.value] } })}
                                    >
                                        <option value="" disabled>
                                            Select Article Type
                                        </option>
                                        {Object.entries(articleTypeMap).map(([key, value]) => (
                                            <option key={key} value={key}>{value}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="premium-content">Premium Content<span style={{ color: "#ff0000" }}>*</span></label>
                                    <select
                                        id="premium-content"
                                        className={`form-control ${editedContent.premium_content ? 'filled' : ''}`}
                                        value={editedContent.premium_content}
                                        onChange={(e) => handleEditChange({ target: { name: "premium_content", value: e.target.value } })}
                                    >
                                        <option value="" disabled>
                                            Select Premium Content
                                        </option>
                                        {Object.entries(premiumContentTypeMap).map(([key, value]) => (
                                            <option key={key} value={key}>{value}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="status">Status<span style={{ color: "#ff0000" }}>*</span></label>
                                    <select
                                        id="status"
                                        className={`form-control ${editedContent.article_status ? 'filled' : ''}`}
                                        value={editedContent.article_status === 1 ? "Enabled" : "Disabled"}
                                        onChange={(e) => handleEditChange({ target: { name: "status", value: e.target.value } })}
                                    >
                                        <option value="" disabled>
                                            Select Status
                                        </option>
                                        <option value="Enabled">Enabled</option>
                                        <option value="Disabled">Disabled</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>
                                        Save
                                    </button>
                                    <button type="button" className="btn btn-secondary" onClick={handleCancelClick} style={{ padding: "15px", lineHeight: "1.5" }}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                        <ToastContainer />
                    </div>
                </section>
            </Modal>
        </div>
    );
};

export default ContentList;
