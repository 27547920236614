import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from "react-datepicker";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
function SplashForm(props) {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [splashTitle, setSplashTitle] = useState("");
    const [splashStartFrom, setSplashStartFrom] = useState('');
    const [splashImage, setSplashImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [deviceType, setDeviceType] = useState('')
    const [imagePreview, setImagePreview] = useState('');
    const deviceTypeOptions = [
        { id: 1, value: "1", label: "Android" },
        { id: 3, value: "2", label: "Ios" },
    ];
    const handleDeviceTypeChange = (event) => {
        setDeviceType(event.target.value)
    }
    const handleImageUpload = (event, setImage) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImage(file);
            setImagePreview(reader.result);
        };
    };
    const handleReset = () => {
        // setLayoutTitle("");
        // setLayoutCode("");
        navigate('/master/splash');
    };

    const handleSubmit = async (event,formType) => {
        event.preventDefault();
        console.log(!splashTitle ,!splashStartFrom ,!splashImage ,!deviceType)
        if (!splashTitle || !splashImage || !deviceType || !splashStartFrom) {
            console.error('Missing required fields');
            toast.error("Missing required fields");
            return;
        }
        try {
            if (isSubmitting) return;
            setIsSubmitting(true); 
            const formData = new FormData();
            formData.append('device_type', deviceType);
            formData.append('title', splashTitle);
            formData.append('splash_url', splashImage);
            formData.append('start_from', splashStartFrom)
            
            try {
                // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/master/create_splash`, formData, {
                //     headers: {
                //         'Content-Type': 'multipart/form-data'
                //     }
                // });
                const response = await apiCallForm('/master/create_splash',formData,'POST')
                if (response.data.status) {
                    console.log('Data saved successfully');
                    toast.success("Data saved successfully");
                } else {
                    console.error('Error while saving data:', response?.data?.message || "Unknown error");
                    toast.error(`Error while saving data:', ${response?.data?.message}`);
                }
            } catch (error) {
                if (error.response) {
                    console.error('Error response:--76', error.response);
                    toast.error(error.response?.data?.error);
                } else if (error.request) {
                    console.error('Error request:', error.request);
                    toast.error("No response from server. Please check your network connection.");
                } else {
                    toast.error(error);
                }
            }
        } catch (error) {
            console.error('Error while saving data:', error);
            toast.error(error);
        }
        finally{
            setIsSubmitting(false); 
            navigate('/master/splash')
        }
    };

    return (
        <section className="panel add_section">
            <div className="panel-body">
                <form role="form" onSubmit={(event) => handleSubmit(event, 'splash_form')} id="splash_form">
                    <div className="row mt-3">
                        <section className="panel">
                            <header className="panel-heading" style={{ fontWeight: "bold" }}>Add Splash</header>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor="splash_title"> Splash Title<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className={`form-control ${splashTitle ? 'filled' : ''}`} id="splash_title" name="splash_title" placeholder="Splash Title" value={splashTitle} onChange={(e) => setSplashTitle(e.target.value)} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="widget_image_dark">Splash Image<span style={{color: "#ff0000"}}>*</span></label>
                                    <input type="file" className={`form-control ${splashImage ? 'filled' : ''}`} id="splash_image_dark" name="splash_image_dark" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, setSplashImage)} />
                                    {imagePreview && (
                        <img src={imagePreview} alt="Splash Image" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                        )}
                                </div>
                            </div>
                            <div className="row mt-3">
                            <div className="form-group col-md-6">
                                    <label htmlFor="splash_device_type"> Splash Device Type<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <select id="device_type" className={`form-control ${deviceType ? 'filled' : ''}`} value={deviceType} onChange={handleDeviceTypeChange}>
                                            <option value=""></option>
                                            {deviceTypeOptions.map((cat) => (
                                                <option key={cat.id} value={cat.value}>
                                                    {cat.label}
                                                </option>
                                            ))}
                                        </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="splash_start_from">
                                        Start From<span style={{color: "#ff0000", paddingRight: "10px"}}>*</span>
                                    </label>
                                    <DatePicker className={`form-control ${splashStartFrom ? 'filled' : ''}`} selected={splashStartFrom} onChange={(date) => setSplashStartFrom(date)} />
                                </div>
                            </div>
                        </section>
                        <div className="col-md-12 mt-3">
                            <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </section>
    );
}

export default SplashForm;
