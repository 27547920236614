import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton, Pagination, Modal, InputLabel, FormControl
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { Edit, Delete, Save, Add, Cancel } from '@mui/icons-material';
import { Link,useNavigate } from 'react-router-dom';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const ViewContentAccessList = () => {
    const navigate = useNavigate();
    const [contents, setContents] = useState([]);
    const [editRowId, setEditRowId] = useState(null);
    const [editedContent, setEditedContent] = useState({
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filters, setFilters] = useState({
        category: '',
        contentType: '',
        status: '',
        fromDate: '',
        toDate: ''
    });
    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const contentTypeMap = [
        { id: 0, label: "Article" },
        { id: 1, label: "Photo-Gallery" },
        { id: 2, label: "Video" },
        { id: 3, label: "Podcast" },
        { id: 4, label: "Web-Story" },
        { id: 5, label: "Short" },
        { id: 6, label: "Liveblog" },
        { id: 7, label: "Live" },
        { id: 51, label: "Article Short" },
        { id: 52, label: "Video Short" },
        { id: 53, label: "Podcast Short" }
    ];
    const contentResolutionMap = {
        "140": "140p",
        "240": "240p",
        "360": "360p",
        "480": "480p",
        "720": "720p"
    };
    const articleTypeMap = {
        '0': "Unknown",
        '1': "Article Short",
        '2': "Video Short",
        '3': "Podcast Short",
        '4': "JW Video Short"
    };
    const premiumContentTypeMap = {
        0: "Non Premium Content",
        1: "Premium Content"
    }
   
    const handleContentTypeChange = (e) => {
        const selectedOptions = Array.from(e.target.options)
            .filter(option => option.selected)
            .map(option => option.value);

        setEditedContent({ ...editedContent, content_type: selectedOptions });
    };
    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);
    const fetchData = async () => {
        try {
            const response = await apiGetCall(`/plan/content_access?page=${currentPage}&pageSize=20`,filters)
            if (Array.isArray(response.data.data)) {
                    setContents(response.data.data);
                    setTotalPages(response.data.totalPages);
                } else {
                    console.error('Fetched data is not an array:', response.data);
                }
        } catch (error) {
            
        }
    };
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleEditClick = (id) => {
        setEditRowId(id);
        let content = contents.find(content => content.id === id);
        content['content_type'] = content?.content_type?.split(',')
        console.log('---------80', content)
        setEditedContent({ ...content });
        setModalOpen(true);
        console.log(editedContent, '-------------------103')
    };

    const handleCancelClick = () => {
        setEditRowId(null);
        setEditedContent({});
        setModalOpen(false);
    };

    const handleSaveClick = async(e) => {
        e.preventDefault();
        try {
            const response = await apiCall(`/plan/update_content_access`,JSON.stringify(editedContent),'PUT')
            if (response && response.data.status) {
                            setEditRowId(null);
                            setEditedContent({});
                            toast.success('Data updated successfully');
                            fetchData()
                            setModalOpen(false);
                            console.log('Modal should close now');
                        } else {
                            console.error('Error updating data:', data.message);
                            toast.error('Failed to update data');
                        }
        } catch (error) {
            toast.error('Failed to update data');
        }finally{
            navigate('/subcription/cam');
        }
    };

    const handleDeleteClick = (id) => {
        // Delete data via API
        fetch(`https://your-api-endpoint.com/contents/${id}`, {
            method: 'DELETE'
        })
            .then(() => {
                const updatedContents = contents.filter(content => content.id !== id);
                setContents(updatedContents);
            })
            .catch(error => console.error('Error deleting data:', error));
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedContent({
            ...editedContent,
            [name]: value
        });
    };
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
    ]);

    return (
        <>
            <div className="header-container">
                <header>
                    <h1>Content Access List</h1>
                </header>
                <div className="add-author-button">
                    <Link className="text-white" to="/add_subcription/cam">Add Content Access</Link>
                </div>
            </div>

            <div style={{ width: '100%', overflowX: 'auto' }}>
                <TableContainer component={Paper} style={{ width: '100%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Content Access Title</TableCell>
                                <TableCell>Content Type</TableCell>
                                <TableCell>Content Quality</TableCell>
                                <TableCell>Content Resolution</TableCell>
                                <TableCell>Premium Content</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Created On</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={filters.status}
                                        onChange={handleFilterChange}
                                        displayEmpty
                                        name="status"
                                        size="small"
                                        variant="outlined"
                                    >
                                        {statusOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contents.map((content) => (
                                <TableRow key={content.id}>
                                    <TableCell>{content.id}</TableCell>
                                    <TableCell>{content.access_title}</TableCell>
                                    <TableCell>{content.content_type == '1' ? "Article" : "Article + Video"}</TableCell>
                                    <TableCell>{content.content_quality}</TableCell>
                                    <TableCell>{`${content.content_resolution}p`}</TableCell>
                                    <TableCell>{content.content_access_status == '1' ? "Premium" : "Non-Premium"}</TableCell>
                                    <TableCell>{content.content_access_status == '1' ? "Enabled" : "Disabled"}</TableCell>
                                    <TableCell>{new Date(content.created_on * 1000).toLocaleString()}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteClick(content.id)} aria-label="delete">
                                            <Delete />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                    <Button
                        variant="outlined"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>
                <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="edit-content-modal"
                    aria-describedby="modal to edit content"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <section className="panel edit_section col-6">
                        <header className="panel-heading" >
                            Edit Banner
                        </header>
                        <div className="panel-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            <form role="form" onSubmit={handleSaveClick} encType="multipart/form-data" id="edit_language_form">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="state_name">Access Title<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editedContent.access_title ? 'filled' : ''}`}
                                            id="access_title"
                                            name="access_title"
                                            placeholder="Access Title"
                                            value={editedContent.access_title}
                                            onChange={(e) => handleEditChange({ target: { name: "access_title", value: e.target.value } })}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="lang_status">Access Status</label>
                                        <select
                                            value={editedContent.content_access_status}
                                            onChange={(e) => handleEditChange({ target: { name: "content_access_status", value: e.target.value } })}
                                            className={`form-control ${editedContent.content_access_status ? 'filled' : ''}`}
                                            name="content_access_status"
                                        >
                                            {statusOptions.filter(option => option.value !== false).map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                <div className="form-group col-md-6">
                                        <label htmlFor="content_type">Content type*</label>
                                        <select
                                            multiple
                                            value={editedContent.content_type}
                                            onChange={handleContentTypeChange}
                                            className={`form-control ${editedContent.content_type?.length > 0 ? 'filled' : ''}`}
                                            name="content_type"
                                        >
                                            {contentTypeMap.map((row) => (
                                                <option key={row.id} value={row.id}>
                                                    {row.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="lang_status">Content Resolution*</label>
                                        <select
                                            value={editedContent.content_resolution}
                                            onChange={(e) => handleEditChange({ target: { name: "content_resolution", value: e.target.value } })}
                                            className={`form-control ${editedContent.content_resolution ? 'filled' : ''}`}
                                            name="content_resolution"
                                        >
                                            {Object.keys(contentResolutionMap).map((key) => (
                                                <option key={key} value={key}>
                                                    {contentResolutionMap[key]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="lang_status">Premium Content*</label>
                                        <select
                                            value={editedContent.premium_content}
                                            onChange={(e) => handleEditChange({ target: { name: "premium_content", value: e.target.value } })}
                                            className={`form-control ${editedContent.premium_content ? 'filled' : ''}`}
                                            name="premium_content"
                                        >
                                            {Object.keys(premiumContentTypeMap).map((key) => (
                                                <option key={key} value={key}>
                                                    {premiumContentTypeMap[key]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="state_name">Content Quality<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editedContent.content_quality ? 'filled' : ''}`}
                                            id="content_quality"
                                            name="content_quality"
                                            placeholder="Content Quality"
                                            value={editedContent.content_quality}
                                            onChange={(e) => handleEditChange({ target: { name: "content_quality", value: e.target.value } })}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>
                                            Save
                                        </button>
                                        <button type="button" className="btn btn-secondary" onClick={handleCancelClick} style={{ padding: "15px", lineHeight: "1.5" }}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <ToastContainer />
                        </div>
                    </section>

                </Modal>
            </div>
        </>
    );
};
export default ViewContentAccessList;
