import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
// import './AddHome.css'
import { useUser } from '../../UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faL } from '@fortawesome/free-solid-svg-icons';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
function AddSection(props) {
    const { menu_id, menu_side } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const [rows, setRows] = useState([{ id: 1, ads_code: '', ads_position: '', ads_repeat_count: '' }]);
    const [nextId, setNextId] = useState(2);

    const handleEnableAdsChange = (rowIndex, field, event) => {
        const newRows = [...rows];
        newRows[rowIndex][field] = event.target.value;
        setRows(newRows);
    };

    const addNewRow = () => {
        setRows([...rows, { id: nextId, layout1: '', layout2: '', layout3: '' }]);
        setNextId(nextId + 1);
    };

    const removeRow = (id) => {
        setRows(rows.filter((row) => row.id !== id));
    };
    const { selectedLanguage } = useUser();
    const [sectionTitle, setSectionTitle] = useState("");
    const [sectionLimit, setSectionLimit] = useState('');
    const [errors, setErrors] = useState({});
    const [sendToMenu, setSendToMenu] = useState([]);
    const [layouts, setLayouts] = useState([]);
    const [adsList, setAdsList] = useState([])
    const [adsDisplay, setAdsDisplay] = useState([
        { id: 1, value: "1" },
        { id: 2, value: "2" },
        { id: 3, value: "3" },
        { id: 4, value: "4" },
        { id: 5, value: "5" },
        { id: 7, value: "7" },
        { id: 8, value: "8" },
        { id: 9, value: "9" },
        { id: 10, value: "10" },
    ])
    const [selectLayout, setSelectLayout] = useState('');
    const [sectionType, setSectionType] = useState('');
    const [categories, setCategories] = useState([]);
    const [widgets, setWidgets] = useState([])
    const [widgetData, setWidgetData] = useState('')
    const [categoryData, setCategoryData] = useState('')
    const [contentType, setContentType] = useState([
        { id: 0, label: "Article" },
        { id: 1, label: "Photo-Gallery" },
        { id: 2, label: "Video" },
        { id: 3, label: "Podcast" },
        { id: 4, label: "Web-Story" },
        { id: 5, label: "Short" },
        { id: 6, label: "Liveblog" },
        { id: 7, label: "Live" },
        { id: 51, label: "Article Short" },
        { id: 52, label: "Video Short" },
        { id: 53, label: "Podcast Short" },
    ])
    const [articleData,setArticleData] = useState('')
    const [sendToMenuData, setSendToMenuData] = useState(0)
    const [moreView, setMoreView] = useState(false)
    const [contentTypeIconVisibility, setContentTypeIconVisibility] = useState(false)
    const [enablePagination, setEnablePagination] = useState(false)
    const [enableAds, setEnableAds] = useState(false)
    useEffect(() => {
        const fetchAds = async () => {
            try {
                if (selectedLanguage == null) {
                    toast.error("Please select a language first");
                    return
                }
                if (sectionType == '3') {
                    const responseCate = await apiGetCall(`/master/category_list`, {
                            lang_id: selectedLanguage?.id
                    });
                    console.log(responseCate,'------------85')
                    setCategories(responseCate.data.data);
                }
                else if (sectionType == '7') {
                    const responseWidget = await apiGetCall(`/master/widget_list`, {
                            lang_id: selectedLanguage?.id,
                            status: "1"
                    });
                    console.log(responseWidget,'------------95')
                    setWidgets(responseWidget.data.data);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchAds()
    }, [sectionType])
    useEffect(() => {
        const fetchAds = async () => {
            try {
                if (selectedLanguage == null) {
                    toast.error("Please select a language first");
                    return
                }
                const response = await apiGetCall(`/master/ads_list`, {
                        ads_type: '1',
                        ads_screen: '1,2',
                        lang_id: selectedLanguage?.id
                });
                setAdsList(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchAds()
    }, [enableAds, selectedLanguage])
    useEffect(() => {
        const fetchLayouts = async () => {
            try {
                if (selectedLanguage == null) {
                    toast.error("Please select a language first");
                    return
                }
                const response = await apiGetCall(`/master/layout_list?lang_id=${selectedLanguage.id}`,{});
                setLayouts(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        const fetchSendToMenu = async () => {
            try {
                if (selectedLanguage == null) {
                    toast.error("Please select a language first");
                    return
                }
                const response = await apiGetCall(`/config/send_to_menu_list?lang_id=${selectedLanguage.id}`,{});
                setSendToMenu(response.data.sendToMenu);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchSendToMenu();
        fetchLayouts()
    }, [selectedLanguage]);

    const sectionTypeMap = [
        { id: 1, value: "1", label: "Top9 News" },
        { id: 2, value: "2", label: "Latest News" },
        { id: 3, value: "3", label: "Categories" },
        { id: 4, value: "4", label: "Content Type" },
        { id: 5, value: "5", label: "Tags List" },
        { id: 7, value: "7", label: "Widgets" },
        { id: 8, value: "8", label: "For You" },
        { id: 9, value: "9", label: "Ads" },
        { id: 10, value: "10", label: "Tags News" },
        { id: 11, value: "11", label: "Opinion" },
    ]

    const handleReset = () => {
        navigate('/configuration/app_settings');
    };
    const validateForm = (formType) => {
        let formErrors = {};
        return formErrors;
    };
    const handleSetLayoutChange = (event) => {
        setSelectLayout(event.target.value)

    }

    const handleSectionTypeChange = (event) => {
        if (selectedLanguage == null) {
            toast.error("Please select a language first");
            return
        }
        setSectionType(event.target.value)
    };
    const handleSendMenuChange = (event) => {
        if (selectedLanguage == null) {
            toast.error("Please select a language first");
            return
        }
        setSendToMenuData(event.target.value)
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        let payload = {
            section_title: sectionTitle,
            section_limit: sectionLimit,
            layout_code: selectLayout,
            section_type: sectionType,
            send_to_menu: sendToMenuData,
            category_id:categoryData,
            article_type:articleData,
            widget_id:widgetData,
            menu_id: menu_id,
            lang_id: selectedLanguage?.id,
            more_view: moreView,
            show_icon: contentTypeIconVisibility,
            allow_pagination: enablePagination,
            ads_enable: enableAds,
            ads_object: rows

        }
        console.log('------------160', payload)
        try {
            // if (!sectionTitle) {
            //     console.error('Missing required fields');
            //     toast.error("Missing required fields");
            //     return;
            // }
            if (isSubmitting) return;
            setIsSubmitting(true); 
            // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/config/add_menu_section`, payload, {
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // });
            const response = await apiCall('/config/add_menu_section',payload,'POST')

            if (response.data.status) {
                console.log('Data saved successfully');
                toast.success("Data saved successfully", {
                });
                
            } else {
                console.error('Error while saving data:', "Error while saving data:");
                toast.success("Error while saving data:", {
                });
            }
        } catch (error) {
            toast.success("Error while saving data:", {})

        } finally{
            setIsSubmitting(false); 
            navigate('/configuration/app_settings');
        }
    };
    return (
        <section className="panel add_section">
            <div className="panel-body">
                <form role="form" onSubmit={(event) => handleSubmit(event, 'widgets_ads')} id="widgets_form">
                    <div className="row mt-3">
                        <section className="panel">
                            <header className="panel-heading" style={{ fontWeight: "bold" }}>Add Menu Section</header>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor="section_title"> Section Title<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className="form-control-unified" id="section_title" name="section_title" placeholder="Section Title" value={sectionTitle} onChange={(e) => setSectionTitle(e.target.value)} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="section_limit"> Section Limit<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <input type="number" className="form-control-unified" id="section_limit" name="section_limit" placeholder="Section Limit" value={sectionLimit} onChange={(e) => setSectionLimit(e.target.value)} />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="select_section_type"> Select Section Type<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="section_section_type" className="form-control-unified" value={sectionType} onChange={handleSectionTypeChange}>
                                        <option value="">--Select a Section Type--</option>
                                        {sectionTypeMap.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="widgets_action">Select Layout<span style={{ color: "#ff0000", paddingRight: "10px" }}></span></label>
                                    <select id="widget_action" className="form-control-unified" value={selectLayout} onChange={handleSetLayoutChange}>
                                        <option value="">--Select a layout--</option>
                                        {layouts.map((cat) => (
                                            <option key={cat.id} value={cat.layout_code}>
                                                {cat.layout_title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className="form-group col-md-6">
                                    <label htmlFor="widgets_action"> Send To Menu<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="widget_action" className="form-control-unified" value={sendToMenuData} onChange={handleSendMenuChange}>
                                        <option value="">--Select a Send to menu--</option>
                                        {sendToMenu.map((cat) => (
                                            <option key={cat.id} value={cat.id}>
                                                {cat.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {sectionType == "3" && (
                                    <div div className="form-group col-md-6">
                                        <label htmlFor="widgets_action"> Select Category<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                        <select id="widget_action" className="form-control-unified" value={categoryData} onChange={(e)=>{setCategoryData(e.target.value)}}>
                                            <option value="">--Select a Category--</option>
                                            {categories.map((cat) => (
                                                <option key={cat.id} value={cat.id}>
                                                    {cat.category_title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                {sectionType == "7" && (
                                    <div div className="form-group col-md-6">
                                        <label htmlFor="widgets_action"> Select Widget<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                        <select id="widget_action" className="form-control-unified" value={widgetData} onChange={(e)=>{setWidgetData(e.target.value)}}>
                                            <option value="">--Select a Widget--</option>
                                            {widgets.map((cat) => (
                                                <option key={cat.id} value={cat.id}>
                                                    {cat.widget_title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                {sectionType == '4' && (
                                      <div div className="form-group col-md-6">
                                      <label htmlFor="widgets_action"> Select Article Type<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                      <select id="widget_action" className="form-control-unified" value={articleData} onChange={(e)=>{setArticleData(e.target.value)}}>
                                          <option value="">--Select a Article type--</option>
                                          {contentType.map((cat) => (
                                              <option key={cat.id} value={cat.id}>
                                                  {cat.label}
                                              </option>
                                          ))}
                                      </select>
                                  </div>
                                )}
                            </div>
                            <div className='row mt-3'>
                                <div className="form-group col-md-6">
                                    <label>More View (If yes the section layout code will be used for view more)</label>
                                    <input className="form-control-unified" type="checkbox" checked={moreView} onChange={() => setMoreView(!moreView)} />
                                </div>

                                <div className="form-group col-md-6">
                                    <label>Content Type Icon Visibility</label>
                                    <input className="form-control-unified" type="checkbox" checked={contentTypeIconVisibility} onChange={() => setContentTypeIconVisibility(!contentTypeIconVisibility)} />
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className="form-group col-md-6">
                                    <label>Enable Pagination</label>
                                    <input className="form-control-unified" type="checkbox" checked={enablePagination} onChange={() => setEnablePagination(!enablePagination)} />
                                </div>

                                <div className="form-group col-md-6">
                                    <label>Enable Ads</label>
                                    <input className="form-control-unified" type="checkbox" checked={enableAds} onChange={() => setEnableAds(!enableAds)} />
                                </div>
                            </div>
                            <div>
                                {enableAds === true && (
                                    <>
                                        <div className="row mt-3">
                                            {rows.map((row, rowIndex) => (
                                                <React.Fragment key={row.id}>
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor={`widget_action2_${row.id}`}>
                                                            Select Ads
                                                            <span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span>
                                                        </label>
                                                        <select
                                                            id={`widget_action2_${row.id}`}
                                                            className="form-control-unified"
                                                            value={row.ads_code}
                                                            onChange={(e) => handleEnableAdsChange(rowIndex, 'ads_code', e)}
                                                        >
                                                            <option value="">--Select a ads--</option>
                                                            {adsList.map((cat) => (
                                                                <option key={cat.id} value={cat.id}>
                                                                    {cat.ads_code}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="ads_position"> Ads Position<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                                        <input type="number" className="form-control-unified" id="ads_position" name="ads_position" placeholder="Ads Position" value={row.ads_position} onChange={(e) => handleEnableAdsChange(rowIndex, 'ads_position', e)} />
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor={`widget_action3_${row.id}`}>
                                                            Ads Display over page
                                                            <span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span>
                                                        </label>
                                                        <select
                                                            id={`widget_action3_${row.id}`}
                                                            className="form-control-unified"
                                                            value={row.ads_repeat_count}
                                                            onChange={(e) => handleEnableAdsChange(rowIndex, 'ads_repeat_count', e)}
                                                        >
                                                            <option value="">--Select page--</option>
                                                            {adsDisplay.map((cat) => (
                                                                <option key={cat.id} value={cat.id}>
                                                                    {cat.value}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    {rowIndex > 0 && (
                                                        <div className="form-group col-md-12">
                                                            <button type="button" onClick={() => removeRow(row.id)}>Remove</button>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                        <button type="button" onClick={addNewRow} className="btn btn-primary mt-3">Add More</button>
                                    </>
                                )}
                            </div>

                        </section>
                        <div className="col-md-12 mt-3">
                            <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </section>
    );
}
export default AddSection;