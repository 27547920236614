import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton,Pagination,Modal,InputLabel, FormControl
} from '@mui/material';
import { Edit, Delete, Save,Add, Cancel } from '@mui/icons-material';
import { FaUser, FaEnvelope, FaPhone } from 'react-icons/fa';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const ViewTransactionsList = () => {
    const [contents, setContents] = useState([]);
    const [statusOptions, setStatusOptions] = useState([
        { value: null, label: 'All' },
        { value: 0, label: 'Enabled' },
        { value: 1, label: 'Disabled' },
      ]);
      const [plans, setPlanOptions] = useState([
        { value: null, label: 'All' },
        { value: 0, label: 'Weekly Plan' },
        { value: 1, label: 'Monthly Plan' },
        { value: 2, label: 'Quarterly Plan' },
        { value: 3, label: 'Half Yearly' },
        { value: 4, label: 'Annual Plan' },
        { value: 5, label: 'Life Time Plan' },
      ]);
      const [planVia, setPlanVia] = useState([
        { value: null, label: 'All' },
        { value: 0, label: 'Google Pay' },
        { value: 1, label: 'Razor Pay' },
        { value: 2, label: 'Apple Pay' }
      ]);
      const [planType, setPlanType] = useState([
        { value: null, label: 'All' },
        { value: 0, label: 'New Plan' },
        { value: 1, label: 'Upgrade Plan' },
      ]);
      const [planAmout, setPlanAmout] = useState([
        { value: null, label: 'All' },
        { value: 0, label: 'INR' },
        { value: 1, label: 'USD' },
      ]);
    const [filters, setFilters] = useState({
        plans: '',
        contentType: '',
        status: '',
        fromDate: '',
        toDate: ''
    });
    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const contentTypeMap = {
        "0": "Text",
        "1": "Photo-Gallery",
        "2": "Video",
        "3": "Podcast",
        '4': "Webstory",
        '5': "Shorts",
        '6': "Live-Blog"
      };
      
      const articleTypeMap = {
        '0':"Unknown",
        '1': "Article Short",
        '2': "Video Short",
        '3': "Podcast Short",
        '4': "JW Video Short"
      };
      const premiumContentTypeMap = {
            0:"Non Premium Content",
            1:"Premium Content"
      }
    const [editRowId, setEditRowId] = useState(null);
    const [editedContent, setEditedContent] = useState({
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);
      const fetchData = async () => {
        try {
            const response = await apiGetCall(`/plan/transactions_list?page=${currentPage}&pageSize=20`,filters)
            if (Array.isArray(response.data.data)) {
                    setContents(response.data.data);
                    setTotalPages(response.data.totalPages);
                } else {
                    console.error('Fetched data is not an array:', response.data);
                }
        } catch (error) {
            
        }
    };
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        console.log( name, value,'------------------------93')
        setFilters({
            ...filters,
            [name]: value
        });
    };
 const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleEditClick = (id) => {
        setEditRowId(id);
        const content = contents.find(content => content.id === id);
        setEditedContent({ ...content });
        setModalOpen(true);
    };

    const handleCancelClick = () => {
        setEditRowId(null);
        setEditedContent({});
        setModalOpen(false);
    };

    const handleSaveClick = (e) => {
        e.preventDefault()
        // Update data via API
        fetch(`https://your-api-endpoint.com/contents/${editedContent.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(editedContent)
        })
        .then(response => response.json())
        .then(data => {
            const updatedContents = contents.map(content =>
                content.id === editedContent.id ? editedContent : content
            );
            setContents(updatedContents);
            setEditRowId(null);
            setEditedContent({});
        })
        .catch(error => console.error('Error updating data:', error));
    };
    const [imagePreview, setImagePreview] = useState(editedContent.author_image);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        handleEditChange({ target: { name: "author_image", value: file } });
        setImagePreview(URL.createObjectURL(file));
    };
    const handleDeleteClick = (id) => {
        // Delete data via API
        fetch(`https://your-api-endpoint.com/contents/${id}`, {
            method: 'DELETE'
        })
        .then(() => {
            const updatedContents = contents.filter(content => content.id !== id);
            setContents(updatedContents);
        })
        .catch(error => console.error('Error deleting data:', error));
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedContent({
            ...editedContent,
            [name]: value
        });
    };
 const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    const filteredContents = contents.filter(content => {
        const createdOnDate = new Date(content.createdOn);
        const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
        const toDate = filters.toDate ? new Date(filters.toDate) : null;

        return (
            (filters.category ? content.category === filters.category : true) &&
            (filters.contentType ? content.contentType === filters.contentType : true) &&
            (filters.status ? content.status === filters.status : true) &&
            (fromDate ? createdOnDate >= fromDate : true) &&
            (toDate ? createdOnDate <= toDate : true)
        );
    });

    return (
        <>
          <div className="header-container">
                <header>
                    <h1>Transactions</h1>
                </header>
            </div>
        <div style={{ width: '100%', overflowX: 'auto' }}>
        <TableContainer component={Paper} style={{ width: '100%' }}>
    <Table>				
        <TableHead>				
            <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell>User Info</TableCell>
                <TableCell>Plan</TableCell>
                <TableCell>Pay Via</TableCell>
                <TableCell>Payment Type</TableCell>
                <TableCell>Plan Amount</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Validity</TableCell>
                <TableCell>Payment Date</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                <Select
                        value={filters.plans}
                        onChange={handleFilterChange}
                        displayEmpty
                        name="plans"
                        size="small"
                        variant="outlined"
                        >
                        {plans.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                        </Select>
                </TableCell>
                <TableCell>
                    <Select
                        value={filters.status}
                        onChange={handleFilterChange}
                        displayEmpty
                        name="status"
                        size="small"
                        variant="outlined"
                    >
                    {planVia.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell>
                <Select
                        value={filters.plans}
                        onChange={handleFilterChange}
                        displayEmpty
                        name="plans"
                        size="small"
                        variant="outlined"
                        >
                        {planType.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                        </Select>
                </TableCell>
                <TableCell>
                <Select
                        value={filters.plans}
                        onChange={handleFilterChange}
                        displayEmpty
                        name="plans"
                        size="small"
                        variant="outlined"
                        >
                        {planAmout.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                        </Select>
                </TableCell>
                <TableCell>
                <Select
                        value={filters.plans}
                        onChange={handleFilterChange}
                        displayEmpty
                        name="plans"
                        size="small"
                        variant="outlined"
                        >
                        {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                        </Select>
                </TableCell>
                <TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {filteredContents.map((content) => (
                <TableRow key={content.id}>
                    <TableCell>{content.id}</TableCell>
                    <TableCell>{content.access_title}</TableCell>
                    <TableCell>{content.content_type === 1 ? "Article" : "Article + Video"}</TableCell>
                    <TableCell>{content.content_quality}</TableCell>
                    <TableCell>{content.content_resolution}</TableCell>
                    <TableCell>{content.content_access_status === 1 ? "Premium" : "Non-Premium"}</TableCell>
                    <TableCell>{content.content_access_status === 1 ? "Enabled" : "Disabled"}</TableCell>
                    <TableCell>{new Date(content.created_on * 1000).toLocaleString()}</TableCell>
                    <TableCell>
                        <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                            <Edit />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(content.id)} aria-label="delete">
                            <Delete />
                        </IconButton>
                    </TableCell>

                </TableRow>
            ))}
        </TableBody>
    </Table>
</TableContainer>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
                    <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div>
            </div>
    </>
);
};
export default ViewTransactionsList;
