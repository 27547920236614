import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useUser } from '../../UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
function UpdateSection(props) {
    const { menu_id, section_id } = useParams();
    const navigate = useNavigate();
    const [isSubmit,setIsSubmit] = useState(false)
    const [id, setId] = useState('')
    const [selectLayout, setSelectLayout] = useState('');
    const [sectionType, setSectionType] = useState('');
    const [categories, setCategories] = useState([]);
    const [widgets, setWidgets] = useState([]);
    const [widgetData, setWidgetData] = useState('');
    const [categoryData, setCategoryData] = useState('');
    const [contentType, setContentType] = useState([
        { id: 0, label: "Article" },
        { id: 1, label: "Photo-Gallery" },
        { id: 2, label: "Video" },
        { id: 3, label: "Podcast" },
        { id: 4, label: "Web-Story" },
        { id: 5, label: "Short" },
        { id: 6, label: "Liveblog" },
        { id: 7, label: "Live" },
        { id: 51, label: "Article Short" },
        { id: 52, label: "Video Short" },
        { id: 53, label: "Podcast Short" },
    ]);
    const [articleData, setArticleData] = useState('');
    const [sendToMenuData, setSendToMenuData] = useState(0);
    const [moreView, setMoreView] = useState(false);
    const [contentTypeIconVisibility, setContentTypeIconVisibility] = useState(false);
    const [enablePagination, setEnablePagination] = useState(false);
    const [enableAds, setEnableAds] = useState(false);

    const { selectedLanguage } = useUser();
    const [sectionTitle, setSectionTitle] = useState("");
    const [sectionLimit, setSectionLimit] = useState('');
    const [errors, setErrors] = useState({});
    const [sendToMenu, setSendToMenu] = useState([]);
    const [layouts, setLayouts] = useState([]);
    const [adsList, setAdsList] = useState([])
    const [adsDisplay, setAdsDisplay] = useState([
        { id: 1, value: "1" },
        { id: 2, value: "2" },
        { id: 3, value: "3" },
        { id: 4, value: "4" },
        { id: 5, value: "5" },
        { id: 7, value: "7" },
        { id: 8, value: "8" },
        { id: 9, value: "9" },
        { id: 10, value: "10" },
    ]);
    const [rows, setRows] = useState([{ id: 1, ads_code: '', ads_position: '', ads_repeat_count: '' }]);
    const [nextId, setNextId] = useState(2);
    const [preAds, setPreAds] = useState([])

    const handleEnableAdsChange = (rowIndex, field, event) => {
        const newRows = [...rows];
        newRows[rowIndex][field] = event.target.value;
        setRows(newRows);
    };
    const handlePreAdsChange = (rowIndex, field, event) => {
        const newPreAds = [...preAds];
        newPreAds[rowIndex][field] = event.target.value;
        setPreAds(newPreAds);
    };
    const addNewRow = () => {
        setRows([...rows, { id: nextId, layout1: '', layout2: '', layout3: '' }]);
        setNextId(nextId + 1);
    };

    const removeRow = (id) => {
        setRows(rows.filter((row) => row.id !== id));
    };


    useEffect(() => {
        const fetchAds = async () => {
            try {
                if (selectedLanguage == null) {
                    toast.error("Please select a language first");
                    return;
                }
                if (sectionType == '3') {
                    const responseCate = await apiGetCall(`/master/category_list`, {
                            lang_id: selectedLanguage?.id
                    });
                    setCategories(responseCate.data.data)
                } else if (sectionType == '7') {
                    const responseWidget = await apiGetCall(`/master/widget_list`, {
                            lang_id: selectedLanguage?.id,
                            status: "1"
                    });
                    setWidgets(responseWidget.data.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchAds();
    }, [sectionType, selectedLanguage]);

    useEffect(() => {
        const fetchAds = async () => {
            try {
                if (selectedLanguage == null) {
                    toast.error("Please select a language first");
                    return;
                }
                const response = await apiGetCall(`/master/ads_list`, {
                        ads_type: '1',
                        ads_screen: '1,2',
                        lang_id: selectedLanguage?.id
                });
                setAdsList(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchAds();
    }, [enableAds, selectedLanguage]);

    useEffect(() => {
        const fetchLayouts = async () => {
            try {
                if (selectedLanguage == null) {
                    toast.error("Please select a language first");
                    return;
                }
                const response = await apiGetCall(`/master/layout_list?lang_id=${selectedLanguage?.id}`,{});
                setLayouts(response.data.data);
                console.log('layouts', response.data.data)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchSendToMenu = async () => {
            try {
                if (selectedLanguage == null) {
                    toast.error("Please select a language first");
                    return;
                }
                const response = await apiGetCall(`/config/send_to_menu_list?lang_id=${selectedLanguage.id}`,{});
                setSendToMenu(response.data.sendToMenu);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchSendToMenu();
        fetchLayouts();
    }, [selectedLanguage]);
    useEffect(() => {
        const fetchSectionData = async () => {
            try {
                if (selectedLanguage == null) {
                    toast.error("Please select a language first");
                    return;
                }
                const response = await apiGetCall(`/config/get_menu_section_list`, {
                        lang_id: selectedLanguage?.id,
                        menu_id: menu_id,
                        section_id: section_id
                });
                const data = response?.data?.data[0];
                // console.log(data,'----------176')
                setId(data.id)
                setSectionTitle(data.section_title);
                setSectionLimit(data.section_limit);
                setSelectLayout(data.layout_code);
                setSectionType(data.section_type);
                setSendToMenuData(data.send_to_menu);
                setCategoryData(data.category_id);
                setArticleData(data.article_type);
                setWidgetData(data.widget_id);
                setMoreView(data.more_view == "1" ? true : false);
                setContentTypeIconVisibility(data.show_icon == "1" ? true : false);
                setEnablePagination(data.allow_pagination == "1" ? true : false);
                setEnableAds(data.ads_enable == "1" ? true : false);
                setPreAds(data.ads_relations);
                console.log(data.ads_relations,'-------------relations')
            } catch (error) {
                console.error('Error fetching section data:', error);
            }
        };
        fetchSectionData();
    }, [section_id, selectedLanguage]);

    const sectionTypeMap = [
        { id: 1, value: "1", label: "Top9 News" },
        { id: 2, value: "2", label: "Latest News" },
        { id: 3, value: "3", label: "Categories" },
        { id: 4, value: "4", label: "Content Type" },
        { id: 5, value: "5", label: "Tags List" },
        { id: 7, value: "7", label: "Widgets" },
        { id: 8, value: "8", label: "For You" },
        { id: 9, value: "9", label: "Ads" },
        { id: 10, value: "10", label: "Tags News" },
    ];

    const handleReset = () => {
        navigate('/configuration/app_settings');
    };

    const handleSetLayoutChange = (event) => {
        // console.log(event.target.value, '------------------214')
        setSelectLayout(event.target.value);
    };

    const handleSectionTypeChange = (event) => {
        if (selectedLanguage == null) {
            toast.error("Please select a language first");
            return;
        }
        setSectionType(event.target.value);
        setWidgetData('');
        setCategoryData('');
    };
    const handleSendMenuChange = (event) => {
        if (selectedLanguage == null) {
            toast.error("Please select a language first");
            return
        }
        setSendToMenuData(event.target.value)
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = {};
        if (!sectionTitle) errors.sectionTitle = 'Section Title is required';
        if (!sectionLimit) errors.sectionLimit = 'Section Limit is required';
        if (!selectLayout) errors.selectLayout = 'Section Layout is required';
        if (!sectionType) errors.sectionType = 'Section Type is required';
        if (sectionType == '4' && articleData == '') errors.articleData = 'Content Type is required';
        if (sectionType == '7' && widgetData == '') errors.widgetData = 'Widget is required';
        if ((sectionType == '3' || sectionType == '11') && categoryData == '') errors.categoryData = 'Category is required';
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        const formData = {
            id: id,
            section_title: sectionTitle,
            section_limit: sectionLimit,
            layout_code: selectLayout,
            section_type: sectionType,
            lang_id: selectedLanguage.id,
            article_type: articleData,
            widget_id: widgetData,
            category_id: categoryData,
            ads_enable: enableAds,
            ads_object: enableAds ? rows.map(row => ({
                ads_code: row.ads_code,
                ads_position: row.ads_position,
                ads_repeat_count: row.ads_repeat_count
            })) : [],
            pre_ads: preAds,
            send_to_menu: sendToMenuData,
            allow_pagination: enablePagination,
            show_icon: contentTypeIconVisibility,
            more_view: moreView,
        };
        if (isSubmit){
            return
        }
        try {
            setIsSubmit(true)
            console.log('----------272--data', formData)
            // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/config/add_menu_section`, formData);
            // console.log(formData);
            // console.log(response.data);
            const response = await apiCall('/config/add_menu_section',formData,'POST')
            if (response.status === 200) {
                toast.success('Section updated successfully.')
            }
        } catch (error) {
            toast.error("An error occurred while updating the section.");
            console.error('Error updating section:', error);
        }
        finally {
            setIsSubmit(false)
                navigate('/configuration/app_settings');
        }

    };

    return (
        <div className="container my-5">
            <div className="white-box-container">
                <h2 className="text-center mb-4">Update Section</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="sectionTitle" className="form-label">Section Title</label>
                        <input
                            type="text"
                            id="sectionTitle"
                            className="form-control"
                            value={sectionTitle}
                            onChange={(e) => setSectionTitle(e.target.value)}
                        />
                        {errors.sectionTitle && <div className="text-danger">{errors.sectionTitle}</div>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="sectionLimit" className="form-label">Section Limit</label>
                        <input
                            type="text"
                            id="sectionLimit"
                            className="form-control"
                            value={sectionLimit}
                            onChange={(e) => setSectionLimit(e.target.value)}
                        />
                        {errors.sectionLimit && <div className="text-danger">{errors.sectionLimit}</div>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="sectionLayout" className="form-label">Section Layout</label>
                        <select
                            id="sectionLayout"
                            className="form-control"
                            value={selectLayout}
                            onChange={handleSetLayoutChange}
                        >
                            <option value="">Select a layout</option>
                            {layouts.map((layout) => (
                                <option key={layout.id} value={layout.layout_code}>{layout.layout_title}</option>
                            ))}
                        </select>
                        {errors.selectLayout && <div className="text-danger">{errors.selectLayout}</div>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="sectionType" className="form-label">Section Type</label>
                        <select
                            id="sectionType"
                            className="form-control"
                            value={sectionType}
                            onChange={handleSectionTypeChange}
                        >
                            <option value="">Select a section type</option>
                            {sectionTypeMap.map((type) => (
                                <option key={type.id} value={type.value}>{type.label}</option>
                            ))}
                        </select>
                        {errors.sectionType && <div className="text-danger">{errors.sectionType}</div>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="send_to_menu_action"> Send To Menu<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                        <select id="widget_action" className="form-control-unified" value={sendToMenuData} onChange={handleSendMenuChange}>
                            <option value="">--Select a Send to menu--</option>
                            {sendToMenu.map((cat) => (
                                <option key={cat.id} value={cat.id}>
                                    {cat.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    {sectionType == '4' && (
                        <div className="mb-3">
                            <label htmlFor="articleData" className="form-label">Content Type</label>
                            <select
                                id="articleData"
                                className="form-control"
                                value={articleData}
                                onChange={(e) => setArticleData(e.target.value)}
                            >
                                <option value="">Select a content type</option>
                                {contentType.map((content) => (
                                    <option key={content.id} value={content.id}>{content.label}</option>
                                ))}
                            </select>
                            {errors.articleData && <div className="text-danger">{errors.articleData}</div>}
                        </div>
                    )}
                    {(sectionType == '3' || sectionType == '11') && (
                        <div className="mb-3">
                            <label htmlFor="categoryData" className="form-label">Category</label>
                            <select
                                id="categoryData"
                                className="form-control"
                                value={categoryData}
                                onChange={(e) => setCategoryData(e.target.value)}
                            >
                                <option value="">Select a category</option>
                                {categories.map((category) => (
                                    <option key={category.id} value={category.id}>{category.category_title}</option>
                                ))}
                            </select>
                            {errors.categoryData && <div className="text-danger">{errors.categoryData}</div>}
                        </div>
                    )}
                    {sectionType === '7' && (
                        <div className="mb-3">
                            <label htmlFor="widgetData" className="form-label">Widget</label>
                            <select
                                id="widgetData"
                                className="form-control"
                                value={widgetData}
                                onChange={(e) => setWidgetData(e.target.value)}
                            >
                                <option value="">Select a widget</option>
                                {widgets.map((widget) => (
                                    <option key={widget.id} value={widget.id}>{widget.widget_name}</option>
                                ))}
                            </select>
                            {errors.widgetData && <div className="text-danger">{errors.widgetData}</div>}
                        </div>
                    )}
                    <div className="form-check mb-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="moreView"
                            checked={moreView}
                            onChange={(e) => setMoreView(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="moreView">
                            More View
                        </label>
                    </div>
                    <div className="form-check mb-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="contentTypeIconVisibility"
                            checked={contentTypeIconVisibility}
                            onChange={(e) => setContentTypeIconVisibility(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="contentTypeIconVisibility">
                            Content Type Icon Visibility
                        </label>
                    </div>
                    <div className="form-check mb-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="enablePagination"
                            checked={enablePagination}
                            onChange={(e) => setEnablePagination(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="enablePagination">
                            Enable Pagination
                        </label>
                    </div>
                    <div className="form-check mb-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="enableAds"
                            checked={enableAds}
                            onChange={(e) => setEnableAds(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="enableAds">
                            Enable Ads
                        </label>
                    </div>
                    {enableAds === true && preAds.length && (
                        <>
                            <div className="row mt-3">
                                {preAds.map((row, rowIndex) => (
                                    <React.Fragment key={rowIndex}>
                                        <div className="form-group col-md-4">
                                            <label htmlFor={`widget_action2_${row.id}`}>
                                                Select Ads
                                                <span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span>
                                            </label>
                                            <select
                                                id={`widget_action2_${row.ads_id}`}
                                                className="form-control-unified"
                                                value={row.ads_id}
                                                onChange={(e) => handlePreAdsChange(rowIndex, 'ads_code', e)}
                                            >
                                                <option value="">--Select a ads--</option>
                                                {adsList.map((cat) => (
                                                    <option key={cat.id} value={cat.id}>
                                                        {cat.ads_code}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="ads_position"> Ads Position<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                            <input type="number" className="form-control-unified" id="ads_position" name="ads_position" placeholder="Ads Position" value={row.ads_position} onChange={(e) => handlePreAdsChange(rowIndex, 'ads_position', e)} />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor={`widget_action3_${row.id}`}>
                                                Ads Display over page
                                                <span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span>
                                            </label>
                                            <select
                                                id={`widget_action3_${row.id}`}
                                                className="form-control-unified"
                                                value={row.ads_repeat_count}
                                                onChange={(e) => handlePreAdsChange(rowIndex, 'ads_repeat_count', e)}
                                            >
                                                <option value="">--Select page--</option>
                                                {adsDisplay.map((cat) => (
                                                    <option key={cat.id} value={cat.id}>
                                                        {cat.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                            <div className="row mt-3">
                                {rows.map((row, rowIndex) => (
                                    <React.Fragment key={row.id}>
                                        <div className="form-group col-md-4">
                                            <label htmlFor={`widget_action2_${row.id}`}>
                                                Select Ads
                                                <span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span>
                                            </label>
                                            <select
                                                id={`widget_action2_${row.id}`}
                                                className="form-control-unified"
                                                value={row.ads_code}
                                                onChange={(e) => handleEnableAdsChange(rowIndex, 'ads_code', e)}
                                            >
                                                <option value="">--Select a ads--</option>
                                                {adsList.map((cat) => (
                                                    <option key={cat.id} value={cat.id}>
                                                        {cat.ads_code}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="ads_position"> Ads Position<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                            <input type="number" className="form-control-unified" id="ads_position" name="ads_position" placeholder="Ads Position" value={row.ads_position} onChange={(e) => handleEnableAdsChange(rowIndex, 'ads_position', e)} />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor={`widget_action3_${row.id}`}>
                                                Ads Display over page
                                                <span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span>
                                            </label>
                                            <select
                                                id={`widget_action3_${row.id}`}
                                                className="form-control-unified"
                                                value={row.ads_repeat_count}
                                                onChange={(e) => handleEnableAdsChange(rowIndex, 'ads_repeat_count', e)}
                                            >
                                                <option value="">--Select page--</option>
                                                {adsDisplay.map((cat) => (
                                                    <option key={cat.id} value={cat.id}>
                                                        {cat.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {rowIndex > 0 && (
                                            <div className="form-group col-md-12">
                                                <button type="button" onClick={() => removeRow(row.id)}>Remove</button>
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                            <button type="button" onClick={addNewRow} className="btn btn-primary mt-3">Add More</button>
                        </>
                    )}
                    <button type="submit" className="btn btn-primary">Update Section</button>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default UpdateSection;
